import { Reducer } from 'redux';

import { SigninActions, SigninTypes } from './actions';
import { Signin } from '@healthspaces/hsuite-data/models/signin'
import { AuthActions, AuthTypes } from '../auth';

export interface SigninState {
  fetching: boolean
  entities: { [key: string]: Signin }
  keys: string[]
}

const initialState: SigninState = {
  fetching: false,
  entities: {},
  keys: [],
};

export default <Reducer>(state: SigninState = initialState, action: SigninActions | AuthActions) => {
  switch (action.type) {
    case AuthTypes.SIGNED_OUT:
      return initialState

    case SigninTypes.SIGNINS_FETCH_REQUEST:
      return {
        ...state,
        fetching: true,
      }

    case SigninTypes.SIGNINS_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        keys: action.payload.signins.map(x => x.id),
        entities: action.payload.signins.reduce((entities, signin) => {
          entities[signin.id] = signin
          return entities
        }, {}),
      }

    default:
      return state
  }
}
