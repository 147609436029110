import merge from 'deepmerge';
import { FormActions, FormTypes } from './actions';
import { FormStage } from './models';
import { AuthActions, AuthTypes } from '../auth';
import { Signin, Visit, VisitInfo, BlankVisitInfo, MedicalHistory, BlankMedicalHistory } from '@healthspaces/hsuite-data/models';
import { Patient } from '../models'
import { VisitTypes, VisitActions } from '../visits'
import { SigninActions, SigninTypes } from '../signins'

export interface FormState {
  signin?: Signin
  visit?: Visit
  patient: Patient
  stage: FormStage
  step: number
  signature_exists: boolean
  valid: boolean
  visit_info: VisitInfo
  medical_history: MedicalHistory
  scroll_required: boolean
  scrolled_to_end: boolean
  upload_progress: number
}

const initialState: FormState = {
  signin: null,
  visit: null,
  patient: null,
  stage: FormStage.Welcome,
  step: 0,
  signature_exists: false,
  valid: true,
  visit_info: BlankVisitInfo,
  medical_history: BlankMedicalHistory,
  scroll_required: false,
  scrolled_to_end: false,
  upload_progress: -1,
};

export default <Reducer>(state: FormState = initialState, action: FormActions | AuthActions | SigninActions | VisitActions ) => {
  switch (action.type) {
    case AuthTypes.SIGNED_OUT:
      return initialState

    case FormTypes.FORM_POSITION:
      return {
        ...state,
        stage: action.payload.stage,
        step: action.payload.step,
      }

    case FormTypes.FORM_SIGNATURE_FETCH_SUCCESS:
      return {
        ...state,
        signature_exists: action.payload.signature_exists,
      }

    case FormTypes.FORM_VALID:
      return {
        ...state,
        valid: action.payload.valid,
      }

    case FormTypes.FORM_VISIT_UPDATE:
      return {
        ...state,
        visit_info: merge(state.visit_info, action.payload.value),
      }

    case FormTypes.FORM_VISIT_RESET:
      return {
        ...state,
        ...initialState,
      }

    case FormTypes.FORM_ADD_PAIN:
      return {
        ...state,
        visit_info: {
          ...state.visit_info,
          pain: state.visit_info.pain.concat(action.payload.item),
        },
      }

    case FormTypes.FORM_REMOVE_PAIN:
      return {
        ...state,
        visit_info: {
          ...state.visit_info,
          pain: state.visit_info.pain.filter(item => item !== action.payload.item),
        },
      }

    case FormTypes.FORM_MEDICAL_HISTORY_UPDATE:
      return {
        ...state,
        medical_history: merge(state.medical_history, action.payload.value),
      }

    case FormTypes.FORM_MEDICAL_HISTORY_FETCH_SUCCESS:
      return {
        ...state,
        medical_history: action.payload.medical_history,
      }

    case FormTypes.FORM_MEDICAL_HISTORY_ARRAY_ADD:
      return {
        ...state,
        medical_history: merge_array_add(state.medical_history, action.payload.path, action.payload.item),
      }

    case FormTypes.FORM_MEDICAL_HISTORY_ARRAY_UPDATE:
      return {
        ...state,
        medical_history: merge_array_update(state.medical_history, action.payload.path, action.payload.old, action.payload.item),
      }

    case FormTypes.FORM_MEDICAL_HISTORY_ARRAY_DELETE:
      return {
        ...state,
        medical_history: merge_array_delete(state.medical_history, action.payload.path, action.payload.item),
      }

    case FormTypes.FORM_PATIENT_FETCH_SUCCESS:
      return {
        ...state,
        patient: action.payload.patient,
      }

    case FormTypes.FORM_PATIENT_INFO_UPDATE:
      return {
        ...state,
        patient: {
          ...state.patient,
          info: merge(state.patient.info, action.payload.value),
        },
      }

    case FormTypes.FORM_PATIENT_INFO_ARRAY_ADD:
      return {
        ...state,
        patient: {
          ...state.patient,
          info: merge_array_add(state.patient.info, action.payload.path, action.payload.item),
        },
      }

    case FormTypes.FORM_PATIENT_INFO_ARRAY_UPDATE:
      return {
        ...state,
        patient: {
          ...state.patient,
          info: merge_array_update(state.patient.info, action.payload.path, action.payload.old, action.payload.item),
        },
      }

    case FormTypes.FORM_PATIENT_INFO_ARRAY_DELETE:
      return {
        ...state,
        patient: {
          ...state.patient,
          info: merge_array_delete(state.patient.info, action.payload.path, action.payload.item),
        },
      }

    case FormTypes.FORM_SCROLL_REQUIRED:
      return {
        ...state,
        scroll_required: action.payload.required,
      }

    case FormTypes.FORM_SCROLL_POSITION:
      return {
        ...state,
        scrolled_to_end: action.payload.bottom,
      }

    case SigninTypes.SIGNIN_SWITCH:
      return {
        ...state,
        signin: null,
        visit: action.payload.visit,
        patient: {
          ...state.patient,
          ...action.payload.visit.appointment.patient,
        }
      }

    case SigninTypes.SIGNIN_SELECT:
      return {
        ...state,
        signin: action.payload.signin,
        visit: null,
      }

    case VisitTypes.VISIT_SELECT:
      return {
        ...state,
        signin: null,
        visit: action.payload.visit,
      }

    case FormTypes.FORM_PATIENT_UPLOAD_IMAGE:
      return {
        ...state,
        upload_progress: 0,
      }

    case FormTypes.FORM_PATIENT_UPLOAD_IMAGE_PROGRESS:
      return {
        ...state,
        upload_progress: action.payload.progress,
      }

    case FormTypes.FORM_PATIENT_UPLOAD_IMAGE_COMPLETE:
      return {
        ...state,
        upload_progress: -1,
      }

    default:
      return state
  }
}

function merge_array_add(obj: any, path: string, item: any) {
  return updateArray(obj, path, (arr: any[]) => [...arr, item])
}

function merge_array_update(obj: any, path: string, old: any, item: any) {
  return updateArray(obj, path, (arr: any[]) => {
    const idx = arr.indexOf(old)
    return [...arr.slice(0, idx), item, ...arr.slice(idx + 1, arr.length)]
  })
}

function merge_array_delete(obj: any, path: string, item: any) {
  return updateArray(obj, path, (arr: any[]) => {
    const idx = arr.indexOf(item)
    return [...arr.slice(0, idx), ...arr.slice(idx + 1, arr.length)]
  })
}

function updateArray(obj: any, path: string, callback: (arr: any[]) => any[], parent: string = '') {
  if (parent.length) parent += '.'

  const val = {}

  Object.keys(obj).forEach(key => {
    const p = `${parent}${key}`
    if (p === path) {
      val[key] = callback(obj[key])
    } else {
      if (path.startsWith(p + '.')) {
        val[key] = updateArray(obj[key], path, callback, p)
      } else {
        val[key] = obj[key]
      }
    }
  })

  return val
}
