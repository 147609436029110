import { createSelector } from 'reselect'
import { AuthSelectors } from '../auth';
import { RootState } from '../reducer';

const getState = (state: RootState) => state.practices

export namespace PracticeSelectors {
  export const current = createSelector(
    [getState, AuthSelectors.context],
    (state, context) => context
      ? state.entities[context.practice]
      : undefined
  )
}
