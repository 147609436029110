import { Store } from '@healthspaces/hsuite-data/store'
import { Firestore, FirestoreNamespace } from '@healthspaces/hsuite-data/firestore';
import { firebaseApp } from './firebase-loader';

export const firestore = firebaseApp.then(() => window.firebase.firestore())

export const datastore = firestore.then(firestore => {
  const fs = firestore as unknown as Firestore
  fs.Firestore = window.firebase.firestore as unknown as FirestoreNamespace

  return Store.Default(fs)
})

export const convertDates = (obj: any) => {
  for (const prop in obj) {
    if (obj[prop] instanceof window.firebase.firestore.Timestamp) {
      obj[prop] = obj[prop].toDate()
    } else if (typeof obj[prop] === 'object') {
      convertDates(obj[prop])
    } else if (Array.isArray(obj[prop])) {
      obj[prop].forEach(convertDates)
    }
  }
  return obj
}
