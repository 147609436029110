import { customElement, LitElement, html, property, css } from 'lit-element'
import { connect } from '@captaincodeman/redux-connect-element'
import { translateUnsafeHTML as th } from 'lit-translate'
import './form-radio-group'
import '@polymer/paper-radio-button/paper-radio-button'

import { store, FormActions, RootState, FormSelectors } from '../../store'
import { VisitInfo } from '@healthspaces/hsuite-data/models';
import { baseStyle, fontStyle } from '../shared-styles';

declare global {
  interface HTMLElementTagNameMap {
    'visit-backpain': VisitBackPainElement;
  }
}

@customElement('visit-backpain')
export class VisitBackPainElement extends connect(store, LitElement) {
  @property({ type: Object }) info: VisitInfo

  mapState(state: RootState) {
    return {
      info: FormSelectors.visit_info(state),
    }
  }

  mapEvents() {
    return {
      'form-radio-group-changed': (e: CustomEvent) => FormActions.visitUpdate(e.detail),
    }
  }

  render() {
    return html`
      <p>${th('visit-backpain.instructions')}</p>

      <h3>${th('visit-backpain.pain_intensity.title')}</h3>
      <section>
        <form-radio-group .data=${this.info} key="back_pain.pain_intensity">
          <paper-radio-button name="mild_goes">${th('visit-backpain.pain_intensity.mild_goes')}</paper-radio-button>
          <paper-radio-button name="mild_vary">${th('visit-backpain.pain_intensity.mild_vary')}</paper-radio-button>
          <paper-radio-button name="moderate_goes">${th('visit-backpain.pain_intensity.moderate_goes')}</paper-radio-button>
          <paper-radio-button name="moderate_vary">${th('visit-backpain.pain_intensity.moderate_vary')}</paper-radio-button>
          <paper-radio-button name="severe_goes">${th('visit-backpain.pain_intensity.severe_goes')}</paper-radio-button>
          <paper-radio-button name="severe_vary">${th('visit-backpain.pain_intensity.severe_vary')}</paper-radio-button>
        </form-radio-group>
      </section>

      <h3>${th('visit-backpain.personal_care.title')}</h3>
      <section>
        <form-radio-group .data=${this.info} key="back_pain.personal_care">
          <paper-radio-button name="no_change">${th('visit-backpain.personal_care.no_change')}</paper-radio-button>
          <paper-radio-button name="some_pain">${th('visit-backpain.personal_care.some_pain')}</paper-radio-button>
          <paper-radio-button name="increase_pain">${th('visit-backpain.personal_care.increase_pain')}</paper-radio-button>
          <paper-radio-button name="change_routine">${th('visit-backpain.personal_care.change_routine')}</paper-radio-button>
          <paper-radio-button name="partial_pain">${th('visit-backpain.personal_care.partial_pain')}</paper-radio-button>
          <paper-radio-button name="complete_pain">${th('visit-backpain.personal_care.complete_pain')}</paper-radio-button>
        </form-radio-group>
      </section>

      <h3>${th('visit-backpain.lifting.title')}</h3>
      <section>
        <form-radio-group .data=${this.info} key="back_pain.lifting">
          <paper-radio-button name="no_change_heavy">${th('visit-backpain.lifting.no_change_heavy')}</paper-radio-button>
          <paper-radio-button name="increase_pain_heavy">${th('visit-backpain.lifting.increase_pain_heavy')}</paper-radio-button>
          <paper-radio-button name="manageable_heavy">${th('visit-backpain.lifting.manageable_heavy')}</paper-radio-button>
          <paper-radio-button name="manageable_light_medium">${th('visit-backpain.lifting.manageable_light_medium')}</paper-radio-button>
          <paper-radio-button name="lifting_light_weights">${th('visit-backpain.lifting.lifting_light_weights')}</paper-radio-button>
          <paper-radio-button name="pain_prevents">${th('visit-backpain.lifting.pain_prevents')}</paper-radio-button>
        </form-radio-group>
      </section>

      <h3>${th('visit-backpain.walking.title')}</h3>
      <section>
        <form-radio-group .data=${this.info} key="back_pain.walking">
          <paper-radio-button name="no_pain">${th('visit-backpain.walking.no_pain')}</paper-radio-button>
          <paper-radio-button name="normal_distance">${th('visit-backpain.walking.normal_distance')}</paper-radio-button>
          <paper-radio-button name="prevents_long_distance">${th('visit-backpain.walking.prevents_long_distance')}</paper-radio-button>
          <paper-radio-button name="prevents_intermediate_distance">${th('visit-backpain.walking.prevents_intermediate_distance')}</paper-radio-button>
          <paper-radio-button name="prevents_short_distance">${th('visit-backpain.walking.prevents_short_distance')}</paper-radio-button>
          <paper-radio-button name="pain_prevents">${th('visit-backpain.walking.pain_prevents')}</paper-radio-button>
        </form-radio-group>
      </section>

      <h3>${th('visit-backpain.sitting.title')}</h3>
      <section>
        <form-radio-group .data=${this.info} key="back_pain.sitting">
          <paper-radio-button name="no_pain">${th('visit-backpain.sitting.no_pain')}</paper-radio-button>
          <paper-radio-button name="choice_surfaces">${th('visit-backpain.sitting.choice_surfaces')}</paper-radio-button>
          <paper-radio-button name="sitting_one_hour">${th('visit-backpain.sitting.sitting_one_hour')}</paper-radio-button>
          <paper-radio-button name="sitting_half_hour">${th('visit-backpain.sitting.sitting_half_hour')}</paper-radio-button>
          <paper-radio-button name="sitting_ten_minutes">${th('visit-backpain.sitting.sitting_ten_minutes')}</paper-radio-button>
          <paper-radio-button name="pain_prevents">${th('visit-backpain.sitting.pain_prevents')}</paper-radio-button>
        </form-radio-group>
      </section>

      <h3>${th('visit-backpain.standing.title')}</h3>
      <section>
        <form-radio-group .data=${this.info} key="back_pain.standing">
          <paper-radio-button name="no_pain">${th('visit-backpain.standing.no_pain')}</paper-radio-button>
          <paper-radio-button name="increased_pain">${th('visit-backpain.standing.increased_pain')}</paper-radio-button>
          <paper-radio-button name="standing_one_hour">${th('visit-backpain.standing.standing_one_hour')}</paper-radio-button>
          <paper-radio-button name="standing_half_hour">${th('visit-backpain.standing.standing_half_hour')}</paper-radio-button>
          <paper-radio-button name="standing_ten_minutes">${th('visit-backpain.standing.standing_ten_minutes')}</paper-radio-button>
          <paper-radio-button name="pain_prevents">${th('visit-backpain.standing.pain_prevents')}</paper-radio-button>
        </form-radio-group>
      </section>

      <h3>${th('visit-backpain.sleeping.title')}</h3>
      <section>
        <form-radio-group .data=${this.info} key="back_pain.sleeping">
          <paper-radio-button name="no_pain">${th('visit-backpain.sleeping.no_pain')}</paper-radio-button>
          <paper-radio-button name="bed_pain">${th('visit-backpain.sleeping.bed_pain')}</paper-radio-button>
          <paper-radio-button name="sleep_three_quarter">${th('visit-backpain.sleeping.sleep_three_quarte')}</paper-radio-button>
          <paper-radio-button name="sleep_half_pain">${th('visit-backpain.sleeping.sleep_half_pain')}</paper-radio-button>
          <paper-radio-button name="sleep_one_quarter">${th('visit-backpain.sleeping.sleep_one_quarter')}</paper-radio-button>
          <paper-radio-button name="pain_prevents">${th('visit-backpain.sleeping.pain_prevents')}</paper-radio-button>
        </form-radio-group>
      </section>

      <h3>${th('visit-backpain.social_life.title')}</h3>
      <section>
        <form-radio-group .data=${this.info} key="back_pain.social_life">
          <paper-radio-button name="no_pain">${th('visit-backpain.social_life.no_pain')}</paper-radio-button>
          <paper-radio-button name="increased_pain">${th('visit-backpain.social_life.increased_pain')}</paper-radio-button>
          <paper-radio-button name="activity_pain">${th('visit-backpain.social_life.activity_pain')}</paper-radio-button>
          <paper-radio-button name="pain_restricted">${th('visit-backpain.social_life.pain_restricted')}</paper-radio-button>
          <paper-radio-button name="pain_prevents">${th('visit-backpain.social_life.pain_prevents')}</paper-radio-button>
        </form-radio-group>
      </section>

      <h3>${th('visit-backpain.traveling.title')}</h3>
      <section>
        <form-radio-group .data=${this.info} key="back_pain.traveling">
          <paper-radio-button name="no_pain">${th('visit-backpain.traveling.no_pain')}</paper-radio-button>
          <paper-radio-button name="increased_pain">${th('visit-backpain.traveling.increased_pain')}</paper-radio-button>
          <paper-radio-button name="no_alternative_travel">${th('visit-backpain.traveling.no_alternative_travel')}</paper-radio-button>
          <paper-radio-button name="aleternative_travel">${th('visit-backpain.traveling.aleternative_travel')}</paper-radio-button>
          <paper-radio-button name="restricted_lying">${th('visit-backpain.traveling.restricted_lying')}</paper-radio-button>
          <paper-radio-button name="pain_restricts">${th('visit-backpain.traveling.pain_restricts')}</paper-radio-button>
        </form-radio-group>
      </section>

      <h3>${th('visit-backpain.employment_homemaking.title')}</h3>
      <section>
        <form-radio-group .data=${this.info} key="back_pain.employment_homemaking">
          <paper-radio-button name="no_pain">${th('visit-backpain.employment_homemaking.no_pain')}</paper-radio-button>
          <paper-radio-button name="increased_pain">${th('visit-backpain.employment_homemaking.increased_pain')}</paper-radio-button>
          <paper-radio-button name="physical_activites">${th('visit-backpain.employment_homemaking.physical_activites')}</paper-radio-button>
          <paper-radio-button name="light_duties">${th('visit-backpain.employment_homemaking.light_duties')}</paper-radio-button>
          <paper-radio-button name="prevent_light_duties">${th('visit-backpain.employment_homemaking.prevent_light_duties')}</paper-radio-button>
          <paper-radio-button name="pain_prevents">${th('visit-backpain.employment_homemaking.pain_prevents')}</paper-radio-button>
        </form-radio-group>
      </section>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
      css`
        paper-radio-button {
          display: block;
        }
      `
    ]
  }
}
