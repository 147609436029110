import { customElement, LitElement, html, css, property } from 'lit-element'
import { registerTranslateConfig, use } from 'lit-translate'

import '@polymer/paper-button/paper-button'

declare global {
  interface HTMLElementTagNameMap {
    'language-select': LanguageSelectElement
  }
}

const languages = {
  'en': 'English',
  'es': 'Español',
}

registerTranslateConfig({
  loader: lang => fetch(`/i18n/${lang}.json`).then(res => res.json())
})

@customElement('language-select')
export class LanguageSelectElement extends LitElement {
  @property({ type: String }) value: string = 'en'

  updated() {
    use(this.value)
  }

  render() {
    return html`
      ${Object.keys(languages).map(lang => html`
      <paper-button ?disabled=${this.value === lang} @click=${() => this.value = lang}>${languages[lang]}</paper-button>
      `)}
    `
  }

  static get styles() {
    return [
      css`
        :host {
          display: block;
        }

        paper-button {
          background-color: transparent;
          color: #279682;
        }

        paper-button[disabled] {
          background-color: #3be2c4;
          color: #333;
        }
      `
    ]
  }
}
