import { Context } from '@healthspaces/hsuite-data/repositories/repository';
import { IStore } from '@healthspaces/hsuite-data/store';
import { put } from 'redux-saga/effects';
import { datastore } from '../firestore';
import { PracticeActions } from './actions';
import { takeAuth } from '../saga-helpers';

export default [
  fetchPracticeListener,
]

function* fetchPracticeListener() {
  yield takeAuth(fetchPracticeSaga)
}

function* fetchPracticeSaga(ctx: Context) {
  const db: IStore = yield datastore

  yield put(PracticeActions.requestPractice(ctx.practice))
  try {
    const practice = yield db.practices.get(ctx, ctx.practice)
    yield put(PracticeActions.receivePractice(ctx.practice, practice))
  } catch (err) {
    yield put(PracticeActions.abortedPractice(err))
  }
}
