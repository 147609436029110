import { customElement, LitElement, html, property } from 'lit-element'
import { translate as t, translateUnsafeHTML as th } from 'lit-translate'
import './form-radio-group'
import './form-input'

import { HabitItem } from '@healthspaces/hsuite-data/models';
import { fontStyle } from '../shared-styles';

declare global {
  interface HTMLElementTagNameMap {
    'medical-social-smoking': MedicalSocialSmokingElement;
  }
}

@customElement('medical-social-smoking')
export class MedicalSocialSmokingElement extends LitElement {
  @property({ type: Object }) smoking: HabitItem

  onPackSizeChanged(e: CustomEvent) {
    this.smoking.frequency = parseInt(e.detail.frequency)
    this.notify(e)
  }

  onStartDateChanged(e: CustomEvent) {
    this.smoking.started = new Date(e.detail.started)
    this.notify(e)
  }

  onEndedDateChanged(e: CustomEvent) {
    this.smoking.ended = new Date(e.detail.ended)
    this.notify(e)
  }

  notify(e: CustomEvent) {
    e.stopPropagation()
    const evt = new CustomEvent('medical-social-smoking-updated', {
      bubbles: true,
      composed: true,
      detail: this.smoking,
    })
    this.dispatchEvent(evt)
  }

  render() {
    return html`
    <h3>${th('medical-social-smoking.title')}</h3>
    <form-radio-group .data=${this.smoking} key="frequency" @form-radio-group-changed=${this.onPackSizeChanged}>
      <paper-radio-button name="0">${t('medical-social-smoking.none')}</paper-radio-button>
      <paper-radio-button name="1">${t('medical-social-smoking.1')}</paper-radio-button>
      <paper-radio-button name="2">${t('medical-social-smoking.2')}</paper-radio-button>
      <paper-radio-button name="3">${t('medical-social-smoking.3')}</paper-radio-button>
    </form-radio-group>

    <h3>${th('medical-social-smoking.started.title')}</h3>
    <form-input .data=${this.smoking} key="started" type="date" title=${t('medical-social-smoking.started.label')} @form-input-changed=${this.onStartDateChanged}></form-input>

    <h3>${th('medical-social-smoking.ended.title')}</h3>
    <form-input .data=${this.smoking} key="ended" type="date" title=${t('medical-social-smoking.ended.label')} @form-input-changed=${this.onEndedDateChanged}></form-input>
    `
  }

  static get styles() {
    return [
      fontStyle,
    ]
  }
}
