import { Context } from '@healthspaces/hsuite-data/repositories/repository';
import { IStore } from '@healthspaces/hsuite-data/store';
import { put, take, cancelled } from 'redux-saga/effects';
import { datastore } from '../firestore';
import { ClinicActions } from './actions';
import { eventChannel, buffers } from 'redux-saga';
import { Clinic } from '@healthspaces/hsuite-data/models';
import { takeAuth } from '../saga-helpers';

export default [
  fetchClinicListener,
]

function* fetchClinicListener() {
  yield takeAuth(fetchClinicSaga)
}

function* fetchClinicSaga(ctx: Context) {
  const db: IStore = yield datastore

  yield put(ClinicActions.requestClinic(ctx.practice, ctx.clinic))

  const listener = eventChannel(emit => db.clinics.watch(ctx, ctx.clinic,
    (clinic, error) => emit({ clinic, error })
  ), buffers.fixed(1))

  try {
    while (true) {
      const { clinic, error }: { clinic: Clinic, error: Error } = yield take(listener)
      if (clinic) {
        yield put(ClinicActions.receiveClinic(ctx.practice, clinic))
      } else {
        console.error(error)
        yield put(ClinicActions.abortedClinic(error))
      }
    }
  } finally {
    if (yield cancelled()) {
      listener.close()
    }
  }
}
