import { customElement, LitElement, html, property } from 'lit-element'
import { connect } from '@captaincodeman/redux-connect-element'
import { translate as t } from 'lit-translate'

import '../address-input'
import { store, FormActions, RootState, FormSelectors } from '../../store'
import { baseStyle, fontStyle } from '../shared-styles';
import { PatientInfo } from '@healthspaces/hsuite-data'
import './form-image'

declare global {
  interface HTMLElementTagNameMap {
    'patient-insurance-image': PatientInsuranceImage;
  }
}

@customElement('patient-insurance-image')
export class PatientInsuranceImage extends connect(store, LitElement) {
  @property({ type: Object }) info: PatientInfo
  @property({ type: Number }) progress: number

  mapState(state: RootState) {
    return {
      info: FormSelectors.patient_info(state),
      progress: FormSelectors.upload_progress(state),
    }
  }

  mapEvents() {
    return {
      'form-image-changed': (e: CustomEvent) => FormActions.uploadImage(e.detail.type, e.detail.blob),
    }
  }

  render() {
    return html`
      <h2>${t('patient-insurance-image.title')}</h2>

      <section>
        <form-image key="insurance_card" .label=${t('patient-insurance-image.title')} .value=${this.info.insurance_card} .progress=${this.progress}></form-image>
      </section>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
    ]
  }
}
