export enum FormStage {
  Welcome,
  Page,
  Legal,
  Complete,
}

export const pages = [
  'patient-driving-license',
  'patient-demographics',
  'patient-contact-info',
  'patient-contacts',
  'patient-insurance-image',
  'patient-insurances',
  'visit-reason',
  'visit-treatment',
  'visit-previous',
  'visit-systems',
  'visit-pain',
  // 'visit-mri',
  // 'visit-backpain',
  // 'visit-lowerpain',
  'medical-social',
  'medical-conditions',
  'medical-family',
  'medical-medications',
  'medical-allergies',
  'medical-surgeries',
  'medical-hospitalizations',
]
