import { customElement, LitElement, html, property, PropertyValues } from 'lit-element'

import '@polymer/paper-button';
import '@polymer/paper-dialog';
import '@polymer/paper-dialog-scrollable';
import { PaperDialogElement } from '@polymer/paper-dialog';

declare global {
  interface HTMLElementTagNameMap {
    'policy-dialog': PolicyDialogElement;
  }
}

@customElement('policy-dialog')
export class PolicyDialogElement extends LitElement {
  @property({ type: String }) url: string
  @property({ type: String }) title: string

  dialog: PaperDialogElement

  show() {
    this.dialog.open()
  }

  firstUpdated(changedProperties: PropertyValues) {
    // re-parent dialog to fix stacking
    this.dialog = this.shadowRoot.querySelector('paper-dialog')
    document.body.appendChild(this.dialog);
  }

  render() {
    return html`
    <paper-dialog with-backdrop>
      <h2>${this.title}</h2>
      <paper-dialog-scrollable>
        <embed type="application/pdf" src="${this.url}#toolbar=0" style="width:80vw;height:60vh;">
      </paper-dialog-scrollable>
      <div style="display:flex;justify-content:flex-end;">
        <paper-button dialog-dismiss>OK</paper-button>
      </div>
    </paper-dialog>
    `
  }
}
