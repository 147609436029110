import { customElement, LitElement, html, property } from 'lit-element'
import { repeat } from 'lit-html/directives/repeat'
import { connect } from '@captaincodeman/redux-connect-element'

import { Visit, Signin } from '@healthspaces/hsuite-elements/models';

import { store, VisitSelectors, VisitActions, SigninSelectors, SigninActions } from '../store'
import { RootState } from '../store/reducer';
import { baseStyle, fontStyle } from './shared-styles';
import './signin-card'
import './visit-card'

declare global {
  interface HTMLElementTagNameMap {
    'visit-list': VisitListElement;
  }
}

@customElement('visit-list')
export class VisitListElement extends connect(store, LitElement) {
  @property({ type: Array }) visits: Visit[]
  @property({ type: Array }) signins: Signin[]

  mapState(state: RootState) {
    return {
      visits: VisitSelectors.visits(state),
      signins: SigninSelectors.signins(state),
    }
  }

  mapEvents() {
    return {
      'signin-select': (e: CustomEvent) => SigninActions.selectSignin(e.detail),
      'visit-select': (e: CustomEvent) => VisitActions.selectVisit(e.detail),
    }
  }

  render() {
    return html`
    <p>Visits with pending PHR requests:</p>
    ${repeat(this.signins, x => x.id, signin => html`
    <signin-card .signin=${signin}></signin-card>`)}
    ${repeat(this.visits, x => x.id, visit => html`
    <visit-card .visit=${visit}></visit-card>`)}
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
    ]
  }
}
