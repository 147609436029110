import { LitElement, html, css, property, PropertyValues, query, customElement } from 'lit-element'
import { connect } from '@captaincodeman/redux-connect-element'
import { translate as t, translateUnsafeHTML as th } from 'lit-translate'
import { Insurance, Patient, Relationship } from '@healthspaces/hsuite-data/models'
import './form-input'
import '@polymer/paper-dropdown-menu/paper-dropdown-menu'
import '@polymer/paper-item/paper-item'
import '@polymer/paper-listbox/paper-listbox'
import '@polymer/paper-fab';
import '@polymer/paper-dialog';
import '@polymer/paper-dialog-scrollable';
import '@polymer/paper-radio-group/paper-radio-group'
import { PaperDropdownMenuElement } from '@polymer/paper-dropdown-menu/paper-dropdown-menu';
import { dobFormatter } from '../../service/dobFormatter';
import { PaperDialogElement } from '@polymer/paper-dialog';
import { PaperInputElement } from '@polymer/paper-input/paper-input';
import { store, FormActions, RootState, FormSelectors} from '../../store'
import { baseStyle, cardStyle, fontStyle } from '../shared-styles';
import { delete_icon } from '../icons';
import { PaperItemElement } from '@polymer/paper-item/paper-item'

declare global {
  interface HTMLElementTagNameMap {
    'patient-insurances': PatientInsuranceElement;
    'patient-insurance-list': PatientInsuranceListElement;
    'patient-insurance-card': PatientInsuranceCardElement;
    'patient-insurance-edit': PatientInsuranceEditElement;
  }
}

const emptyInsurance: Insurance = {
  id: '',
  group: '',
  provider: '',
  policyholder: '',
  relationship: Relationship.Self,
  dob: null,
  ssn: '',
}

@customElement('patient-insurances')
export class PatientInsuranceElement extends connect(store, LitElement) {
  @property({type: Object}) patient: Patient

  mapState(state: RootState) {
    return {
      patient: FormSelectors.patient(state),
    }
  }

  mapEvents() {
    return {
      'insurance-add': (e: CustomEvent<Insurance>) => FormActions.patientInfoArrayAdd("insurance", e.detail),
      'insurance-update': (e: CustomEvent<{ old: Insurance, new: Insurance }>) => FormActions.patientInfoArrayUpdate('insurance', e.detail.old, e.detail.new),
      'insurance-delete': (e: CustomEvent) => FormActions.patientInfoArrayDelete('insurance', e.detail),
     }
  }

  render() {
    return html`
    <p>${ this.patient.info.insurance.length
      ? t('patient-insurances.instructions.filled')
      : th('patient-insurances.instructions.empty', { icon: '<iron-icon icon="add"></iron-icon>' })
    }</p>
    <patient-insurance-list .insurance=${this.patient.info.insurance}></patient-insurance-list>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
      css`
        svg {
          width: 1em;
          height: 1em;
        }
      `
    ]
  }
}

@customElement('patient-insurance-list')
export class PatientInsuranceListElement extends LitElement {
  @property({ type: Array }) insurance: Insurance[]

  @query('patient-insurance-edit')
  editInsurance: PatientInsuranceEditElement

  async add(edit: Event) {
    try{
      const insurance = await this.editInsurance.open(emptyInsurance)
      const evt = new CustomEvent('insurance-add', {
        bubbles: true,
        composed: true,
        detail: insurance,
      })
      this. dispatchEvent(evt)
    } catch(err) {
      //cancelled
    }
  }

  async edit(e: Event){
    const el = <PatientInsuranceCardElement>e.target
    const insurance = el.insurance
    try{
      const updated = await this.editInsurance.open(insurance)
      const evt = new CustomEvent('insurance-update', {
        bubbles: true,
        composed: true,
        detail: {
          old: insurance,
          new: updated,
        },
      })
      this.dispatchEvent(evt)
    } catch (err) {
      //cancelled
    }
  }

  delete(e: Event) {
    e.stopPropagation()
    const el = <HTMLButtonElement>e.target
    const insurance = el.closest('patient-insurance-card').insurance
    const evt = new CustomEvent('insurance-delete', {
      bubbles: true,
      composed: true,
      detail: insurance
    })
    this.dispatchEvent(evt)
  }

  render() {
    return html`
    ${this.insurance.map(insur => html`
    <patient-insurance-card .insurance=${insur} @click=${this.edit} @delete=${this.delete}></patient-insurance-card>`)}
    <paper-fab icon="add" @click=${this.add}></paper-fab>
    <patient-insurance-edit></patient-insurance-edit>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
    ]
  }
}

@customElement('patient-insurance-card')
export class PatientInsuranceCardElement extends LitElement {
  @property({ type: Object }) insurance: Insurance

  delete(e: Event) {
    e.stopPropagation()
    const evt = new CustomEvent('delete', {
      bubbles: true,
      composed: true,
    })
    this.dispatchEvent(evt)
  }

  convertSsn(ssn: string){
    ssn = ssn.replace(/\d(?=\d{4})/g, "*")
    return ssn
  }

  render() {
    let val: any = this.insurance.dob

    if (val.seconds != null){
      val = new Date(val.seconds * 1000)
      val = val && dobFormatter.format(val)
      this.insurance.dob = new Date(val)
    }
    else {
      val = new Date(val)
      val = val && dobFormatter.format(val)
      this.insurance.dob = new Date(val)
    }

    var newSSN = this.convertSsn(this.insurance.ssn)

    return html`
    <button @click=${this.delete}>${delete_icon}</button>
    <h2>
      ${t('patient-insurances.holder')}: ${this.insurance.policyholder}<br>
      ${t('patient-insurances.id')}: ${this.insurance.id}
    </h2>
    <p>
      ${t('patient-insurances.provider')}: ${this.insurance.provider}<br>
      ${t('patient-insurances.group')}: ${this.insurance.group}<br>
      ${t('patient-insurances.relationship')}: ${t('enums.relationship.'+ this.insurance.relationship)}<br>
      ${t('patient-insurances.dob')}: ${val}<br>
      ${t('patient-insurances.ssn')}: ${newSSN}
    </p>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
      cardStyle,
      css`
        :host {
          padding: 8px;
          cursor: pointer;
          display: flex;
          align-items: center;
        }
        h2, p {
          margin: 8px 0;
        }
        h2 { width: 50%; }
        p { width: 40% }
        button {
          color: #999;
          background-color: transparent;
          border: none;
          outline: none;
          margin-right: 8px;
        }
        button svg {
          width: 2em;
          height: 2em;
        }
      `
    ]
  }
}

@customElement('patient-insurance-edit')
export class PatientInsuranceEditElement extends LitElement {
  @property({ type: Object }) insurance: Insurance = emptyInsurance

  private dialog: PaperDialogElement
  private resolve: (Insurances) => void
  private reject: () => void

  firstUpdated(changedProperties: PropertyValues) {
    // re-parent dialog to fix stacking
    this.dialog = this.shadowRoot.querySelector('paper-dialog')
    this.dialog = document.body.appendChild(this.dialog);
    this.dialog.addEventListener('form-input-changed', (e: CustomEvent) => {
      this.insurance = { ...this.insurance, ...e.detail }
    })
  }

  onIDChange(e: Event){
    const el = <PaperInputElement>e.target
    this.insurance = {...this.insurance, id: el.value}
  }

  onGroupChange(e: Event){
    const el = <PaperInputElement>e.target
    this.insurance = {...this.insurance, group: el.value}
  }

  onProviderChange(e: Event){
    const el = <PaperInputElement>e.target
    this.insurance = {...this.insurance, provider: el.value}
  }

  onPolicyHolderChange(e: Event){
    const el = <PaperInputElement>e.target
    this.insurance = {...this.insurance, policyholder: el.value}
  }

  onRelationshipChange(e: CustomEvent){
    const selected = e.detail.value as PaperItemElement
    this.insurance = {...this.insurance, relationship: selected.getAttribute('value') as Relationship }
  }

  onSsnChange(e: Event){
    const el = <PaperInputElement>e.target
    this.insurance = {...this.insurance, ssn: el.value}
  }

  convertSsn(ssn: string){
    ssn = ssn.replace(/\d(?=\d{4})/g, "*")
    return ssn
  }

  open(insurance: Insurance): Promise<Insurance> {
    return new Promise ((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
      this.insurance = insurance
      this.dialog.open()
    })
  }

  save() {
    this.resolve(this.insurance)
    this.dialog.close()
  }

  hide() {
    this.reject()
  }

  // used to prevent closing the drop-down list from triggering hide (on the dialog)
  prevent(e: Event) {
    e.stopPropagation()
    e.preventDefault()
  }

  render() {
    var newSSN = this.convertSsn(this.insurance.ssn)
    return html`
    <paper-dialog style="width: 80vw" with-backdrop no-cancel-on-outside-click @iron-overlay-closed=${this.hide}>
    <h2 style="color: #666">${t('patient-insurances.title')}</h2>
    <paper-dialog-scrollable>
    <paper-input type="text" always-float-label label=${t('patient-insurances.id')} .value=${this.insurance.id} @change=${this.onIDChange}></paper-input>
    <paper-input type="text" always-float-label label=${t('patient-insurances.group')} .value=${this.insurance.group} @change=${this.onGroupChange}></paper-input>
    <paper-input type="text" always-float-label label=${t('patient-insurances.provider')} .value=${this.insurance.provider} @change=${this.onProviderChange}></paper-input>
    <paper-input type="text" always-float-label label=${t('patient-insurances.holder')} .value=${this.insurance.policyholder} @change=${this.onPolicyHolderChange}></paper-input>
    <form-input .data=${this.insurance} key="dob" title=${t('patient-insurances.dob')} type="date"></form-input>
    <paper-dropdown-menu .value=${t('enums.relationship.' + this.insurance.relationship)} style="width: 100%;" label="Relationship" @selected-item-changed=${this.onRelationshipChange} @iron-overlay-closed=${this.prevent}>
      <paper-listbox attr-for-selected="value" style="position:relative; width: 565px; height: 352px; overflow: scroll;" slot="dropdown-content">
        ${Object.values(Relationship).map(rel => html`
          <paper-item value=${rel}>${t('enums.relationship.' + rel)}</paper-item>
        `)}
      </paper-listbox>
    </paper-dropdown-menu>
    <paper-input type="string" maxlength="9" always-float-label label=${t('patient-insurances.ssn')} .value=${newSSN} @change=${this.onSsnChange}></paper-input>
    </paper-dialog-scrollable>
    <div class="buttons" style="display:flex; align-items: center; align-content: flex-end;">
      <paper-button dialog-dismiss>${t('dialog.cancel')}</paper-button>
      <paper-button dialog-confirm autofocus @click=${this.save}>${t('dialog.save')}</paper-button>
    </div>
    </paper-dialog>
    `
  }
}

