import { customElement, LitElement, html, css, property, PropertyValues, query } from 'lit-element'
import { connect } from '@captaincodeman/redux-connect-element'
import { translate as t, translateUnsafeHTML as th } from 'lit-translate'
import '@polymer/paper-fab';
import '@polymer/paper-dialog';
import '@polymer/paper-dialog-scrollable';
import '@polymer/paper-radio-group/paper-radio-group'
import '@polymer/paper-dropdown-menu/paper-dropdown-menu'
import '@polymer/paper-item/paper-item'
import '@polymer/paper-listbox/paper-listbox'
import { PaperDropdownMenuElement } from '@polymer/paper-dropdown-menu/paper-dropdown-menu';
import { PaperRadioButtonElement } from '@polymer/paper-radio-button';
import { PaperDialogElement } from '@polymer/paper-dialog';
import { PaperInputElement } from '@polymer/paper-input/paper-input';
import { store, FormActions, RootState, FormSelectors } from '../../store'
import { Relationship, ContactMethod, OtherContact, ContactLevel, ContactPhone, PhoneType, Patient, ContactInfo } from '@healthspaces/hsuite-data/models';
import { baseStyle, cardStyle, fontStyle } from '../shared-styles';
import { delete_icon } from '../icons';
import './form-checkbox'
import './patient-contact-info'
import { PaperItemElement } from '@polymer/paper-item/paper-item'

declare global {
  interface HTMLElementTagNameMap {
    'patient-contacts': PatientContactsElement;
    'patient-contact-list': PatientContactListElement;
    'patient-contact-card': PatientContactCardElement;
    'patient-contact-edit': PatientContactEditElement;
  }
}

const emptyContact = () => <OtherContact>{
  name: '',
  level: ContactLevel.Regular,
  relationship: Relationship.Self,
  methods: [
    {
      method: ContactMethod.Phone,
      info: { number: '', type: PhoneType.Cell, message: false }
    },
  ],
}

@customElement('patient-contacts')
export class PatientContactsElement extends connect(store, LitElement) {
  @property({ type: Object }) patient: Patient

  mapState(state: RootState) {
    return {
      patient: FormSelectors.patient(state),
    }
  }

  mapEvents() {
    return {
      'contact-add': (e: CustomEvent<OtherContact>) => FormActions.patientInfoArrayAdd("other_contacts", e.detail),
      'contact-update': (e: CustomEvent<{ old: OtherContact, new: OtherContact }>) => FormActions.patientInfoArrayUpdate('other_contacts', e.detail.old, e.detail.new),
      'contact-delete': (e: CustomEvent<OtherContact>) => FormActions.patientInfoArrayDelete('other_contacts', e.detail),
    }
  }

  render() {
    return html`
      <p>${ this.patient.info.other_contacts.length
        ? t('patient-contacts.instructions.filled')
        : th('patient-contacts.instructions.empty', { icon: '<iron-icon icon="add"></iron-icon>' })
      }</p>
      <patient-contact-list .contacts=${this.patient.info.other_contacts}></patient-contact-list>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
      css`
        svg {
          width: 1em;
          height: 1em;
        }
      `
    ]
  }
}

@customElement('patient-contact-list')
export class PatientContactListElement extends LitElement {
  @property({ type: Array }) contacts: OtherContact[]
  @query('patient-contact-edit')
  editContact: PatientContactEditElement

  async add(e: Event) {
    try {
      const contact = await this.editContact.open(emptyContact())
      const evt = new CustomEvent('contact-add', {
        bubbles: true,
        composed: true,
        detail: contact,
      })
      this.dispatchEvent(evt)
    } catch (err) {
      console.error(err)
    }
  }

  async edit(e: Event) {
    const el = <PatientContactCardElement>e.target
    const contact = el.contact
    try {
      const updated = await this.editContact.open(contact)
      const evt = new CustomEvent('contact-update', {
        bubbles: true,
        composed: true,
        detail: {
          old: contact,
          new: updated,
        },
      })
      this.dispatchEvent(evt)
    } catch (err) {
      console.error(err)
    }
  }

  delete(e: Event) {
    e.stopPropagation()
    const el = <HTMLButtonElement>e.target
    const contact = el.closest('patient-contact-card').contact
    const evt = new CustomEvent('contact-delete', {
      bubbles: true,
      composed: true,
      detail: contact,
    })
    this.dispatchEvent(evt)
  }

  render() {
    return html`${this.contacts.map(cont => html`
      <patient-contact-card .contact=${cont} @click=${this.edit} @delete=${this.delete}></patient-contact-card>`)}
      <paper-fab icon="add" @click=${this.add}></paper-fab>
      <patient-contact-edit></patient-contact-edit>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
    ]
  }
}

@customElement('patient-contact-card')
export class PatientContactCardElement extends LitElement {
  @property({ type: Object }) contact: OtherContact

  delete(e: Event) {
    e.stopPropagation()
    const evt = new CustomEvent('delete', {
      bubbles: true,
      composed: true,
    })
    this.dispatchEvent(evt)
  }

  render() {
    return html`
    <button @click=${this.delete}>${delete_icon}</button>
    <h2>${t('patient-contact-card.name')}: ${this.contact.name}</h2>
    <ul>
      <li>${t('patient-contact-card.level')}: ${t('enums.contact-level.'+ this.contact.level)}</li>
      <li>${t('patient-contact-card.relationship')}: ${t('enums.relationship.'+ this.contact.relationship)}</li>
        ${this.contact.methods.map(method => html`
      <li>${this.renderMethod(method)}</li>`)}
    </ul>
    `
  }

  renderMethod(contact: ContactInfo) {
    switch (contact.method) {
      case ContactMethod.Email:
        return html`${t('patient-contact-card.email')}: ${contact.info.address}`
      case ContactMethod.Phone:
        return html`${t('enums.phone-type.' + contact.info.type)}: ${contact.info.number}`
      case ContactMethod.Postal:
        return html`${t('patient-contact-card.postal')}:
          ${contact.info.address.number}
          ${contact.info.address.street}
          ${contact.info.address.city}
          ${contact.info.address.state}
          ${contact.info.address.zip}
          ${contact.info.address.country}
        `
      default:
        return t('patient-contact-card.unknown')
    }
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
      cardStyle,
      css`
        :host {
          padding: 8px;
          cursor: pointer;
          display: flex;
          align-items: center;
        }

        h2, p {
          margin: 1px 0;
        }

        h2 { width: 100%; }

        p { width: 50% }

        button {
          color: #999;
          background-color: transparent;
          border: none;
          outline: none;
          margin-right: 8px;
        }

        button svg {
          width: 2em;
          height: 2em;
        }

        ul {
          width: 100%;
          list-style: none;
          display: flex;
          flex-wrap: wrap;
        }

        li { margin-right: 6px; }
      `
    ]
  }
}

@customElement('patient-contact-edit')
export class PatientContactEditElement extends LitElement {
  @property({ type: Object }) contact: OtherContact

  get method() {
    return <ContactPhone>this.contact.methods[0]
  }

  private dialog: PaperDialogElement
  private resolve: (Contact) => void
  private reject: () => void

  firstUpdated(changedProperties: PropertyValues) {
    // re-parent dialog to fix stacking
    this.dialog = this.shadowRoot.querySelector('paper-dialog')
    this.dialog = document.body.appendChild(this.dialog);
  }

  onLevelChange(e: Event) {
    const el = <PaperRadioButtonElement>e.target
    this.contact = { ...this.contact, level: <ContactLevel>el.name }
  }

  onNameChange(e: Event) {
    const el = <PaperInputElement>e.target
    this.contact = { ...this.contact, name: el.value }
  }

  onNumberChange(e: Event) {
    const el = <PaperInputElement>e.target
    this.contact.methods[0] = <ContactPhone>{
      ...this.contact.methods[0],
      info: {
        ...this.contact.methods[0].info,
        number: el.value,
      }
    }
  }

  onRelationshipChange(e: CustomEvent) {
    const selected = e.detail.value as PaperItemElement
    this.contact = { ...this.contact, relationship: selected.getAttribute('value') as Relationship }
  }

  async open(contact: OtherContact): Promise<OtherContact> {
    this.contact = contact
    await this.updateComplete
    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
      this.dialog.open()
    })
  }

  save() {
    this.resolve(this.contact)
    this.dialog.close()
  }

  hide() {
    this.reject()
  }

  // used to prevent closing the drop-down list from triggering hide (on the dialog)
  prevent(e: Event) {
    e.stopPropagation()
    e.preventDefault()
  }

  render() {
    return html`
      <paper-dialog style="width: 80vw" with-backdrop no-cancel-on-outside-click @iron-overlay-closed=${this.hide}>
        <h2 style="color: #666">${t('patient-contact-edit.title')}</h2>
        <p><label>${t('patient-contact-edit.type.label')}:</label></p>
        <paper-radio-group .selected=${this.contact.level} @change=${this.onLevelChange} style="margin-top:0">
          <paper-radio-button name=${ContactLevel.Regular}>${t('patient-contact-edit.type.regular.label')}: ${t('patient-contact-edit.type.regular.desc')}</paper-radio-button><br>
          <paper-radio-button name=${ContactLevel.Trusted}>${t('patient-contact-edit.type.trusted.label')}: ${t('patient-contact-edit.type.trusted.desc')}</paper-radio-button><br>
          <paper-radio-button name=${ContactLevel.Emergency}>${t('patient-contact-edit.type.emergency.label')}: ${t('patient-contact-edit.type.emergency.desc')}</paper-radio-button>
        </paper-radio-group>
        <paper-input type="text" always-float-label label=${t('patient-contact-edit.name')} .value=${this.contact.name} @change=${this.onNameChange}></paper-input>
        <paper-input type="string" always-float-label label=${t('patient-contact-edit.number')} .value=${this.method.info.number} @change=${this.onNumberChange}></paper-input>
        <paper-dropdown-menu style="width: 92%;" label=${t('patient-contact-edit.relationship')} .value=${t('enums.relationship.' + this.contact.relationship)} @selected-item-changed=${this.onRelationshipChange} @iron-overlay-closed=${this.prevent}>
          <paper-listbox attr-for-selected="value" style="position:relative; width: 565px; height: 352px; overflow: scroll;" slot="dropdown-content">
            ${Object.values(Relationship).map(rel => html`
              <paper-item value=${rel}>${t('enums.relationship.' + rel)}</paper-item>
            `)}
          </paper-listbox>
        </paper-dropdown-menu>
        <div class="buttons" style="display:flex; align-items: center; align-content: flex-end;">
          <paper-button dialog-dismiss>${t('dialog.cancel')}</paper-button>
          <paper-button dialog-confirm autofocus @click=${this.save}>${t('dialog.save')}</paper-button>
        </div>
      </paper-dialog>
    `
  }
}


