import { ActionsUnion, createAction } from '../actions';
import { Patient, PatientSummary, Pain, MedicalHistory } from '@healthspaces/hsuite-data/models';
import { FormStage } from './models';

export enum FormTypes {
  FORM_PATIENT = 'FORM_PATIENT',
  FORM_SIGNATURE = 'FORM_SIGNATURE',
  FORM_SIGNATURE_FETCH_REQUEST = 'FORM_SIGNATURE_FETCH_REQUEST',
  FORM_SIGNATURE_FETCH_SUCCESS = 'FORM_SIGNATURE_FETCH_SUCCESS',
  FORM_SIGNATURE_FETCH_FAILURE = 'FORM_SIGNATURE_FETCH_FAILURE',
  FORM_VALID = 'FORM_VALID',
  FORM_POSITION = 'FORM_POSITION',
  FORM_PREV = 'FORM_PREV',
  FORM_NEXT = 'FORM_NEXT',
  FORM_VISIT_RESET = 'FORM_VISIT_RESET',
  FORM_VISIT_UPDATE = 'FORM_VISIT_UPDATE',
  FORM_ADD_PAIN = 'FORM_ADD_PAIN',
  FORM_REMOVE_PAIN = 'FORM_REMOVE_PAIN',
  FORM_MEDICAL_HISTORY_UPDATE = 'FORM_MEDICAL_HISTORY_UPDATE',
  FORM_MEDICAL_HISTORY_FETCH_REQUEST = 'FORM_MEDICAL_HISTORY_FETCH_REQUEST',
  FORM_MEDICAL_HISTORY_FETCH_SUCCESS = 'FORM_MEDICAL_HISTORY_FETCH_SUCCESS',
  FORM_MEDICAL_HISTORY_FETCH_FAILURE = 'FORM_MEDICAL_HISTORY_FETCH_FAILURE',
  FORM_MEDICAL_HISTORY_ARRAY_ADD = 'FORM_MEDICAL_HISTORY_ARRAY_ADD',
  FORM_MEDICAL_HISTORY_ARRAY_UPDATE = 'FORM_MEDICAL_HISTORY_ARRAY_UPDATE',
  FORM_MEDICAL_HISTORY_ARRAY_DELETE = 'FORM_MEDICAL_HISTORY_ARRAY_DELETE',
  FORM_PATIENT_FETCH_REQUEST = 'FORM_PATIENT_FETCH_REQUEST',
  FORM_PATIENT_FETCH_SUCCESS = 'FORM_PATIENT_FETCH_SUCCESS',
  FORM_PATIENT_FETCH_FAILURE = 'FORM_PATIENT_FETCH_FAILURE',
  FORM_PATIENT_INFO_UPDATE = 'FORM_PATIENT_INFO_UPDATE',
  FORM_PATIENT_INFO_ARRAY_ADD = 'FORM_PATIENT_INFO_ARRAY_ADD',
  FORM_PATIENT_INFO_ARRAY_UPDATE = 'FORM_PATIENT_INFO_ARRAY_UPDATE',
  FORM_PATIENT_INFO_ARRAY_DELETE = 'FORM_PATIENT_INFO_ARRAY_DELETE',
  FORM_PATIENT_UPLOAD_IMAGE = 'FORM_PATIENT_UPLOAD_IMAGE',
  FORM_PATIENT_UPLOAD_IMAGE_PROGRESS = 'FORM_PATIENT_UPLOAD_IMAGE_PROGRESS',
  FORM_PATIENT_UPLOAD_IMAGE_COMPLETE = 'FORM_PATIENT_UPLOAD_IMAGE_COMPLETE',
  FORM_SCROLL_REQUIRED = 'FORM_SCROLL_REQUIRED',
  FORM_SCROLL_POSITION = 'FORM_SCROLL_POSITION',
}

export const FormActions = {
  patient: (patient: PatientSummary) => createAction(FormTypes.FORM_PATIENT, { patient }),
  signature: (image: string, empty: boolean) => createAction(FormTypes.FORM_SIGNATURE, { image, empty }),
  valid: (valid: boolean) => createAction(FormTypes.FORM_VALID, { valid }),
  signatureFetchRequest: () => createAction(FormTypes.FORM_SIGNATURE_FETCH_REQUEST),
  signatureFetchSuccess: (signature_exists: boolean) => createAction(FormTypes.FORM_SIGNATURE_FETCH_SUCCESS, { signature_exists }),
  signatureFetchFailure: (error: Error) => createAction(FormTypes.FORM_SIGNATURE_FETCH_FAILURE, { error }),

  position: (stage: FormStage, step: number) => createAction(FormTypes.FORM_POSITION, { stage, step }),
  prev: () => createAction(FormTypes.FORM_PREV),
  next: () => createAction(FormTypes.FORM_NEXT),

  visitReset: () => createAction(FormTypes.FORM_VISIT_RESET),
  visitUpdate: (value: any) => createAction(FormTypes.FORM_VISIT_UPDATE, { value }),
  addPain: (item: Pain) => createAction(FormTypes.FORM_ADD_PAIN, { item }),
  removePain: (item: Pain) => createAction(FormTypes.FORM_REMOVE_PAIN, { item }),

  medicalHistoryUpdate: (value: any) => createAction(FormTypes.FORM_MEDICAL_HISTORY_UPDATE, { value }),
  medicalHistoryRequest: (practice: string, patient: string) => createAction(FormTypes.FORM_MEDICAL_HISTORY_FETCH_REQUEST, { practice, patient }),
  medicalHistorySuccess: (medical_history: MedicalHistory) => createAction(FormTypes.FORM_MEDICAL_HISTORY_FETCH_SUCCESS, { medical_history }),
  medicalHistoryFailure: (error: Error) => createAction(FormTypes.FORM_MEDICAL_HISTORY_FETCH_FAILURE, { error }),

  medicalHistoryArrayAdd: (path: string, item: any) => createAction(FormTypes.FORM_MEDICAL_HISTORY_ARRAY_ADD, { path, item }),
  medicalHistoryArrayUpdate: (path: string, old: any, item: any) => createAction(FormTypes.FORM_MEDICAL_HISTORY_ARRAY_UPDATE, { path, old, item }),
  medicalHistoryArrayDelete: (path: string, item: any) => createAction(FormTypes.FORM_MEDICAL_HISTORY_ARRAY_DELETE, { path, item }),

  patientFetchRequest: () => createAction(FormTypes.FORM_PATIENT_FETCH_REQUEST),
  patientFetchSuccess: (patient: Patient) => createAction(FormTypes.FORM_PATIENT_FETCH_SUCCESS, { patient }),
  patientFetchFailure: (error: Error) => createAction(FormTypes.FORM_PATIENT_FETCH_FAILURE, { error }),
  patientInfoUpdate: (value: any) => createAction(FormTypes.FORM_PATIENT_INFO_UPDATE, { value }),
  patientInfoArrayAdd: (path: string, item: any) => createAction(FormTypes.FORM_PATIENT_INFO_ARRAY_ADD, { path, item }),
  patientInfoArrayUpdate: (path: string, old: any, item: any) => createAction(FormTypes.FORM_PATIENT_INFO_ARRAY_UPDATE, { path, old, item }),
  patientInfoArrayDelete: (path: string, item: any) => createAction(FormTypes.FORM_PATIENT_INFO_ARRAY_DELETE, { path, item }),

  uploadImage: (type: string, blob: Blob) => createAction(FormTypes.FORM_PATIENT_UPLOAD_IMAGE, { type, blob }),
  uploadImageProgress: (type: string, progress: number) => createAction(FormTypes.FORM_PATIENT_UPLOAD_IMAGE_PROGRESS, { type, progress }),
  uploadImageComplete: (type: string) => createAction(FormTypes.FORM_PATIENT_UPLOAD_IMAGE_COMPLETE, { type }),

  scrollRequired: (required: boolean) => createAction(FormTypes.FORM_SCROLL_REQUIRED, { required }),
  scrollPosition: (bottom: boolean) => createAction(FormTypes.FORM_SCROLL_POSITION, { bottom }),
}

export type FormActions = ActionsUnion<typeof FormActions>
