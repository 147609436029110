import { customElement, LitElement, html, css, property, PropertyValues } from 'lit-element'
import { unsafeHTML } from 'lit-html/directives/unsafe-html'
import { connect } from '@captaincodeman/redux-connect-element'
import UniversalRouter from 'universal-router';

import { store, RoutingSelectors } from '../store'
import { RootState } from '../store/reducer';

declare global {
  interface HTMLElementTagNameMap {
    'app-routes': AppRoutesElement;
  }
}

@customElement('app-routes')
export class AppRoutesElement extends connect(store, LitElement) {
  constructor() {
    super()
    this.router = new UniversalRouter(this.routes);
  }

  private router: UniversalRouter
  private routes = [{
    path: '/',
    action: () => `<view-home></view-home>`
  }, {
    path: '/signin',
    action: () => '<view-signin></view-signin>'
  }]

  @property({ type: String }) view: string

  mapState(state: RootState) {
    return {
      pathname: RoutingSelectors.pathname(state),
    }
  }

  private _pathname: string
  get pathname() { return this._pathname }
  set pathname(val) {
    this._pathname = val
    this.router
      .resolve(this._pathname)
      .then(action => this.view = action)
      .catch(error => this.shadowRoot.innerHTML = `<view-error>${error}</view-error>`)
  }

  shouldUpdate(changedProperties: PropertyValues) {
    return changedProperties.get('view') !== this.view
  }

  updated(changedProperties: PropertyValues) {
    if (changedProperties.has('view')) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return html`${unsafeHTML(this.view)}`
  }

  static get styles() {
    return [
      css`
      :host {
        display: block;
        box-sizing: border-box;
        padding: 12px;
        width: 100%;
        height: 100%;
      }
      `
    ]
  }
}
