import { MiddlewareAPI } from 'redux';

import { RootState } from './reducer';

const storageKey = 'hsuite-phr'
const storageVer = '20190506'
const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

function dateParser(key, value) {
  if (typeof value === "string" && dateFormat.test(value)) {
    return new Date(value);
  }

  return value;
}

export const loadState = (): RootState => {
  try {
    const serializedState = localStorage.getItem(storageKey)
    if (serializedState === null) {
      return undefined
    }
    const state = <RootState>JSON.parse(serializedState, dateParser)
    return (state['version'] === storageVer)
      ? state
      : undefined
  } catch (err) {
    console.error(err)
    return undefined
  }
}

export default ({ getState }: MiddlewareAPI) => next => action => {
  const value = next(action)
  const state = getState()

  try {
    const serializedState = JSON.stringify({
      ...state,
      version: storageVer,
    })
    localStorage.setItem(storageKey, serializedState)
  } catch (err) {
    console.error(err)
  }

  return value
}
