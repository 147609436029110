import { customElement, LitElement, html, css, property, query, PropertyValues } from 'lit-element'
import { connect } from '@captaincodeman/redux-connect-element'
import { translate as t, translateUnsafeHTML as th } from 'lit-translate'

import '@healthspaces/hsuite-elements/signature-pad';
import { SignaturePadElement } from '@healthspaces/hsuite-elements/signature-pad';
import '@polymer/paper-checkbox/paper-checkbox'
import './policy-dialog'
import { store, FormActions, RootState, FormSelectors } from '../../store'
import { baseStyle, fontStyle } from '../shared-styles';
import { Practice, Patient } from '@healthspaces/hsuite-data/models';
import { PracticeSelectors } from '../../store/practices/selectors';
import { PaperCheckboxElement } from '@polymer/paper-checkbox/paper-checkbox';
import { PolicyDialogElement } from './policy-dialog';

declare global {
  interface HTMLElementTagNameMap {
    'form-legal': FormLegalElement;
  }
}

@customElement('form-legal')
export class FormLegalElement extends connect(store, LitElement) {
  @property({ type: Object }) practice: Practice
  @property({ type: Object }) patient: Patient
  @property({ type: Number }) countAccepted = 0
  @property({ type: Boolean }) signatureEnabled = false
  @property({ type: Boolean }) signatureEmpty = true

  @query('signature-pad')
  signaturePad: SignaturePadElement

  @query('policy-dialog.privacy')
  privacyPolicy: PolicyDialogElement

  @query('policy-dialog.financial')
  financialPolicy: PolicyDialogElement

  mapState(state: RootState) {
    return {
      practice: PracticeSelectors.current(state),
      patient: FormSelectors.patient(state),
    }
  }

  async firstUpdated(changedProperties: PropertyValues) {
    this.signaturePad.addEventListener('signature-changed', e => this.signatureChanged())
    await this.signaturePad.updateComplete
    this.signatureChanged()
  }

  updated(changedProperties: PropertyValues) {
    this.signatureEnabled = this.countAccepted === 3
  }

  signatureChanged() {
    this.signatureEmpty = this.signaturePad.empty
    const image = this.signaturePad.image()
    store.dispatch(FormActions.signature(image, this.signatureEmpty))
  }

  clear(e: Event) {
    this.signaturePad.clear()
  }

  acceptedChanged(e: Event) {
    const el = <PaperCheckboxElement>e.target
    if (el.checked) {
      this.countAccepted++
    } else {
      this.countAccepted--
      this.signaturePad.clear()
    }
  }

  showPrivacyPolicy(e: Event) {
    this.privacyPolicy.show()
  }

  showFinancialPolicy(e: Event) {
    this.financialPolicy.show()
  }

  render() {
    return html`
      <p>${th('form-legal.instructions', {
        patient: this.patient.firstName + ' ' + this.patient.lastName,
        practice: this.practice.name,
      })}</p>

      <section>
        <h3>${th('form-legal.financial.title')}</h3>
        <p>${th('form-legal.financial.instructions', { practice: this.practice.name })}</p>
        <button @click=${this.showFinancialPolicy}>${t('form-legal.financial.view')}</button>
        <policy-dialog class="financial" title=${t('form-legal.financial.label')} .url=${this.practice.settings.financial}></policy-dialog>
        <paper-checkbox @change=${this.acceptedChanged}>${t('form-legal.financial.agree')}</paper-checkbox>
      </section>

      <section>
        <h3>${th('form-legal.privacy.title')}</h3>
        <p>${th('form-legal.privacy.instructions', { practice: this.practice.name })}</p>
        <button @click=${this.showPrivacyPolicy}>${t('form-legal.privacy.view')}</button>
        <policy-dialog class="privacy" title=${t('form-legal.privacy.label')} .url=${this.practice.settings.privacy}></policy-dialog>
        <paper-checkbox @change=${this.acceptedChanged}>${t('form-legal.privacy.agree')}</paper-checkbox>
      </section>

      <section>
        <h3>${th('form-legal.release.title')}</h3>
        <p>${th('form-legal.release.instructions', { practice: this.practice.name })}</p>
        <paper-checkbox @change=${this.acceptedChanged}>${t('form-legal.release.agree')}</paper-checkbox>
      </section>

      <p>${th('form-legal.signature.instructions')}:</p>
      <div id="signature">
        <signature-pad ?disabled=${!this.signatureEnabled}></signature-pad>
        <button @click=${this.clear} ?disabled=${this.signatureEmpty}>${th('form-legal.signature.clear')}</button>
      </div>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
      css`
        #signature {
          position: relative;
          width: 100%;
          height: 220px;
        }

        signature-pad {
          width: 100%;
          height: 100%;
          position: absolute;
        }

        section {
          border: 1px solid #ccc;
          background-color: #f7f7f7;
          margin: 16px 0;
          padding: 16px;
          overflow: auto;
        }

        button {
          font-size: 14px;
          padding: 8px 16px;
          border: none;
          border-radius: 4px;
          outline: none;
          color: var(--button-color);
          background-color: var(--button-background-color);
        }

        button[disabled] {
          display: none;
        }

        section p {
          font-size: 14px;
        }

        section button {
          display: block;
          float: right;
        }

        #signature button {
          position: absolute;
          top: 8px;
          left: 8px;
          opacity: 0.8;
        }

        h2, h3 {
          margin: 0;
        }
      `
    ]
  }
}
