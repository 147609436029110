import { customElement, LitElement, html, property } from 'lit-element'
import { connect } from '@captaincodeman/redux-connect-element'

import { store, AuthSelectors } from '../store'
import { RootState } from '../store/reducer';
import './visit-list'

declare global {
  interface HTMLElementTagNameMap {
    'view-home': ViewHomeElement;
  }
}

@customElement('view-home')
export class ViewHomeElement extends connect(store, LitElement) {
  @property({ type: Boolean }) locked: boolean

  mapState(state: RootState) {
    return {
      locked: AuthSelectors.locked(state),
    }
  }

  render() {
    return this.locked
      ? html`<view-forms></view-forms>`
      : html`<visit-list></visit-list>`
  }
}
