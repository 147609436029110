import { customElement, LitElement, html, property } from 'lit-element'
import '@polymer/paper-input/paper-input'
import { PaperInputElement } from '@polymer/paper-input/paper-input';

declare global {
  interface HTMLElementTagNameMap {
    'form-input': FormInputElement;
  }
}

@customElement('form-input')
export class FormInputElement extends LitElement {
  @property({ type: Object }) data: object
  @property({ type: String }) key: string
  @property({ type: String }) type: string
  @property({ type: String }) inputmode: string
  @property({ type: String }) title: string

  onChange(e: Event) {
    const el = <PaperInputElement>e.target
    const paths = this.key.split('.')
    const detail = {}
    let val = detail

    paths.forEach((path, i) => {
      if (i === paths.length - 1) {
        switch (this.type) {
          case 'date':
            val[path] = new Date(el.value)
            break
          default:
            val[path] = el.value
            break
        }
      } else {
        val[path] = {}
        val = val[path]
      }
    })

    const evt = new CustomEvent('form-input-changed', {
      bubbles: true,
      composed: true,
      detail,
    })
    this.dispatchEvent(evt)
  }

  render() {
    const paths = this.key.split('.')
    let val: any = this.data
    paths.forEach(path => {
      val = val[path]
    })

    switch (this.type) {
      case 'date':
        if (val == null || val == "0001-01-01T00:00:00Z"){
          val = undefined
        }
        else if (val.seconds != null){
          val = new Date(val.seconds * 1000)
          val = val && val.toISOString().substr(0, 10)
        }
        else {
          val = new Date(val)
          val = val && val.toISOString().substr(0, 10)
        }
        break
    }

    return html`
    <paper-input @change=${this.onChange} .type=${this.type} .inputmode=${this.inputmode} always-float-label .label=${this.title} .value=${val}></paper-input>
    `
  }
}
