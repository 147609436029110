import { customElement, LitElement, html, property, css } from 'lit-element'
import '@polymer/paper-checkbox/paper-checkbox'
import { PaperCheckboxElement } from '@polymer/paper-checkbox/paper-checkbox';

declare global {
  interface HTMLElementTagNameMap {
    'form-checkbox': FormCheckboxElement;
  }
}

@customElement('form-checkbox')
export class FormCheckboxElement extends LitElement {
  @property({ type: Object }) data: object
  @property({ type: String }) key: string
  @property({ type: String }) title: string

  onChange(e: Event) {
    const el = <PaperCheckboxElement>e.target
    const paths = this.key.split('.')
    const detail = {}
    let val = detail
    paths.forEach((path, i) => {
      if (i === paths.length - 1) {
        val[path] = el.checked
      } else {
        val[path] = {}
        val = val[path]
      }
    })
    const evt = new CustomEvent('form-checkbox-changed', {
      bubbles: true,
      composed: true,
      detail,
    })
    this.dispatchEvent(evt)
  }

  render() {
    const paths = this.key.split('.')
    let val = this.data
    paths.forEach(path => {
      val = val[path]
    })

    return html`
<paper-checkbox @change=${this.onChange} .checked=${val}>${this.title}</paper-checkbox>
`
  }

  static get styles() {
    return css`
      :host {
        --paper-checkbox-size: 24px;
      }
    `
  }
}
