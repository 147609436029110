import { customElement, LitElement, html, property, css } from 'lit-element'
import { connect } from '@captaincodeman/redux-connect-element'
import { translate as t, translateUnsafeHTML as th } from 'lit-translate'
import './form-radio-group'
import '@polymer/paper-radio-button/paper-radio-button'

import { store, FormActions, RootState, FormSelectors } from '../../store'
import { VisitInfo } from '@healthspaces/hsuite-data/models';
import { baseStyle, alternatingSections, fontStyle } from '../shared-styles';

declare global {
  interface HTMLElementTagNameMap {
    'visit-lowerpain': VisitLowerPainElement;
  }
}

const questions = [
  'usual_activities',
  'usual_recreation_activities',
  'into_out_bath',
  'walking_between_rooms',
  'socks_shoes',
  'squatting',
  'lifting_objects',
  'performing_light_duties',
  'performing_heavy_duties',
  'into_out_car',
  'walking_two_blocks',
  'walking_one_mile',
  'one_flight_stairs',
  'standing_one_hour',
  'sitting_one_hour',
  'running_even_ground',
  'running_uneven_ground',
  'sharp_turns_running',
  'hopping',
  'rolling_over_bed',
]

@customElement('visit-lowerpain')
export class VisitLowerPainElement extends connect(store, LitElement) {
  @property({ type: Object }) info: VisitInfo

  mapState(state: RootState) {
    return {
      info: FormSelectors.visit_info(state),
    }
  }

  mapEvents() {
    return {
      'form-radio-group-changed': (e: CustomEvent) => FormActions.visitUpdate(e.detail),
    }
  }

  render() {
    return html`
      <p>${th('visit-lowerpain.instructions')}:</p>

      ${questions.map(q => html`
      <section>
        <label>${th('visit-lowerpain.' + q)}</label>
        <form-radio-group .data=${this.info} key=${'lower_pain.' + q}>
          <paper-radio-button name="5"><span>${t('visit-lowerpain.5')}</span></paper-radio-button>
          <paper-radio-button name="4"><span>${t('visit-lowerpain.4')}</span></paper-radio-button>
          <paper-radio-button name="3"><span>${t('visit-lowerpain.3')}</span></paper-radio-button>
          <paper-radio-button name="2"><span>${t('visit-lowerpain.2')}</span></paper-radio-button>
          <paper-radio-button name="1"><span>${t('visit-lowerpain.1')}</span></paper-radio-button>
        </form-radio-group>
      </section>`)}
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
      alternatingSections,
      css`
        section {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px;
        }

        span {
          display: none;
        }

        @media screen and  (max-width: 767px) {
          section {
            flex-wrap: wrap;
          }

          label {
            width: 100%;
          }

          span, paper-radio-button {
            display: block;
          }
        }
      `
    ]
  }
}
