import { ActionsUnion, createAction } from '../actions';
import { Visit } from '@healthspaces/hsuite-data/models/visit'

export enum VisitTypes {
  VISIT_SELECT = 'VISIT_SELECT',
  VISITS_FETCH_REQUEST = 'VISITS_FETCH_REQUEST',
  VISITS_FETCH_SUCCESS = 'VISITS_FETCH_SUCCESS',
  VISITS_FETCH_FAILURE = 'VISITS_FETCH_FAILURE',
}

export const VisitActions = {
  selectVisit: (visit: Visit) => createAction(VisitTypes.VISIT_SELECT, { visit }),

  requestVisits: (practice: string, clinic: string) => createAction(VisitTypes.VISITS_FETCH_REQUEST, { practice, clinic }),
  receiveVisits: (visits: Visit[]) => createAction(VisitTypes.VISITS_FETCH_SUCCESS, { visits }),
  abortedVisits: (error: Error) => createAction(VisitTypes.VISITS_FETCH_FAILURE, { error }),
}

export type VisitActions = ActionsUnion<typeof VisitActions>
