import { createSelector } from 'reselect';

import { RootState } from '../reducer';
import { pages } from './models';

const getState = (state: RootState) => state.forms;

export namespace FormSelectors {
  export const signin = createSelector(
    [getState],
    (state) => state.signin
  )

  export const visit = createSelector(
    [getState],
    (state) => state.visit
  )

  export const patient = createSelector(
    [getState],
    (state) => state.patient
  )

  export const stage = createSelector(
    [getState],
    (state) => state.stage
  )

  export const step = createSelector(
    [getState],
    (state) => state.step
  )

  export const page = createSelector(
    [step],
    (step) => pages[step]
  )

  export const signature_exists = createSelector(
    [getState],
    (state) => state.signature_exists
  )

  export const scroll_required = createSelector(
    [getState],
    (state) => state.scroll_required
  )

  export const scrolled_to_end = createSelector(
    [getState],
    (state) => state.scrolled_to_end
  )

  export const scroll_indicator = createSelector(
    [scroll_required, scrolled_to_end],
    (scroll_required, scrolled_to_end) => scroll_required && !scrolled_to_end
  )

  export const valid = createSelector(
    [getState],
    (state) => state.valid && (!state.scroll_required || state.scrolled_to_end)
  )

  export const visit_info = createSelector(
    [getState],
    (state) => state.visit_info
  )

  export const medical_history = createSelector(
    [getState],
    (state) => state.medical_history
  )

  export const patient_info = createSelector(
    [getState],
    (state) => state.patient.info
  )

  export const upload_progress = createSelector(
    [getState],
    (state) => state.upload_progress
  )
}
