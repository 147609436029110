import { customElement, LitElement, html, property } from 'lit-element'
import { connect } from '@captaincodeman/redux-connect-element'
import { translate as t, translateUnsafeHTML as th } from 'lit-translate'
import './form-checkbox'
import './form-input'

import { store, FormActions, RootState, FormSelectors } from '../../store'
import { VisitInfo } from '@healthspaces/hsuite-data/models';
import { baseStyle, fontStyle } from '../shared-styles';

declare global {
  interface HTMLElementTagNameMap {
    'visit-treatment': VisitTreatmentElement;
  }
}

@customElement('visit-treatment')
export class VisitTreatmentElement extends connect(store, LitElement) {
  @property({ type: Object }) info: VisitInfo

  mapState(state: RootState) {
    return {
      info: FormSelectors.visit_info(state),
    }
  }

  mapEvents() {
    return {
      'form-checkbox-changed': (e: CustomEvent) => FormActions.visitUpdate(e.detail),
      'form-input-changed': (e: CustomEvent) => FormActions.visitUpdate(e.detail),
    }
  }

  render() {
    return html`
    <h3>${th('visit-treatment.seen.title')}</h3>
    <section>
      <form-checkbox .data=${this.info} key="initial_treatment.seen.emergency_room" title=${t('visit-treatment.seen.emergency_room')}></form-checkbox>
      <form-checkbox .data=${this.info} key="initial_treatment.seen.urgent_care" title=${t('visit-treatment.seen.urgent_care')}></form-checkbox>
      <form-checkbox .data=${this.info} key="initial_treatment.seen.physicians_office" title=${t('visit-treatment.seen.physicians_office')}></form-checkbox>
      <form-checkbox .data=${this.info} key="initial_treatment.seen.other" title=${t('visit-treatment.seen.other')}></form-checkbox>
    </section>

    <form-input .data=${this.info} key="initial_treatment.name" title=${t('visit-treatment.seen.name')}></form-input>

    <h3>${th('visit-treatment.type.title')}</h3>
    <section>
      <form-checkbox .data=${this.info} key="initial_treatment.type.brace" title=${t('visit-treatment.type.brace')}></form-checkbox>
      <form-checkbox .data=${this.info} key="initial_treatment.type.crutch" title=${t('visit-treatment.type.crutch')}></form-checkbox>
      <form-checkbox .data=${this.info} key="initial_treatment.type.cast" title=${t('visit-treatment.type.cast')}></form-checkbox>
      <form-checkbox .data=${this.info} key="initial_treatment.type.therapy" title=${t('visit-treatment.type.therapy')}></form-checkbox>
      <form-checkbox .data=${this.info} key="initial_treatment.type.chiropractic" title=${t('visit-treatment.type.chiropractic')}></form-checkbox>
    </section>

    <h3>${th('visit-treatment.medication.title')}</h3>
    <section>
      <form-checkbox .data=${this.info} key="initial_treatment.medication.narcotic" title=${t('visit-treatment.medication.narcotic')}></form-checkbox>
      <form-checkbox .data=${this.info} key="initial_treatment.medication.anti_inflammatory" title=${t('visit-treatment.medication.anti_inflammatory')}></form-checkbox>
      <form-checkbox .data=${this.info} key="initial_treatment.medication.muscle_relaxers" title=${t('visit-treatment.medication.muscle_relaxers')}></form-checkbox>
      <form-checkbox .data=${this.info} key="initial_treatment.medication.corticosteroids" title=${t('visit-treatment.medication.corticosteroids')}></form-checkbox>
      <form-checkbox .data=${this.info} key="initial_treatment.medication.injection" title=${t('visit-treatment.medication.injection')}></form-checkbox>
      <form-checkbox .data=${this.info} key="initial_treatment.medication.other" title=${t('visit-treatment.medication.other')}></form-checkbox>
    </section>

    <h3>${t('visit-treatment.referral.title')}</h3>
    <p>${t('visit-treatment.referral.sub')}:</p>
    <form-input .data=${this.info} key="initial_treatment.referral" title=${t('visit-treatment.referral.name')}></form-input>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
    ]
  }
}
