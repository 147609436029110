import { customElement, LitElement, html, css, property, PropertyValues, query } from 'lit-element'
import { connect } from '@captaincodeman/redux-connect-element'
import { translate as t, translateUnsafeHTML as th } from 'lit-translate'

import '@polymer/paper-fab';
import '@polymer/paper-dialog';
import '@polymer/paper-dialog-scrollable';
import '@polymer/paper-item/paper-item'
import '@polymer/paper-listbox/paper-listbox'
import '@polymer/paper-radio-group/paper-radio-group'
import { PaperDialogElement } from '@polymer/paper-dialog';
import { PaperInputElement } from '@polymer/paper-input/paper-input';
import { PaperRadioButtonElement } from '@polymer/paper-radio-button';

import { store, FormActions, RootState, FormSelectors } from '../../store'
import { Allergy, MedicalHistory } from '@healthspaces/hsuite-data/models';
import { baseStyle, cardStyle, fontStyle } from '../shared-styles';
import { delete_icon } from '../icons';


declare global {
  interface HTMLElementTagNameMap {
    'medical-allergies': MedicalAllergiesElement;
    'medical-allergy-list': MedicalAllergyListElement;
    'medical-allergy-card': MedicalAllergyCardElement;
    'medical-allergy-edit': MedicalAllergyEditElement;
  }
}

const emptyAllergy: Allergy = { name: '', reaction: '', severity: 0 }

@customElement('medical-allergies')
export class MedicalAllergiesElement extends connect(store, LitElement) {
  @property({ type: Object }) history: MedicalHistory

  mapState(state: RootState) {
    return {
      history: FormSelectors.medical_history(state),
    }
  }

  mapEvents() {
    return {
      'allergy-add': (e: CustomEvent<Allergy>) => FormActions.medicalHistoryArrayAdd('allergies', e.detail),
      'allergy-update': (e: CustomEvent<{ old: Allergy, new: Allergy }>) => FormActions.medicalHistoryArrayUpdate('allergies', e.detail.old, e.detail.new),
      'allergy-delete': (e: CustomEvent<Allergy>) => FormActions.medicalHistoryArrayDelete('allergies', e.detail),
    }
  }

  render() {
    return html`
      ${ this.history.allergies.length
        ? t('medical-allergies.instructions.filled')
        : th('medical-allergies.instructions.empty', { icon: '<iron-icon icon="add"></iron-icon>' })
      }
      <medical-allergy-list .allergies=${this.history.allergies}></medical-allergy-list>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
      css`
        svg {
          width: 1em;
          height: 1em;
        }
      `
    ]
  }
}

@customElement('medical-allergy-list')
export class MedicalAllergyListElement extends LitElement {
  @property({ type: Array }) allergies: Allergy[]

  @query('medical-allergy-edit')
  editElement: MedicalAllergyEditElement

  async add(e: Event) {
    try {
      const allergy = await this.editElement.open(emptyAllergy)
      const evt = new CustomEvent('allergy-add', {
        bubbles: true,
        composed: true,
        detail: allergy,
      })
      this.dispatchEvent(evt)
    } catch (err) {
      // cancelled
    }
  }

  async edit(e: Event) {
    const el = <MedicalAllergyCardElement>e.target
    const allergy = el.allergy
    try {
      const updated = await this.editElement.open(allergy)
      const evt = new CustomEvent('allergy-update', {
        bubbles: true,
        composed: true,
        detail: {
          old: allergy,
          new: updated,
        },
      })
      this.dispatchEvent(evt)
    } catch (err) {
      // cancelled
    }
  }

  delete(e: Event) {
    e.stopPropagation()
    const el = <HTMLButtonElement>e.target
    const allergy = el.closest('medical-allergy-card').allergy
    const evt = new CustomEvent('allergy-delete', {
      bubbles: true,
      composed: true,
      detail: allergy,
    })
    this.dispatchEvent(evt)
  }

  render() {
    const allergies = this.allergies.sort((a, b) => a.name.localeCompare(b.name, 'en'))

    return html`${allergies.map(allergy => html`
      <medical-allergy-card .allergy=${allergy} @click=${this.edit} @delete=${this.delete}></medical-allergy-card>`)}
      <paper-fab icon="add" @click=${this.add}></paper-fab>
      <medical-allergy-edit></medical-allergy-edit>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
    ]
  }
}

@customElement('medical-allergy-card')
export class MedicalAllergyCardElement extends LitElement {
  @property({ type: Object }) allergy: Allergy

  delete(e: Event) {
    e.stopPropagation()
    const evt = new CustomEvent('delete', {
      bubbles: true,
      composed: true,
    })
    this.dispatchEvent(evt)
  }

  render() {
    return html`
      <button @click=${this.delete}>${delete_icon}</button>
      <h2>${this.allergy.name}</h2>
      <p>${t('enums.allergy-severity.' + this.allergy.severity)} ${this.allergy.reaction}</p>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
      cardStyle,
      css`
        :host {
          padding: 8px;
          cursor: pointer;
          display: flex;
          align-items: center;
        }

        h2, p {
          margin: 8px 0;
        }
        h2 { width: 50%; }
        p { width: 40% }

        button {
          color: #999;
          background-color: transparent;
          border: none;
          outline: none;
          margin-right: 8px;
        }

        button svg {
          width: 2em;
          height: 2em;
        }
      `
    ]
  }
}

@customElement('medical-allergy-edit')
export class MedicalAllergyEditElement extends LitElement {
  @property({ type: Object }) allergy: Allergy = emptyAllergy

  private dialog: PaperDialogElement
  private resolve: (Allergy) => void
  private reject: () => void

  firstUpdated(changedProperties: PropertyValues) {
    // re-parent dialog to fix stacking
    this.dialog = this.shadowRoot.querySelector('paper-dialog')
    this.dialog = document.body.appendChild(this.dialog);
  }

  onNameChange(e: Event) {
    const el = <PaperInputElement>e.target
    this.allergy = { ...this.allergy, name: el.value }
  }

  onReactionChange(e: Event) {
    const el = <PaperInputElement>e.target
    this.allergy = { ...this.allergy, reaction: el.value }
  }

  onSeverityChange(e: Event) {
    const el = <PaperRadioButtonElement>e.target
    this.allergy = { ...this.allergy, severity: parseInt(el.name) }
  }

  open(allergy: Allergy): Promise<Allergy> {
    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
      this.allergy = allergy
      this.dialog.open()
    })
  }

  save() {
    this.resolve(this.allergy)
    this.dialog.close()
  }

  hide() {
    this.reject()
  }

  prevent(e: Event) {
    e.stopPropagation()
    e.preventDefault()
  }

  render() {
    return html`
      <paper-dialog style="width: 80vw" with-backdrop no-cancel-on-outside-click @iron-overlay-closed=${this.hide}>
        <h2 style="color: #666">${t('medical-allergies-edit.title')}</h2>
        <paper-dialog-scrollable>
          <paper-input type="text" always-float-label label=${t('medical-allergies-edit.name')} .value=${this.allergy.name} @change=${this.onNameChange}></paper-input>
          <paper-input type="text" always-float-label label=${t('medical-allergies-edit.reaction')} .value=${this.allergy.reaction} @change=${this.onReactionChange}></paper-input>
          <label>${t('medical-allergies-edit.severity')}:</label>
          <paper-radio-group .selected=${this.allergy.severity} @change=${this.onSeverityChange}>
            <paper-radio-button name="1">${t('enums.allergy-severity.1')}</paper-radio-button>
            <paper-radio-button name="3">${t('enums.allergy-severity.3')}</paper-radio-button>
            <paper-radio-button name="5">${t('enums.allergy-severity.5')}</paper-radio-button>
          </paper-radio-group>
        </paper-dialog-scrollable>
        <div class="buttons" style="display:flex; align-items: center; align-content: flex-end;">
          <paper-button dialog-dismiss>${t('dialog.cancel')}</paper-button>
          <paper-button dialog-confirm autofocus @click=${this.save}>${t('dialog.save')}</paper-button>
        </div>
      </paper-dialog>
    `
  }
}
