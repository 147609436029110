import { customElement, LitElement, html, property, PropertyValues } from 'lit-element'
import { connect } from '@captaincodeman/redux-connect-element'
import { translate as t, translateUnsafeHTML as th } from 'lit-translate'

import { store, RootState, FormSelectors, FormActions } from '../../store'
import { baseStyle, alternatingSections, fontStyle } from '../shared-styles';
import './medical-family-conditions'
import { MedicalHistory, FamilyHistory, Relation } from '@healthspaces/hsuite-data/models'

declare global {
  interface HTMLElementTagNameMap {
    'medical-family': MedicalFamilyElement;
  }
}

@customElement('medical-family')
export class MedicalFamilyElement extends connect(store, LitElement) {
  @property({ type: Object }) history: MedicalHistory
  @property({ type: Object }) father: FamilyHistory
  @property({ type: Object }) mother: FamilyHistory
  @property({ type: Object }) sibling: FamilyHistory
  @property({ type: Object }) child: FamilyHistory

  mapState(state: RootState) {
    return {
      history: FormSelectors.medical_history(state),
    }
  }

  updated(changedProperties: PropertyValues) {
    if (changedProperties.has('history')) {
      this.father = this.ensureFamilyHistory(Relation.Father)
      this.mother = this.ensureFamilyHistory(Relation.Mother)
      this.sibling = this.ensureFamilyHistory(Relation.Sibling)
      this.child = this.ensureFamilyHistory(Relation.Child)
    }
  }

  ensureFamilyHistory(relation: Relation) {
    return this.history.family.find(x => x.relation === relation) || {
      relation,
      status: null,
      anesthesia: false,
      rheumatoid: false,
      osteoarthritis: false,
      osteoperosis: false,
      asthma: false,
      bleeding: false,
      cancer: false,
      coronary: false,
      dvt: false,
      diebetes: false,
      hemophilia: false,
      hypertension: false,
      kidney: false,
      pulmonary: false,
      seizures: false,
      stroke: false,
      other: '',
      age: null,
    }
  }

  onFamilyHistoryUpdated(e: CustomEvent<FamilyHistory>) {
    const data = e.detail
    const existing = this.history.family.find(x => x.relation === data.relation)
    const action = existing
      ? FormActions.medicalHistoryArrayUpdate('family', existing, e.detail)
      : FormActions.medicalHistoryArrayAdd('family', e.detail)
    store.dispatch(action)
  }

  render() {
    return html`
    <p>${th('medical-family.title')}:</p>

    <section>
      <h3>${t('medical-family.father')}</h3>
      <medical-family-conditions
        .data=${this.father}
        @medical-family-history-updated=${this.onFamilyHistoryUpdated}>
      </medical-family-conditions>
    </section>

    <section>
      <h3>${t('medical-family.mother')}</h3>
      <medical-family-conditions
        .data=${this.mother}
        @medical-family-history-updated=${this.onFamilyHistoryUpdated}>
      </medical-family-conditions>
    </section>

    <section>
      <h3>${t('medical-family.siblings')}</h3>
      <medical-family-conditions
        .data=${this.sibling}
        @medical-family-history-updated=${this.onFamilyHistoryUpdated}>
      </medical-family-conditions>
    </section>

    <section>
      <h3>${t('medical-family.children')}</h3>
      <medical-family-conditions
        .data=${this.child}
        @medical-family-history-updated=${this.onFamilyHistoryUpdated}>
      </medical-family-conditions>
    </section>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
      alternatingSections,
    ]
  }
}
