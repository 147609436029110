import { createSelector } from 'reselect'
import { RootState } from '..';

const getState = (state: RootState) => state.auth

export namespace AuthSelectors {
  export const user = createSelector(
    [getState],
    (state) => state.user
  )

  export const access = createSelector(
    [getState],
    (state) => state.access
  )

  export const accessKey = createSelector(
    [access],
    (access) => access
      ? `${access.practice}/${access.clinic}`
      : null
  )

  export const statusKnown = createSelector(
    [getState],
    (state) => state.statusKnown
  )

  export const anonymous = createSelector(
    [getState],
    (state) => state.user === null
  )

  export const authenticated = createSelector(
    [getState],
    (state) => state.user !== null
  )

  export const context = createSelector(
    [access],
    (access) => ({ ...access, rootPath: '/' })
  )

  export const isHealthspaces = createSelector(
    [access],
    (access) => access.practice === 'healthspaces'
  )

  export const locked = createSelector(
    [getState],
    (state) => state.locked
  )
}
