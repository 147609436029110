import { customElement, LitElement, html, css, property, PropertyValues, query } from 'lit-element'
import { connect } from '@captaincodeman/redux-connect-element'
import { translate as t, translateUnsafeHTML as th } from 'lit-translate'

import '@polymer/paper-fab';
import '@polymer/paper-dialog';
import '@polymer/paper-dialog-scrollable';
import '@polymer/paper-radio-group/paper-radio-group'
import { PaperDialogElement } from '@polymer/paper-dialog';
import { PaperInputElement } from '@polymer/paper-input/paper-input';
import { PaperRadioButtonElement } from '@polymer/paper-radio-button';

import { store, FormActions, RootState, FormSelectors } from '../../store'
import { baseStyle, cardStyle, fontStyle } from '../shared-styles';
import { delete_icon } from '../icons';
import { Medication, MedicationType, MedicalHistory } from '@healthspaces/hsuite-data/models'


declare global {
  interface HTMLElementTagNameMap {
    'medical-medications': MedicalMedicationsElement;
    'medical-medication-list': MedicalMedicationListElement;
    'medical-medication-card': MedicalMedicationCardElement;
    'medical-medication-edit': MedicalMedicationEditElement;
  }
}

const emptyMedication: Medication = {
  type: MedicationType.Unknown,
  name: '',
  dose: '',
  prescriber: '',
}

@customElement('medical-medications')
export class MedicalMedicationsElement extends connect(store, LitElement) {
  @property({ type: Object }) history: MedicalHistory

  mapState(state: RootState) {
    return {
      history: FormSelectors.medical_history(state),
    }
  }

  mapEvents() {
    return {
      'medication-add': (e: CustomEvent<Medication>) => FormActions.medicalHistoryArrayAdd('medications', e.detail),
      'medication-update': (e: CustomEvent<{ old: Medication, new: Medication }>) => FormActions.medicalHistoryArrayUpdate('medications', e.detail.old, e.detail.new),
      'medication-delete': (e: CustomEvent<Medication>) => FormActions.medicalHistoryArrayDelete('medications', e.detail),
    }
  }

  render() {
    return html`
      ${ this.history.medications.length
        ? t('medical-medications.instructions.filled')
        : th('medical-medications.instructions.empty', { icon: '<iron-icon icon="add"></iron-icon>' })
      }
      <medical-medication-list .medications=${this.history.medications}></medical-medication-list>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
      css`
        svg {
          width: 1em;
          height: 1em;
        }
      `
    ]
  }
}

@customElement('medical-medication-list')
export class MedicalMedicationListElement extends LitElement {
  @property({ type: Array }) medications: Medication[]

  @query('medical-medication-edit')
  editElement: MedicalMedicationEditElement

  async add(e: Event) {
    try {
      const medication = await this.editElement.open(emptyMedication)
      const evt = new CustomEvent('medication-add', {
        bubbles: true,
        composed: true,
        detail: medication,
      })
      this.dispatchEvent(evt)
    } catch (err) {
      // cancelled
    }
  }

  async edit(e: Event) {
    const el = <MedicalMedicationCardElement>e.target
    const medication = el.medication
    try {
      const updated = await this.editElement.open(medication)
      const evt = new CustomEvent('medication-update', {
        bubbles: true,
        composed: true,
        detail: {
          old: medication,
          new: updated,
        },
      })
      this.dispatchEvent(evt)
    } catch (err) {
      // cancelled
    }
  }

  delete(e: Event) {
    e.stopPropagation()
    const el = <HTMLButtonElement>e.target
    const medication = el.closest('medical-medication-card').medication
    const evt = new CustomEvent('medication-delete', {
      bubbles: true,
      composed: true,
      detail: medication,
    })
    this.dispatchEvent(evt)
  }

  render() {
    const medications = this.medications.sort((a, b) => a.name.localeCompare(b.name, 'en'))

    return html`${medications.map(medication => html`
      <medical-medication-card .medication=${medication} @click=${this.edit} @delete=${this.delete}></medical-medication-card>`)}
      <paper-fab icon="add" @click=${this.add}></paper-fab>
      <medical-medication-edit></medical-medication-edit>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
    ]
  }
}

@customElement('medical-medication-card')
export class MedicalMedicationCardElement extends LitElement {
  @property({ type: Object }) medication: Medication

  delete(e: Event) {
    e.stopPropagation()
    const evt = new CustomEvent('delete', {
      bubbles: true,
      composed: true,
    })
    this.dispatchEvent(evt)
  }

  render() {
    return html`
      <button @click=${this.delete}>${delete_icon}</button>
      <h2>${this.medication.name}</h2>
      <p>
        (${t('enums.medication-type.' + this.medication.type)})<br>
        ${this.medication.dose}<br>
        ${this.medication.prescriber}
      </p>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
      cardStyle,
      css`
        :host {
          padding: 8px;
          cursor: pointer;
          display: flex;
          align-items: center;
        }

        h2, p {
          margin: 8px 0;
        }
        h2 { width: 50%; }
        p { width: 40% }

        button {
          color: #999;
          background-color: transparent;
          border: none;
          outline: none;
          margin-right: 8px;
        }

        button svg {
          width: 2em;
          height: 2em;
        }
      `
    ]
  }
}

@customElement('medical-medication-edit')
export class MedicalMedicationEditElement extends LitElement {
  @property({ type: Object }) medication: Medication = emptyMedication

  private dialog: PaperDialogElement
  private resolve: (Medication) => void
  private reject: () => void

  firstUpdated(changedProperties: PropertyValues) {
    // re-parent dialog to fix stacking
    this.dialog = this.shadowRoot.querySelector('paper-dialog')
    this.dialog = document.body.appendChild(this.dialog);
  }

  onTypeChange(e: Event) {
    const el = <PaperRadioButtonElement>e.target
    this.medication = { ...this.medication, type: <MedicationType>el.name }
  }

  onNameChange(e: Event) {
    const el = <PaperInputElement>e.target
    this.medication = { ...this.medication, name: el.value }
  }

  onDoseChange(e: Event) {
    const el = <PaperInputElement>e.target
    this.medication = { ...this.medication, dose: el.value }
  }

  onPrescriberChange(e: Event) {
    const el = <PaperInputElement>e.target
    this.medication = { ...this.medication, prescriber: el.value }
  }

  open(medication: Medication): Promise<Medication> {
    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
      this.medication = medication
      this.dialog.open()
    })
  }

  save() {
    this.resolve(this.medication)
    this.dialog.close()
  }

  hide() {
    this.reject()
  }

  render() {
    return html`
      <paper-dialog style="width: 80vw" with-backdrop no-cancel-on-outside-click @iron-overlay-closed=${this.hide}>
        <h2 style="color: #666">${t('medical-medication-edit.title')}</h2>
        <paper-dialog-scrollable>
          <label>${t('medical-medication-edit.type')}:</label>
          <paper-radio-group .selected=${this.medication.type} @change=${this.onTypeChange}>
            <paper-radio-button name="medication">${t('enums.medication-type.medication')}</paper-radio-button>
            <paper-radio-button name="supplement">${t('enums.medication-type.supplement')}</paper-radio-button>
          </paper-radio-group>
          <paper-input type="text" always-float-label label=${t('medical-medication-edit.name')} .value=${this.medication.name} @change=${this.onNameChange}></paper-input>
          <paper-input type="text" always-float-label label=${t('medical-medication-edit.dose')} .value=${this.medication.dose} @change=${this.onDoseChange}></paper-input>
          <paper-input type="text" always-float-label label=${t('medical-medication-edit.from')} .value=${this.medication.prescriber} @change=${this.onPrescriberChange}></paper-input>

        </paper-dialog-scrollable>
        <div class="buttons" style="display:flex; align-items: center; align-content: flex-end;">
          <paper-button dialog-dismiss>${t('dialog.cancel')}</paper-button>
          <paper-button dialog-confirm autofocus @click=${this.save}>${t('dialog.save')}</paper-button>
        </div>
      </paper-dialog>
    `
  }
}
