import { Store } from 'redux';
import * as ReselectTools from 'reselect-tools'

import { AuthSelectors } from './auth/selectors';
import { ClinicSelectors } from './clinics/selectors';
import { FormSelectors } from './forms/selectors';
import { PracticeSelectors } from './practices/selectors';
import { RoutingSelectors } from './routing/selectors';
import { SigninSelectors } from './signins/selectors';
import { VisitSelectors } from './visits/selectors';

export const startReselect = (store: Store) => {
  ReselectTools.getStateWith(() => store.getState())
  ReselectTools.registerSelectors(AuthSelectors)
  ReselectTools.registerSelectors(ClinicSelectors)
  ReselectTools.registerSelectors(FormSelectors)
  ReselectTools.registerSelectors(PracticeSelectors)
  ReselectTools.registerSelectors(RoutingSelectors)
  ReselectTools.registerSelectors(SigninSelectors)
  ReselectTools.registerSelectors(VisitSelectors)
}
