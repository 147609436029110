import { ActionsUnion, createAction } from '../actions'
import { User, Access } from './models'

export enum AuthTypes {
  SIGNIN_EMAIL = 'SIGNIN_EMAIL',
  SIGNIN_EMAIL_PASSWORD = 'SIGNIN_EMAIL_PASSWORD',
  SIGNIN_PROVIDER = 'SIGNIN_PROVIDER',
  SIGNOUT = 'SIGNOUT',

  SIGNED_IN = 'SIGNED_IN',
  SIGNED_OUT = 'SIGNED_OUT',

  CODE_INPUT = 'CODE_INPUT',
  LOCKED = 'LOCKED',
}

export const AuthActions = {
  signInWithEmail: (email: string) => createAction(AuthTypes.SIGNIN_EMAIL, { email }),
  signInWithEmailPassword: (email: string, password: string) => createAction(AuthTypes.SIGNIN_EMAIL_PASSWORD, { email, password }),
  signInWithProvider: (provider: string) => createAction(AuthTypes.SIGNIN_PROVIDER, { provider }),
  signOut: () => createAction(AuthTypes.SIGNOUT),

  signedIn: (user: User, access: Access) => createAction(AuthTypes.SIGNED_IN, { user, access }),
  signedOut: () => createAction(AuthTypes.SIGNED_OUT),

  codeInput: (code: string) => createAction(AuthTypes.CODE_INPUT, { code }),
  locked: (locked: boolean) => createAction(AuthTypes.LOCKED, { locked }),
}

export type AuthActions = ActionsUnion<typeof AuthActions>
