import { customElement, LitElement, html, css, property } from 'lit-element'

import { Visit } from '@healthspaces/hsuite-elements/models';
import '@healthspaces/hsuite-elements/identicon-img';

import { cardStyle, baseStyle } from './shared-styles';
import { dobFormatter } from '../service/dobFormatter';

declare global {
  interface HTMLElementTagNameMap {
    'visit-card': VisitCardElement;
  }
}

@customElement('visit-card')
export class VisitCardElement extends LitElement {
  @property({ type: Object }) visit: Visit

  constructor() {
    super()
    this.addEventListener('click', this.onClick.bind(this))
  }

  onClick(e: Event) {
    const patient = this.visit.appointment.patient
    const message = `Activate ${patient.firstName} ${patient.lastName} (${dobFormatter.format(patient.dob)})`

    const select = window.confirm(message)
    if (select) {
      this.dispatchEvent(new CustomEvent('visit-select', {
        bubbles: true,
        composed: true,
        detail: this.visit,
      }))
    }
  }

  render() {
    const patient = this.visit.appointment.patient
    const key = `${patient.id} ${patient.firstName} ${patient.lastName}`

    return html`
    <identicon-img .key=${key}></identicon-img>
    <h2>${patient.firstName} ${patient.lastName}</h2>
    `
  }

  static get styles() {
    return [
      baseStyle,
      cardStyle,
      css`
      :host {
        margin: 16px 0;
        padding: 16px;
        cursor: pointer;
      }

      :host([selected]) {
        background-color: #fefeee;
      }

      h2 {
        font-weight: 100;
        margin: 0;
      }

      identicon-img {
        width: 36px;
        height: 36px;
        margin-right: 8px;
        float: left;
      }
      `
    ]
  }
}
