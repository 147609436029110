import { customElement, LitElement, html, property } from 'lit-element'
import { connect } from '@captaincodeman/redux-connect-element'
import { translate as t, translateUnsafeHTML as th } from 'lit-translate'
import './form-checkbox'
import './form-input'

import { store, FormActions, RootState, FormSelectors } from '../../store'
import { baseStyle, fontStyle } from '../shared-styles';
import { MedicalHistory } from '@healthspaces/hsuite-data/models'

declare global {
  interface HTMLElementTagNameMap {
    'medical-conditions': MedicalConditionElement;
  }
}

@customElement('medical-conditions')
export class MedicalConditionElement extends connect(store, LitElement) {
  @property({ type: Object }) history: MedicalHistory

  mapState(state: RootState) {
    return {
      history: FormSelectors.medical_history(state),
    }
  }

  mapEvents() {
    return {
      'form-checkbox-changed': (e: CustomEvent) => FormActions.medicalHistoryUpdate(e.detail),
      'form-input-changed': (e: CustomEvent) => FormActions.medicalHistoryUpdate(e.detail),
    }
  }

  render() {
    return html`
    <p>${th('medical-conditions.title')}:</p>

    <section class="multi-column">
      <form-checkbox .data=${this.history} key="conditions.osteoporosis" title=${t('medical-conditions.osteoporosis')}></form-checkbox>
      <form-checkbox .data=${this.history} key="conditions.cancer" title=${t('medical-conditions.cancer')}></form-checkbox>
      <form-checkbox .data=${this.history} key="conditions.high_blood_pressure" title=${t('medical-conditions.high_blood_pressure')}></form-checkbox>
      <form-checkbox .data=${this.history} key="conditions.heart_disease" title=${t('medical-conditions.heart_disease')}></form-checkbox>
      <form-checkbox .data=${this.history} key="conditions.diabetes" title=${t('medical-conditions.diabetes')}></form-checkbox>
      <form-checkbox .data=${this.history} key="conditions.paralysis" title=${t('medical-conditions.paralysis')}></form-checkbox>
      <form-checkbox .data=${this.history} key="conditions.arthritis" title=${t('medical-conditions.arthritis')}></form-checkbox>
      <form-checkbox .data=${this.history} key="conditions.ulcers" title=${t('medical-conditions.ulcers')}></form-checkbox>
      <form-checkbox .data=${this.history} key="conditions.poor_circulation" title=${t('medical-conditions.poor_circulation')}></form-checkbox>
      <form-checkbox .data=${this.history} key="conditions.asthma" title=${t('medical-conditions.asthma')}></form-checkbox>
    </section>

    <h3>${th('medical-conditions.anesthesia.title')}</h3>
    <form-checkbox .data=${this.history} key="conditions.anesthesia" title=${t('medical-conditions.anesthesia.label')}></form-checkbox>

    <form-input .data=${this.history} key="conditions.anesthesiaIssue" title=${t('medical-conditions.anesthesia.issue')}></form-input>

    <p>${th('medical-conditions.other.title')}:</p>
    <form-input .data=${this.history} key="conditions.other" title=${t('medical-conditions.other.label')}></form-input>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
    ]
  }
}
