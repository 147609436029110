import { css } from "lit-element"

export const baseStyle = css`
  :host {
    display: block;
    box-sizing: border-box;
  }

  :host([hidden]), [hidden] {
    display: none !important;
  }

  section {
    display: grid;
    grid-column-gap: 8px;
    grid-row-gap: 12px;
    padding: 8px;
  }

  .multi-column {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  paper-fab {
    position: absolute;
    bottom: 128px;
    right: 56px;
    background-color: #2AD3B3;
    width: 64px;
    height: 64px;
    --iron-icon-width: 48px;
    --iron-icon-height: 48px;
  }
`

export const fontStyle = css`
  h2, h3 {
    font-weight: normal;
    color: #565;
    margin: 16px 0 8px 0;
  }

  h3 {
    margin-top: 20px;
  }

  p {
    color: #333;
  }
`

export const cardStyle = css`
  :host {
    color: #666;
    background-color: #f9f9f9;
    border: 2px solid #fff;
    box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.3);
  }
`

export const alternatingSections = css`
  :host > section:nth-child(even) {
    background-color: #f7f7f7;
  }
`
