import createSagaMiddleware from 'redux-saga'

import auth from './auth/sagas';
import clinics from './clinics/sagas';
import forms from './forms/sagas';
import practices from './practices/sagas';
import routing from './routing/sagas';
import signins from './signins/sagas';
import visits from './visits/sagas';

import { firebaseApp } from './firebase-loader';
import { all, spawn, call } from 'redux-saga/effects';

export const rootSagas = {
  auth,
  clinics,
  forms,
  practices,
  routing,
  signins,
  visits,
}

export const sagaMiddleware = createSagaMiddleware()

export async function startSagas() {
  await firebaseApp

  for (let name in rootSagas) {
    console.log(`starting ${name} sagas`)
    const sagas = rootSagas[name]

    function* saga() {
      yield all(sagas.map(saga => spawn(function* () {
        while (true) {
          try {
            yield call(saga)
            break
          } catch (err) {
            console.error(err)
          }
        }
      })))
    }

    sagaMiddleware.run(saga)
  }
}
