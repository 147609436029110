import { customElement, LitElement, html, css, query } from 'lit-element'
import { connect } from '@captaincodeman/redux-connect-element'
import { translate as t, translateUnsafeHTML as th } from 'lit-translate'

import '@polymer/paper-button';
import '@polymer/paper-dialog';
import '@polymer/paper-dialog-scrollable';
import { PaperDialogElement } from '@polymer/paper-dialog';
import '@healthspaces/hsuite-elements/code-input';

import { store, AuthSelectors, RootState } from '../store'
import { CodeInputElement } from '@healthspaces/hsuite-elements/code-input';

declare global {
  interface HTMLElementTagNameMap {
    'unlock-dialog': UnlockDialogElement;
  }
}

@customElement('unlock-dialog')
export class UnlockDialogElement extends connect(store, LitElement) {
  set locked(val: boolean) {
    if (!val && this.dialog) {
      this.dialog.close()
      this.reset()
    }
  }

  mapState(state: RootState) {
    return {
      locked: AuthSelectors.locked(state),
    }
  }

  @query('paper-dialog')
  dialog: PaperDialogElement

  @query('code-input')
  codeInput: CodeInputElement

  open() {
    this.dialog.open()
    this.reset()
  }

  reset() {
    this.codeInput.value = ''
    // TODO: this should happen in the code-input element:
    this.codeInput.input.value = ''
    this.codeInput.input.focus()
  }

  render() {
    return html`
    <paper-dialog id="dialog" with-backdrop>
      <h2>${t('unlock-dialog.title')}</h2>
      <paper-dialog-scrollable>
        <p>${th('unlock-dialog.instructions')}</p>
        <code-input></code-input>
      </paper-dialog-scrollable>
      <div class="buttons">
        <paper-button dialog-dismiss>${t('dialog.cancel')}</paper-button>
      </div>
    </paper-dialog>
    `
  }

  static get styles() {
    return [
      css`
        paper-dialog {
          max-width: 480px;
          border-radius: 8px;
        }
      `
    ]
  }
}
