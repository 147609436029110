import { customElement, LitElement, html, property, css, internalProperty } from 'lit-element'
import { nothing } from 'lit-html'
import { translate as t, translateUnsafeHTML as th } from 'lit-translate'
import { camera } from '../icons'

declare global {
  interface HTMLElementTagNameMap {
    'form-image': FormImageElement;
  }
}

export interface FormImageEvent {
  type: string
  blob: Blob
}

@customElement('form-image')
export class FormImageElement extends LitElement {
  @property({ type: Object }) value: { front?: string, back?: string }
  @property({ type: String }) label: string
  @property({ type: String }) key: string
  @property({ type: Number }) progress: number = -1

  @internalProperty() type: string

  async onChange(e: Event) {
    const el = e.target as HTMLInputElement
    this.type = el.id
    const file = el.files[0]
    if (file) {
      const blob = await this.constrain(file)
      const evt = new CustomEvent<FormImageEvent>('form-image-changed', {
        bubbles: true,
        composed: true,
        detail: {
          type: this.key + '.' + this.type,
          blob,
        },
      })
      this.dispatchEvent(evt)
    }
  }

  // constrain image size in megapixels
  constrain(file: File): Promise<Blob> {
    return new Promise<Blob>(resolve => {
      const url = URL.createObjectURL(file)
      const img = new Image()

      img.onload = () => {
        URL.revokeObjectURL(url)

        // check megapixel size of image
        const MEGAPIXEL_LIMIT = 6000 * 4000 // 24 megapixel
        const size = img.width * img.height

        if (size <= MEGAPIXEL_LIMIT) {
          // already under megapixel limit, we'll allow it ...
          resolve(file)
        }

        // reduce image to the maximum megapixel area
        // note we're just using the canvas resize ability
        // and nothing fancy such as lanczos resize, as the
        // sizes are pretty large and we shouldn't be losing
        // much real detail
        const sizeRatio = Math.sqrt(MEGAPIXEL_LIMIT / size)
        const width = Math.round(img.width * sizeRatio)
        const height = Math.round(img.height * sizeRatio)
        const canvas = document.createElement('canvas')
        canvas.width = width
        canvas.height = height
        const ctx = canvas.getContext('2d')

        // draw image at new size
        ctx.drawImage(img, 0, 0, width, height)

        // re-encode as jpeg
        canvas.toBlob(resolve, 'image/jpeg', 0.85)
      }

      img.src = url
    })
  }

  render() {
    return html`
      <p>${th('image.upload', { type: this.label })}</p>
      <h3>${t('image.front')}</h3>

      <label>
        <span>${camera} ${t('image.capture')} (${t('image.front')})</span>
        <input id="front" type="file" accept="image/*" capture="environment" @change=${this.onChange}>
      </label>
      <progress ?hidden=${this.progress < 0 || this.type !== 'front'} .value=${this.progress}>${Math.round(this.progress * 100)}%</progress>
      ${ this.value && this.value.front
        ? html`<img src=${this.value.front} alt="" />`
        : nothing
      }


      <h3>${t('image.back')}</h3>
      <label>
        <span>${camera} ${t('image.capture')} (${t('image.back')})</span>
        <input id="back" type="file" accept="image/*" capture="environment" @change=${this.onChange}>
      </label>
      <progress ?hidden=${this.progress < 0 || this.type !== 'back'} .value=${this.progress}>${Math.round(this.progress * 100)}%</progress>
      ${ this.value && this.value.back
        ? html`<img src=${this.value.back} alt="" />`
        : nothing
      }
    `
  }

  static get styles() {
    return css`
      svg {
        width: 1em;
        height: 1em;
        color: #fff;
        margin: 0 6px;
      }

      img {
        width: 100%;
        display: block;
      }

      input[type="file"] {
        display: none;
      }

      label {
        display: block;
        border-radius: 8px;
        padding: 12px;
        cursor: pointer;
        margin: 12px 0;
        color: var(--button-color);
        background-color: var(--button-background-color);
      }

      progress {
        width: 100%;
      }
    `
  }
}
