import { cancel, take, fork, select } from 'redux-saga/effects';
import { AuthTypes, AuthSelectors } from './auth';

export function* takeAuth(saga) {
  let task

  while (true) {
    const user = yield select(AuthSelectors.user)
    const ctx = yield select(AuthSelectors.context)

    if (user) {
      task = yield fork(saga, ctx)
    }

    yield take([
      AuthTypes.SIGNED_IN,
      AuthTypes.SIGNED_OUT,
    ])

    if (task) {
      yield cancel(task)
    }
  }
}
