import { eventChannel, buffers } from 'redux-saga';
import { call, put, take, takeLatest, select } from 'redux-saga/effects';
import { Clinic } from '@healthspaces/hsuite-data/models/clinic';
import { ClinicSelectors } from '../clinics/selectors';
import { RoutingActions } from '../routing';
import { AuthActions, AuthTypes } from './actions';
import { AuthSelectors } from './selectors';

export default [
  syncUserSaga,
  signInWithEmailPasswordListener,
  signInWithEmailListener,
  signInWithProviderListener,
  signOutListener,
  codeInputListener,
]

function authChannel() {
  const auth = window.firebase.auth()
  return eventChannel(emit => auth.onAuthStateChanged(
    user => emit({ user }),
    error => emit({ error })
  ),
    buffers.fixed(1)
  )
}

function* syncUserSaga() {
  const channel = yield call(authChannel);

  while (true) {
    const { user, error } = yield take(channel);

    if (user) {
      const token = yield user.getIdTokenResult()
      const { clinic, practice } = token.claims
      const access = { clinic, practice }
      yield put(AuthActions.signedIn(user, access))
      yield put(RoutingActions.push('/'))
    } else {
      // TODO: feedback auth error to state / UI
      yield put(AuthActions.signedOut())
    }
  }
}

function* signInWithEmailPasswordListener() {
  yield takeLatest(AuthTypes.SIGNIN_EMAIL_PASSWORD, signInWithEmailPasswordSaga)
}

function* signInWithEmailPasswordSaga(action: ReturnType<typeof AuthActions.signInWithEmailPassword>) {
  const { email, password } = action.payload
  const auth = window.firebase.auth()
  try {
    yield call([auth, auth.signInWithEmailAndPassword], email, password)
  } catch (err) {
    console.error(err.code)
    switch (err.code) {
      case 'auth/invalid-email':
      // Thrown if the email address is not valid.
      case 'auth/user-disabled':
      // Thrown if the user corresponding to the given email has been disabled.
      case 'auth/user-not-found':
      // Thrown if there is no user corresponding to the given email.
      case 'auth/wrong-password':
      // Thrown if the password is invalid for the given email, or the account corresponding to the email does not have a password set.
    }
  }
}

function* signInWithEmailListener() {
  // passwordless signin
  const auth = window.firebase.auth()
  if (auth.isSignInWithEmailLink(window.location.href)) {
    let email = window.localStorage.getItem('signin-email');
    if (!email) {
      email = window.prompt('Please provide your email for confirmation');
    }

    auth.signInWithEmailLink(email, window.location.href)
      .then(result => window.localStorage.removeItem('signin-email'))
      .catch(error => {
        console.error(error)
      })
  }

  yield takeLatest(AuthTypes.SIGNIN_EMAIL, signInWithEmailSaga);
}


function* signInWithEmailSaga(action: ReturnType<typeof AuthActions.signInWithEmail>) {
  const actionCodeSettings = { url: location.href, handleCodeInApp: true };
  const email = action.payload.email
  const auth = window.firebase.auth()

  try {
    yield call([auth, auth.sendSignInLinkToEmail], email, actionCodeSettings)
  } catch (err) {
    console.error(err.code)
    switch (err.code) {
      case 'auth/argument-error':
      // Thrown if handleCodeInApp is false.
      case 'auth/invalid-email':
      // Thrown if the email address is not valid.
      case 'auth/missing-android-pkg-name':
      // An Android package name must be provided if the Android app is required to be installed.
      case 'auth/missing-continue-uri':
      // A continue URL must be provided in the request.
      case 'auth/missing-ios-bundle-id':
      // An iOS Bundle ID must be provided if an App Store ID is provided.
      case 'auth/invalid-continue-uri':
      // The continue URL provided in the request is invalid.
      case 'auth/unauthorized-continue-uri':
      // The domain of the continue URL is not whitelisted. Whitelist the domain in the Firebase console.
    }
  }

  localStorage.setItem('signin-email', email)
}

function* signInWithProviderListener() {
  yield takeLatest(AuthTypes.SIGNIN_PROVIDER, signInWithProviderSaga)
}

function* signInWithProviderSaga(action: ReturnType<typeof AuthActions.signInWithProvider>) {
  const provider = providerFromName(action.payload.provider)
  const auth = window.firebase.auth()

  try {
    yield call([auth, auth.signInWithPopup], provider)
  } catch (err) {
    console.error(err.code)
    switch (err.code) {
      case 'auth/account-exists-with-different-credential':
      // Thrown if there already exists an account with the email address asserted by the credential. Resolve this by calling firebase.auth.Auth#fetchProvidersForEmail with the error.email and then asking the user to sign in using one of the returned providers. Once the user is signed in, the original credential retrieved from the error.credential can be linked to the user with firebase.User#linkWithCredential to prevent the user from signing in again to the original provider via popup or redirect. If you are using redirects for sign in, save the credential in session storage and then retrieve on redirect and repopulate the credential using for example firebase.auth.GoogleAuthProvider#credential depending on the credential provider id and complete the link.
      case 'auth/auth-domain-config-required':
      // Thrown if authDomain configuration is not provided when calling firebase.initializeApp(). Check Firebase Console for instructions on determining and passing that field.
      case 'auth/cancelled-popup-request':
      // Thrown if successive popup operations are triggered. Only one popup request is allowed at one time. All the popups would fail with this error except for the last one.
      case 'auth/operation-not-allowed':
      // Thrown if the type of account corresponding to the credential is not enabled. Enable the account type in the Firebase Console, under the Auth tab.
      case 'auth/operation-not-supported-in-this-environment':
      // Thrown if this operation is not supported in the environment your application is running on. "location.protocol" must be http or https.
      case 'auth/popup-blocked':
      // Thrown if the popup was blocked by the browser, typically when this operation is triggered outside of a click handler.
      case 'auth/popup-closed-by-user':
      // Thrown if the popup window is closed by the user without completing the sign in to the provider.
      case 'auth/unauthorized-domain':
      // Thrown if the app domain is not authorized for OAuth operations for your Firebase project. Edit the list of authorized domains from the Firebase console.
    }
  }
}

function providerFromName(name: string) {
  switch (name) {
    case 'facebook': return new window.firebase.auth.FacebookAuthProvider();
    case 'google': return new window.firebase.auth.GoogleAuthProvider();
    case 'twitter': return new window.firebase.auth.TwitterAuthProvider();
  }
}

function* signOutListener() {
  yield takeLatest(AuthTypes.SIGNOUT, signOutSaga)
}

function* signOutSaga(action: ReturnType<typeof AuthActions.signOut>) {
  const auth = window.firebase.auth()
  yield call([auth, auth.signOut])
  yield take(AuthTypes.SIGNED_OUT)
  yield put(RoutingActions.push('/'))
}

function* codeInputListener() {
  yield takeLatest(AuthTypes.CODE_INPUT, codeInputSaga)
}

function* codeInputSaga(action: ReturnType<typeof AuthActions.codeInput>) {
  const { code } = action.payload
  const clinic: Clinic = yield select(ClinicSelectors.current)
  const unlock = clinic.settings.phrUnlock
  if (code === unlock) {
    yield put(AuthActions.locked(false))
  } else {
    const locked = yield select(AuthSelectors.locked)
    if (!locked) {
      yield put(AuthActions.locked(true))
    }
  }
}
