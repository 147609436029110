import { customElement, LitElement, html, property } from 'lit-element'
import { connect } from '@captaincodeman/redux-connect-element'
import { translate as t, translateUnsafeHTML as th } from 'lit-translate'
import './form-checkbox'

import { store, FormActions, RootState, FormSelectors } from '../../store'
import { VisitInfo } from '@healthspaces/hsuite-data/models';
import { baseStyle, fontStyle } from '../shared-styles';

declare global {
  interface HTMLElementTagNameMap {
    'visit-mri': VisitMriElement;
  }
}

const questions = [
  'heart_implants',
  'aneurysm_clips',
  'contrast_agent',
  'surgical_implants',
  'metal_fragments',
  'ear_implants',
  'electrical_implants',
  'tattoos_piercing',
  'transdermal_patch',
  'claustrophobia',
  'disease_history',
  'device_history',
]

@customElement('visit-mri')
export class VisitMriElement extends connect(store, LitElement) {
  @property({ type: Object }) info: VisitInfo

  mapState(state: RootState) {
    return {
      info: FormSelectors.visit_info(state),
    }
  }

  mapEvents() {
    return {
      'form-checkbox-changed': (e: CustomEvent) => FormActions.visitUpdate(e.detail),
    }
  }

  render() {
    return html`
      <p>${th('visit-mri.mri_effects,instructions')}:</p>
      <section>${questions.map(q => html`
        <form-checkbox .data=${this.info} key=${'mri_effects.' + q}) title=${t('visit-mri.' + q)}></form-checkbox>`)}
      </section>

      <h2>${th('visit-mri.pregnancy_nursing.title')}</h2>
      <p>${th('visit-mri.pregnancy_nursing.instructions')}:</p>
      <section>
        <form-checkbox .data=${this.info} key="pregnancy_nursing.pregnant" title=${t('visit-mri.pregnancy_nursing.pregnant')}></form-checkbox>
        <form-checkbox .data=${this.info} key="pregnancy_nursing.breastfeeding" title=${t('visit-mri.pregnancy_nursing.breastfeeding')}></form-checkbox>
      </section>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
    ]
  }
}
