import { customElement, LitElement, html, property } from 'lit-element'
import { translate as t, translateUnsafeHTML as th } from 'lit-translate'
import './form-radio-group'

import { HabitItem } from '@healthspaces/hsuite-data/models'
import { fontStyle } from '../shared-styles';

declare global {
  interface HTMLElementTagNameMap {
    'medical-social-alcohol': MedicalSocialAlcoholElement;
  }
}

@customElement('medical-social-alcohol')
export class MedicalSocialAlcoholElement extends LitElement {
  @property({ type: Object }) alcohol: HabitItem

  onDrinkAmountChanged(e: CustomEvent) {
    this.alcohol.frequency = parseInt(e.detail.frequency)
    this.notify(e)
  }

  notify(e: CustomEvent) {
    e.stopPropagation()
    const evt = new CustomEvent('medical-social-alcohol-updated', {
      bubbles: true,
      composed: true,
      detail: this.alcohol,
    })
    this.dispatchEvent(evt)
  }

  render() {
    return html`
    <h3>${th('medical-social-alcohol.title')}</h3>
    <form-radio-group .data=${this.alcohol} key="frequency" @form-radio-group-changed=${this.onDrinkAmountChanged}>
      <paper-radio-button name="0">${t('medical-social-alcohol.none')}</paper-radio-button>
      <paper-radio-button name="2">1 - 2</paper-radio-button>
      <paper-radio-button name="5">3 - 5</paper-radio-button>
      <paper-radio-button name="9">6 - 9</paper-radio-button>
      <paper-radio-button name="10">10+</paper-radio-button>
    </form-radio-group>
    `
  }

  static get styles() {
    return [
      fontStyle,
    ]
  }
}
