import { Visit, FormsStatus, PatientSummary, Patient, BlankPatientInfo, MedicalHistory, BlankMedicalHistory } from '@healthspaces/hsuite-data/models';
import { IStore } from '@healthspaces/hsuite-data/store';
import { buffers, eventChannel } from 'redux-saga';
import { cancelled, put, take, takeLatest, select, call, all } from 'redux-saga/effects';
import { takeAuth } from '../saga-helpers';
import { VisitActions, VisitTypes } from './actions';
import { datastore, firestore, convertDates } from '../firestore';
import { Context } from '@healthspaces/hsuite-data/repositories/repository';
import { AuthActions, AuthSelectors, AuthTypes } from '../auth';
import { FormActions, FormStage,  FormSelectors } from '../forms'

export default [
  fetchVisitsListener,
  visitSelectedListener,
  lockListener,
]

function* fetchVisitsListener() {
  yield takeAuth(fetchVisitsSaga)
}

function* fetchVisitsSaga(ctx: Context) {
  const db: IStore = yield datastore

  const listener = eventChannel(emit => db.visits.subscribe(ctx,
    (data, error) => emit({ data, error }), db.visits.withForms(FormsStatus.Requested)
  ), buffers.expanding(1))

  yield put(VisitActions.requestVisits(ctx.practice, ctx.clinic))

  try {
    while (true) {
      const { data, error }: { data: Visit[], error: Error } = yield take(listener)
      if (data) {
        yield put(VisitActions.receiveVisits(data))
      } else {
        yield put(VisitActions.abortedVisits(error))
      }
    }
  } finally {
    if (yield cancelled()) {
      listener.close()
    }
  }
}

function* visitSelectedListener() {
  yield takeLatest(VisitTypes.VISIT_SELECT, visitSelectedSaga)
}

function* visitSelectedSaga(action: ReturnType<typeof VisitActions.selectVisit>) {
  const ctx: Context = yield select(AuthSelectors.context)
  const { visit } = action.payload

  // TODO: handle errors

  yield all([
    put(AuthActions.locked(true)),
    put(FormActions.position(FormStage.Welcome, 0)),
    call(fetchPatientSaga, ctx, visit.appointment.patient),
    call(fetchSignatureSaga, ctx, visit.appointment.patient),
    call(fetchMedicalHistorySaga, ctx, visit),
  ])
}

function* lockListener() {
  yield takeLatest(AuthTypes.LOCKED, lockSaga)
}

function* lockSaga(action: ReturnType<typeof AuthActions.locked>) {
  const { locked } = action.payload

  const db: IStore = yield datastore
  const ctx = yield select(AuthSelectors.context)
  const visit: Visit = yield select(FormSelectors.visit)
  if (!visit) return

  yield call([db, db.updateVisitForms], ctx, visit.id, locked
    ? FormsStatus.Activated
    : FormsStatus.Completed
  )
}

function* fetchSignatureSaga(ctx: Context, patient: PatientSummary) {
  yield put(FormActions.signatureFetchRequest())
  try {
    const signature_exists: boolean = yield call(signatureExists, ctx, patient.id)
    yield put(FormActions.signatureFetchSuccess(signature_exists))
  } catch (err) {
    yield put(FormActions.signatureFetchFailure(err))
  }
}

function* fetchMedicalHistorySaga(ctx: Context, visit: Visit) {
  yield put(FormActions.medicalHistoryRequest(ctx.practice, visit.appointment.patient.id))

  try {
    const data = yield call(loadMedicalHistory, ctx, visit.appointment.patient.id)
    yield put(FormActions.medicalHistorySuccess(data))
  } catch (err) {
    yield put(FormActions.medicalHistoryFailure(err))
  }
}

async function loadMedicalHistory(ctx: Context, id: string) {
  const fs: firebase.firestore.Firestore = await firestore

  const path = `/practices/${ctx.practice}/medical_history/${id}`
  const ref = fs.doc(path)

  const doc = await ref.get()

  return doc.exists
    ? <MedicalHistory>{ ...convertDates(doc.data()) }
    : BlankMedicalHistory
}

async function signatureExists(ctx: Context, patient: string) {
  const fs: firebase.firestore.Firestore = await firestore

  const path = `/practices/${ctx.practice}/signatures/${patient}`
  const ref = fs.doc(path)

  const doc = await ref.get()

  return doc.exists
}

function* fetchPatientSaga(ctx: Context, patient: PatientSummary) {
  const db: IStore = yield datastore

  yield put(FormActions.patientFetchRequest())
  try {
    const data: Patient = yield db.patients.get(ctx, patient.id)
    if (!data.info) {
      data.info = BlankPatientInfo
    }
    yield put(FormActions.patientFetchSuccess(data))
  } catch (err) {
    yield put(FormActions.patientFetchFailure(err))
  }
}
