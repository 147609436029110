import { customElement, LitElement, html, property } from 'lit-element'
import { connect } from '@captaincodeman/redux-connect-element'
import { translate as t } from 'lit-translate'
import { PatientSummary } from '@healthspaces/hsuite-data/models';

import { dobFormatter } from '../../service/dobFormatter';
import { store, RootState, FormSelectors } from '../../store';
import { baseStyle, fontStyle } from '../shared-styles';

declare global {
  interface HTMLElementTagNameMap {
    'form-welcome': FormWelcomeElement;
  }
}

@customElement('form-welcome')
export class FormWelcomeElement extends connect(store, LitElement) {
  @property({ type: Object }) patient: PatientSummary

  mapState(state: RootState) {
    return {
      patient: FormSelectors.patient(state),
    }
  }

  render() {
    return html`
    <p>${t('form-welcome.welcome')}</p>
    <p>${t('form-welcome.confirm')} &hellip;</p>

    <p>${t('form-welcome.name')}: <b>${this.patient.firstName} ${this.patient.lastName}</b></p>
    <p>${t('form-welcome.born')}: <b>${dobFormatter.format(this.patient.dob)}</b></p>

    <p>${t('form-welcome.continue')}</p>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
    ]
  }
}
