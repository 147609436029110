import { customElement, LitElement, html, property } from 'lit-element'
import { connect } from '@captaincodeman/redux-connect-element'
import { translate as t } from 'lit-translate'
import './form-checkbox'
import './form-input'
import './form-radio-group'
import '@polymer/paper-radio-button/paper-radio-button'
import { store, FormActions, RootState, FormSelectors } from '../../store'
import { baseStyle, fontStyle, alternatingSections } from '../shared-styles';
import { ContactPhone, ContactEmail, PatientInfo } from '@healthspaces/hsuite-data/models'

declare global {
  interface HTMLElementTagNameMap {
    'patient-contact-info': PatientContactElement;
  }
}

@customElement('patient-contact-info')
export class PatientContactElement extends connect(store, LitElement) {
  @property({ type: Object }) info: PatientInfo

  mapState(state: RootState) {
    return {
      info: FormSelectors.patient_info(state),
    }
  }

  render() {
    return html`
      <h2>${t('patient-contact-info.title')}</h2>
      <h3>${t('patient-contact-info.sub')}</h3>
      <patient-contact-phone .data=${<ContactPhone>this.info.contact_methods[0]} title=${t('patient-contact-info.home')}></patient-contact-phone>
      <patient-contact-phone .data=${<ContactPhone>this.info.contact_methods[1]} title=${t('patient-contact-info.cell')}></patient-contact-phone>
      <patient-contact-phone .data=${<ContactPhone>this.info.contact_methods[2]} title=${t('patient-contact-info.work')}></patient-contact-phone>
      <patient-contact-email .data=${<ContactEmail>this.info.contact_methods[3]} title=${t('patient-contact-info.email')}></patient-contact-email>
      `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
      alternatingSections,
    ]
  }
}

@customElement('patient-contact-phone')
export class PatientContactPhoneElement extends LitElement {
  @property({ type: String }) title: string
  @property({ type: Object }) data: ContactPhone

  onChanged(e: CustomEvent) {
    e.stopPropagation()
    const update = {...this.data, info: { ...this.data.info, ...e.detail.info } }
    store.dispatch(FormActions.patientInfoArrayUpdate('contact_methods', this.data, update))
  }

  render() {
    return html`
    <section class="multi-column">
      <form-input .data=${this.data} key="info.number" title=${this.title} type="string" @form-input-changed=${this.onChanged}></form-input>
      <form-checkbox .data=${this.data} key="info.message" title=${t('patient-contact-phone.message')} @form-checkbox-changed=${this.onChanged}></form-checkbox>
    </section>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
      alternatingSections,
    ]
  }
}

@customElement('patient-contact-email')
export class PatientContactEmailElement extends LitElement {
  @property({ type: String }) title: string
  @property({ type: Object }) data: ContactEmail

  onChanged(e: CustomEvent) {
    e.stopPropagation()
    const update = {...this.data, info: { ...this.data.info, ...e.detail.info } }
    store.dispatch(FormActions.patientInfoArrayUpdate('contact_methods', this.data, update))
  }

  render() {
    return html`
    <section class="multi-column">
      <form-input .data=${this.data} key="info.address" title=${this.title} type="string" @form-input-changed=${this.onChanged}></form-input>
      <form-checkbox .data=${this.data} key="info.message" title=${t('patient-contact-email.message')} @form-checkbox-changed=${this.onChanged}></form-checkbox>
    </section>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
      alternatingSections,
    ]
  }
}

