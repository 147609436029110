import { customElement, LitElement, html, property, query, css } from 'lit-element'
import '@polymer/paper-radio-group/paper-radio-group'
import { PaperRadioButtonElement } from '@polymer/paper-radio-button/paper-radio-button';

declare global {
  interface HTMLElementTagNameMap {
    'form-radio-group': FormRadioGroupElement;
  }
}

@customElement('form-radio-group')
export class FormRadioGroupElement extends LitElement {
  @property({ type: Object }) data: object
  @property({ type: String }) key: string
  @property({ type: String }) title: string

  onChange(e: Event) {
    const el = <PaperRadioButtonElement>e.target
    const paths = this.key.split('.')
    const detail = {}
    let val = detail
    paths.forEach((path, i) => {
      if (i === paths.length - 1) {
        val[path] = el.name
      } else {
        val[path] = {}
        val = val[path]
      }
    })
    const evt = new CustomEvent('form-radio-group-changed', {
      bubbles: true,
      composed: true,
      detail,
    })
    this.dispatchEvent(evt)
  }

  render() {
    const paths = this.key.split('.')
    let val = this.data
    paths.forEach(path => {
      val = val[path]
    })

    return html`
    <paper-radio-group .selected=${val} @change=${this.onChange}>
      <slot></slot>
    </paper-radio-group>
    `
  }

  static get styles() {
    return css`
      :host {
        --paper-radio-button-size: 24px;
      }
    `
  }
}
