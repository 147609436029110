import { customElement, LitElement, html, property } from 'lit-element'
import { connect } from '@captaincodeman/redux-connect-element'
import { translate as t, translateUnsafeHTML as th } from 'lit-translate'
import './form-checkbox'
import './form-input'

import { store, FormActions, RootState, FormSelectors } from '../../store'
import { VisitInfo } from '@healthspaces/hsuite-data/models';
import { baseStyle, fontStyle } from '../shared-styles';

declare global {
  interface HTMLElementTagNameMap {
    'visit-reason': VisitReasonElement;
  }
}

@customElement('visit-reason')
export class VisitReasonElement extends connect(store, LitElement) {
  @property({ type: Object }) info: VisitInfo

  mapState(state: RootState) {
    return {
      info: FormSelectors.visit_info(state),
    }
  }

  mapEvents() {
    return {
      'form-checkbox-changed': (e: CustomEvent) => FormActions.visitUpdate(e.detail),
      'form-input-changed': (e: CustomEvent) => FormActions.visitUpdate(e.detail),
    }
  }

  render() {
    return html`
    <h3>${th('visit-reason.problem.title')}</h3>
    <section class="multi-column">
      <form-checkbox .data=${this.info} key="reason.problem.elbow" title=${t('visit-reason.problem.elbow')}></form-checkbox>
      <form-checkbox .data=${this.info} key="reason.problem.neck" title=${t('visit-reason.problem.neck')}></form-checkbox>
      <form-checkbox .data=${this.info} key="reason.problem.knee" title=${t('visit-reason.problem.knee')}></form-checkbox>
      <form-checkbox .data=${this.info} key="reason.problem.shoulder" title=${t('visit-reason.problem.shoulder')}></form-checkbox>
      <form-checkbox .data=${this.info} key="reason.problem.forearm" title=${t('visit-reason.problem.forearm')}></form-checkbox>
      <form-checkbox .data=${this.info} key="reason.problem.leg" title=${t('visit-reason.problem.leg')}></form-checkbox>
      <form-checkbox .data=${this.info} key="reason.problem.wrist" title=${t('visit-reason.problem.wrist')}></form-checkbox>
      <form-checkbox .data=${this.info} key="reason.problem.back" title=${t('visit-reason.problem.back')}></form-checkbox>
      <form-checkbox .data=${this.info} key="reason.problem.ankle" title=${t('visit-reason.problem.ankle')}></form-checkbox>
      <form-checkbox .data=${this.info} key="reason.problem.hand" title=${t('visit-reason.problem.hand')}></form-checkbox>
      <form-checkbox .data=${this.info} key="reason.problem.hip" title=${t('visit-reason.problem.hip')}></form-checkbox>
      <form-checkbox .data=${this.info} key="reason.problem.foot" title=${t('visit-reason.problem.foot')}></form-checkbox>
      <form-checkbox .data=${this.info} key="reason.problem.finger" title=${t('visit-reason.problem.finger')}></form-checkbox>
      <form-checkbox .data=${this.info} key="reason.problem.thigh" title=${t('visit-reason.problem.thigh')}></form-checkbox>
      <form-checkbox .data=${this.info} key="reason.problem.toe" title=${t('visit-reason.problem.toe')}></form-checkbox>
    </section>

    <h3>${th('visit-reason.side.title')}</h3>
    <section class="multi-column">
      <form-checkbox .data=${this.info} key="reason.problem.left" title=${t('visit-reason.side.left')}></form-checkbox>
      <form-checkbox .data=${this.info} key="reason.problem.right" title=${t('visit-reason.side.right')}></form-checkbox>
    </section>

    <h3>${th('visit-reason.cause.title')}:</h3>
    <section class="multi-column">
      <form-checkbox .data=${this.info} key="reason.cause.work" title=${t('visit-reason.cause.work')}></form-checkbox>
      <form-checkbox .data=${this.info} key="reason.cause.vehicle" title=${t('visit-reason.cause.vehicle')}></form-checkbox>
      <form-checkbox .data=${this.info} key="reason.cause.other" title=${t('visit-reason.cause.other')}></form-checkbox>
    </section>

    <h3>${th('visit-reason.onset.title')}</h3>
    <form-input .data=${this.info} key="reason.started" type="date" title=${t('visit-reason.onset.started')}></form-input>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
    ]
  }
}
