import { customElement, LitElement, html, css, property } from 'lit-element'
import { connect } from '@captaincodeman/redux-connect-element'
import { store, AuthSelectors, AuthActions } from '../store'
import { RootState } from '../store/reducer';

import '@healthspaces/hsuite-elements/auth-signin';
import { AuthEmailPasswordCredentials } from '@healthspaces/hsuite-elements/models/auth';

declare global {
  interface HTMLElementTagNameMap {
    'view-signin': ViewSignInElement;
  }
}

@customElement('view-signin')
export class ViewSignInElement extends connect(store, LitElement) {
  @property({ type: Boolean }) statusKnown: boolean = false
  @property({ type: Boolean }) anonymous: boolean = false

  mapState(state: RootState) {
    return {
      statusKnown: AuthSelectors.statusKnown(state),
      anonymous: AuthSelectors.anonymous(state),
    }
  }

  mapEvents() {
    return {
      'auth-signin': (e: CustomEvent<AuthEmailPasswordCredentials>) =>
        AuthActions.signInWithEmailPassword(e.detail.email, e.detail.password),
    }
  }

  render() {
    return html`<auth-signin ?hidden=${!this.statusKnown || !this.anonymous}></auth-signin>`
  }

  static get styles() {
    return [
      css`
      :host {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
      `
    ]
  }
}
