import { ClinicActions, ClinicTypes } from './actions'
import { Clinic } from '@healthspaces/hsuite-data/models/clinic'
import { AuthActions, AuthTypes } from '../auth';

export interface ClinicState {
  entities: { [key: string]: Clinic }
  loading: boolean
  error: Error
  practice: string
}

export const initialState: ClinicState = {
  entities: {},
  loading: false,
  error: null,
  practice: null,
}

export const clinicKey = (practice: string, clinic: Clinic) => `${practice}/${clinic.id}`

export default (state: ClinicState = initialState, action: ClinicActions | AuthActions) => {
  switch (action.type) {
    case AuthTypes.SIGNED_OUT:
      return initialState

    case ClinicTypes.FETCH_CLINIC_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case ClinicTypes.FETCH_CLINIC_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          [clinicKey(action.payload.practice, action.payload.clinic)]: action.payload.clinic,
        },
        loading: false,
      }

    case ClinicTypes.FETCH_CLINIC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    default:
      return state;
  }
}
