import { customElement, LitElement, html, css, property, PropertyValues, query } from 'lit-element'
import { connect } from '@captaincodeman/redux-connect-element'
import { translate as t, translateUnsafeHTML as th } from 'lit-translate'
import './form-input'

import '@polymer/paper-fab';
import '@polymer/paper-dialog';
import '@polymer/paper-dialog-scrollable';
import { PaperDialogElement } from '@polymer/paper-dialog';

import { store, FormActions, RootState, FormSelectors } from '../../store'
import { MedicalHistory, Surgery } from '@healthspaces/hsuite-data/models'
import { baseStyle, cardStyle, fontStyle } from '../shared-styles';
import { delete_icon } from '../icons';

declare global {
  interface HTMLElementTagNameMap {
    'medical-surgeries': MedicalSurgeriesElement;
    'medical-surgery-list': MedicalSurgeryListElement;
    'medical-surgery-card': MedicalSurgeryCardElement;
    'medical-surgery-edit': MedicalSurgeryEditElement;
  }
}

const emptySurgery: Surgery = { type: '', date: '' }

@customElement('medical-surgeries')
export class MedicalSurgeriesElement extends connect(store, LitElement) {
  @property({ type: Object }) history: MedicalHistory

  mapState(state: RootState) {
    return {
      history: FormSelectors.medical_history(state),
    }
  }

  mapEvents() {
    return {
      'surgery-add': (e: CustomEvent<Surgery>) => FormActions.medicalHistoryArrayAdd('surgeries', e.detail),
      'surgery-update': (e: CustomEvent<{ old: Surgery, new: Surgery }>) => FormActions.medicalHistoryArrayUpdate('surgeries', e.detail.old, e.detail.new),
      'surgery-delete': (e: CustomEvent<Surgery>) => FormActions.medicalHistoryArrayDelete('surgeries', e.detail),
    }
  }

  render() {
    return html`
      ${ this.history.surgeries.length
        ? t('medical-surgeries.instructions.filled')
        : th('medical-surgeries.instructions.empty', { icon: '<iron-icon icon="add"></iron-icon>' })
      }
      <medical-surgery-list .surgeries=${this.history.surgeries}></medical-surgery-list>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
      css`
        svg {
          width: 1em;
          height: 1em;
        }
      `
    ]
  }
}

@customElement('medical-surgery-list')
export class MedicalSurgeryListElement extends LitElement {
  @property({ type: Array }) surgeries: Surgery[]

  @query('medical-surgery-edit')
  editElement: MedicalSurgeryEditElement

  async add(e: Event) {
    try {
      const surgery = await this.editElement.open(emptySurgery)
      const evt = new CustomEvent('surgery-add', {
        bubbles: true,
        composed: true,
        detail: surgery,
      })
      this.dispatchEvent(evt)
    } catch (err) {
      // cancelled
    }
  }

  async edit(e: Event) {
    const el = <MedicalSurgeryCardElement>e.target
    const surgery = el.surgery
    try {
      const updated = await this.editElement.open(surgery)
      const evt = new CustomEvent('surgery-update', {
        bubbles: true,
        composed: true,
        detail: {
          old: surgery,
          new: updated,
        },
      })
      this.dispatchEvent(evt)
    } catch (err) {
      // cancelled
    }
  }

  delete(e: Event) {
    e.stopPropagation()
    const el = <HTMLButtonElement>e.target
    const surgery = el.closest('medical-surgery-card').surgery
    const evt = new CustomEvent('surgery-delete', {
      bubbles: true,
      composed: true,
      detail: surgery,
    })
    this.dispatchEvent(evt)
  }

  render() {
    const surgeries = this.surgeries.sort((a, b) => b.date.localeCompare(a.date, 'en'))

    return html`${surgeries.map(surgery => html`
      <medical-surgery-card .surgery=${surgery} @click=${this.edit} @delete=${this.delete}></medical-surgery-card>`)}
      <paper-fab icon="add" @click=${this.add}></paper-fab>
      <medical-surgery-edit></medical-surgery-edit>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
    ]
  }
}

@customElement('medical-surgery-card')
export class MedicalSurgeryCardElement extends LitElement {
  @property({ type: Object }) surgery: Surgery

  delete(e: Event) {
    e.stopPropagation()
    const evt = new CustomEvent('delete', {
      bubbles: true,
      composed: true,
    })
    this.dispatchEvent(evt)
  }

  render() {
    return html`
      <button @click=${this.delete}>${delete_icon}</button>
      <h2>${this.surgery.type}</h2>
      <p>${this.surgery.date}</p>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
      cardStyle,
      css`
        :host {
          padding: 8px;
          cursor: pointer;
          display: flex;
          align-items: center;
        }

        h2, p {
          margin: 8px 0;
        }
        h2 { width: 70%; }
        p { width: 20% }

        button {
          color: #999;
          background-color: transparent;
          border: none;
          outline: none;
          margin-right: 8px;
        }

        button svg {
          width: 2em;
          height: 2em;
        }
      `
    ]
  }
}

@customElement('medical-surgery-edit')
export class MedicalSurgeryEditElement extends LitElement {
  @property({ type: Object }) surgery: Surgery = emptySurgery

  private dialog: PaperDialogElement
  private resolve: (Surgery) => void
  private reject: () => void

  firstUpdated(changedProperties: PropertyValues) {
    // re-parent dialog to fix stacking
    this.dialog = this.shadowRoot.querySelector('paper-dialog')
    this.dialog = document.body.appendChild(this.dialog);

    this.dialog.addEventListener('form-input-changed', (e: CustomEvent) => {
      this.surgery = { ...this.surgery, ...e.detail }
    })
  }

  open(surgery: Surgery): Promise<Surgery> {
    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
      this.surgery = surgery
      this.dialog.open()
    })
  }

  save() {
    this.resolve(this.surgery)
  }

  hide() {
    this.reject()
  }

  render() {
    return html`
      <paper-dialog style="width: 80vw" with-backdrop no-cancel-on-outside-click @iron-overlay-closed=${this.hide}>
        <h2 style="color: #666">${t('medical-surgeries-edit.title')}</h2>
        <paper-dialog-scrollable>
          <form-input .data=${this.surgery} key="type" title=${t('medical-surgeries-edit.type')}></form-input>
          <form-input .data=${this.surgery} key="date" title=${t('medical-surgeries-edit.date')} type="text" inputmode="numeric"></form-input>
        </paper-dialog-scrollable>
        <div class="buttons" style="display:flex; align-items: center; align-content: flex-end;">
          <paper-button dialog-dismiss>${t('dialog.cancel')}</paper-button>
          <paper-button dialog-confirm autofocus @click=${this.save}>${t('dialog.save')}</paper-button>
        </div>
      </paper-dialog>
    `
  }
}
