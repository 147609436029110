import { combineReducers } from 'redux';

import auth, { AuthState } from './auth/reducer';
import clinics, { ClinicState } from './clinics/reducer';
import forms, { FormState } from './forms/reducer';
import practices, { PracticeState } from './practices/reducer';
import routing, { RoutingState } from './routing/reducer';
import signins, { SigninState } from './signins/reducer';
import visits, { VisitState } from './visits/reducer';

export interface RootState {
  readonly auth : AuthState
  readonly clinics : ClinicState
  readonly forms : FormState
  readonly practices : PracticeState
  readonly routing : RoutingState
  readonly signins : SigninState
  readonly visits : VisitState
}

export const rootReducer = combineReducers({
  auth,
  clinics,
  forms,
  practices,
  routing,
  signins,
  visits,
})
