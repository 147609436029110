import { ActionsUnion, createAction } from '../actions';
import { Clinic } from '@healthspaces/hsuite-data/models/clinic'

export enum ClinicTypes {
  FETCH_CLINIC_REQUEST = 'FETCH_CLINIC_REQUEST',
  FETCH_CLINIC_SUCCESS = 'FETCH_CLINIC_SUCCESS',
  FETCH_CLINIC_FAILURE = 'FETCH_CLINIC_FAILURE',
}

export const ClinicActions = {
  requestClinic: (practice: string, clinic: string) => createAction(ClinicTypes.FETCH_CLINIC_REQUEST, { practice, clinic }),
  receiveClinic: (practice: string, clinic: Clinic) => createAction(ClinicTypes.FETCH_CLINIC_SUCCESS, { practice, clinic }),
  abortedClinic: (error: Error) => createAction(ClinicTypes.FETCH_CLINIC_FAILURE, { error }),
}

export type ClinicActions = ActionsUnion<typeof ClinicActions>
