import { AuthActions, AuthTypes } from './actions'
import { User, Access } from './models'

export interface AuthState {
  user?: User;
  access?: Access;
  statusKnown: boolean;
  emailSent: boolean;
  locked: boolean;
}

export const initialState: AuthState = {
  user: null,
  access: null,
  statusKnown: false,
  emailSent: false,
  locked: false,
}

export default (state: AuthState = initialState, action: AuthActions) => {
  switch (action.type) {
    case AuthTypes.SIGNED_IN:
      return {
        ...state,
        user: action.payload.user,
        access: action.payload.access,
        statusKnown: true,
      }

    case AuthTypes.SIGNED_OUT:
      return {
        ...state,
        user: null,
        statusKnown: true,
      }

    case AuthTypes.SIGNIN_EMAIL:
      return {
        ...state,
        emailSent: true,
      }

    case AuthTypes.LOCKED:
      return {
        ...state,
        locked: action.payload.locked
      }

    default:
      return state;
  }
}
