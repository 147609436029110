import { createSelector } from 'reselect';

import { RootState } from '../reducer';

const getState = (state: RootState) => state.signins;

export namespace SigninSelectors {
  export const signins = createSelector(
    [getState],
    (state) => state.keys
      .map((key) => state.entities[key])
      .sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime())
  )

  export const loading = createSelector(
    [getState],
    (state) => state.fetching
  )
}
