import { PracticeActions, PracticeTypes } from './actions'
import { Practice } from '@healthspaces/hsuite-data/models/practice'
import { AuthActions, AuthTypes } from '../auth';

export interface PracticeState {
  entities: { [key: string]: Practice }
  loading: boolean
  error: Error
}

export const initialState: PracticeState = {
  entities: {},
  loading: false,
  error: null,
}

export default (state: PracticeState = initialState, action: PracticeActions | AuthActions) => {
  switch (action.type) {
    case AuthTypes.SIGNED_OUT:
      return initialState

    case PracticeTypes.FETCH_PRACTICE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case PracticeTypes.FETCH_PRACTICE_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.id]: action.payload.practice,
        },
        loading: false,
      }

    case PracticeTypes.FETCH_PRACTICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    default:
      return state;
  }
}
