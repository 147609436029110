import { customElement, LitElement, html, property, PropertyValues } from 'lit-element'
import { connect } from '@captaincodeman/redux-connect-element'
import { translate as t, translateUnsafeHTML as th } from 'lit-translate'
import './form-radio-group'
import './form-input'

import { store, FormActions, RootState, FormSelectors } from '../../store'
import { baseStyle, alternatingSections, fontStyle } from '../shared-styles';
import { EducationLevel, MaritalStatus, MedicalHistory, HabitItem } from '@healthspaces/hsuite-data/models';

import './medical-social-smoking'
import './medical-social-alcohol'

declare global {
  interface HTMLElementTagNameMap {
    'medical-social': MedicalSocialElement;
  }
}

@customElement('medical-social')
export class MedicalSocialElement extends connect(store, LitElement) {
  @property({ type: Object }) history: MedicalHistory
  @property({ type: Object }) smoking: HabitItem
  @property({ type: Object }) alcohol: HabitItem

  mapState(state: RootState) {
    return {
      history: FormSelectors.medical_history(state),
    }
  }

  updated(changedProperties: PropertyValues) {
    if (changedProperties.has('history')) {
      this.smoking = this.history.social.smoking.length
        ? this.history.social.smoking[0]
        : { name: 'smoking', started: null, ended: null, frequency: -1 }
      this.alcohol = this.history.social.alcohol.length
        ? this.history.social.alcohol[0]
        : { name: 'alcohol', started: null, ended: null, frequency: -1 }
    }

    const valid = (this.history.social.smoking.length > 0 && this.history.social.alcohol.length > 0)
    store.dispatch(FormActions.valid(valid))
  }

  onSmokingUpdated(e: CustomEvent<HabitItem>) {
    const action = this.history.social.smoking.length
      ? FormActions.medicalHistoryArrayUpdate('social.smoking', this.history.social.smoking[0], e.detail)
      : FormActions.medicalHistoryArrayAdd('social.smoking', e.detail)
    store.dispatch(action)
  }

  onAlcoholUpdated(e: CustomEvent<HabitItem>) {
    const action = this.history.social.alcohol.length
      ? FormActions.medicalHistoryArrayUpdate('social.alcohol', this.history.social.alcohol[0], e.detail)
      : FormActions.medicalHistoryArrayAdd('social.alcohol', e.detail)
    store.dispatch(action)
  }

  mapEvents() {
    return {
      'form-radio-group-changed': (e: CustomEvent) => FormActions.medicalHistoryUpdate(e.detail),
      'form-input-changed': (e: CustomEvent) => FormActions.medicalHistoryUpdate(e.detail),
    }
  }

  render() {
    return html`
    <section>
      <medical-social-smoking
        .smoking=${this.smoking}
        @medical-social-smoking-updated=${this.onSmokingUpdated}>
      </medical-social-smoking>
    </section>

    <section>
      <medical-social-alcohol
        .alcohol=${this.alcohol}
        @medical-social-alcohol-updated=${this.onAlcoholUpdated}>
      </medical-social-alcohol>
    </section>

    <section>
      <h3>${th('medical-social.drugs.title')}:</h3>
      <form-input .data=${this.history} key="social.drugs" title=${t('medical-social.drugs.label')}></form-input>
    </section>

    <section>
      <h3>${th('medical-social.education.title')}</h3>
      <form-radio-group .data=${this.history} key="social.highestEducation">
        <paper-radio-button name=${EducationLevel.Elementary}>${t('medical-social.education.elementary')}</paper-radio-button>
        <paper-radio-button name=${EducationLevel.HighSchool}>${t('medical-social.education.high-school')}</paper-radio-button>
        <paper-radio-button name=${EducationLevel.College}>${t('medical-social.education.college')}</paper-radio-button>
        <paper-radio-button name=${EducationLevel.GradSchool}>${t('medical-social.education.grad-school')}</paper-radio-button>
      </form-radio-group>
    </section>

    <section>
      <h3>${th('medical-social.maritalStatus.title')}</h3>
      <form-radio-group .data=${this.history} key="social.maritalStatus">
        <paper-radio-button name=${MaritalStatus.Single}>${t('medical-social.maritalStatus.single')}</paper-radio-button>
        <paper-radio-button name=${MaritalStatus.Married}>${t('medical-social.maritalStatus.married')}</paper-radio-button>
        <paper-radio-button name=${MaritalStatus.Separated}>${t('medical-social.maritalStatus.separated')}</paper-radio-button>
        <paper-radio-button name=${MaritalStatus.Divorced}>${t('medical-social.maritalStatus.divorced')}</paper-radio-button>
        <paper-radio-button name=${MaritalStatus.Widowed}>${t('medical-social.maritalStatus.widowed')}</paper-radio-button>
      </form-radio-group>
    </section>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
      alternatingSections,
    ]
  }
}
