import { createSelector } from 'reselect'
import { merge } from '@healthspaces/hsuite-data'
import { AuthSelectors } from '../auth';
import { PracticeSelectors } from '../practices/selectors'
import { RootState } from '../reducer';

const getState = (state: RootState) => state.clinics
const clinic = createSelector(
  [getState, AuthSelectors.accessKey],
  (state, accessKey) => accessKey
    ? state.entities[accessKey]
    : undefined
)

export namespace ClinicSelectors {
  export const current = createSelector(
    [clinic, PracticeSelectors.current],
    (clinic, practice) => merge(practice, clinic)

  )
}
