import { customElement, LitElement, html, property } from 'lit-element'
import { connect } from '@captaincodeman/redux-connect-element'
import { translate as t, translateUnsafeHTML as th } from 'lit-translate'
import './form-checkbox'
import './form-input'

import { store, FormActions, RootState, FormSelectors } from '../../store'
import { baseStyle, fontStyle } from '../shared-styles';
import { VisitInfo } from '@healthspaces/hsuite-data/models'

declare global {
  interface HTMLElementTagNameMap {
    'visit-previous': VisitPreviousElement;
  }
}

@customElement('visit-previous')
export class VisitPreviousElement extends connect(store, LitElement) {
  @property({ type: Object }) info: VisitInfo

  mapState(state: RootState) {
    return {
      info: FormSelectors.visit_info(state),
    }
  }

  mapEvents() {
    return {
      'form-checkbox-changed': (e: CustomEvent) => FormActions.visitUpdate(e.detail),
      'form-input-changed': (e: CustomEvent) => FormActions.visitUpdate(e.detail),
    }
  }

  render() {
    return html`
    <h3>${th('visit-previous.tests.title')}</h3>
    <section>
      <form-checkbox .data=${this.info} key="prior_incidents.tests.x_rays" title=${t('visit-previous.tests.x_rays')}></form-checkbox>
      <form-checkbox .data=${this.info} key="prior_incidents.tests.ct_cat" title=${t('visit-previous.tests.ct_cat')}></form-checkbox>
      <form-checkbox .data=${this.info} key="prior_incidents.tests.mri" title=${t('visit-previous.tests.mri')}></form-checkbox>
      <form-checkbox .data=${this.info} key="prior_incidents.tests.nerve_test" title=${t('visit-previous.tests.nerve_test')}></form-checkbox>
      <form-checkbox .data=${this.info} key="prior_incidents.tests.anthrogram" title=${t('visit-previous.tests.anthrogram')}></form-checkbox>
      <form-checkbox .data=${this.info} key="prior_incidents.tests.myelogram" title=${t('visit-previous.tests.myelogram')}></form-checkbox>
      <form-checkbox .data=${this.info} key="prior_incidents.tests.discogram" title=${t('visit-previous.tests.discogram')}></form-checkbox>
    </section>

    <h3>${th('visit-previous.images.title')}</h3>
    <section>
    <form-checkbox .data=${this.info} key="prior_incidents.images" title=${t('visit-previous.images.yes')}></form-checkbox>
    </section>

    <h3>${th('visit-previous.treatments.title')}</h3>
    <section>
      <form-checkbox .data=${this.info} key="prior_incidents.treatments.surgery" title=${t('visit-previous.treatments.surgery')}></form-checkbox>
      <form-input .data=${this.info} key="prior_incidents.location" title=${t('visit-previous.treatments.location')}></form-input>
      <form-input .data=${this.info} key="prior_incidents.year" title=${t('visit-previous.treatments.year')}></form-input>
      <form-checkbox .data=${this.info} key="prior_incidents.treatments.injury" title=${t('visit-previous.treatments.injury')}></form-checkbox>
      <form-input .data=${this.info} key="prior_incidents.details" title=${t('visit-previous.treatments.details')}></form-input>
    </section>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
    ]
  }
}
