import { all, put, take, takeLatest, select, call, race, cancelled, delay } from 'redux-saga/effects';
import { Context } from '@healthspaces/hsuite-data/repositories/repository';
import { AuthSelectors } from '../auth';
import { FormTypes, FormActions } from './actions';
import { firestore } from '../firestore';
import { storage } from '../storage';
import { FormSelectors } from './selectors';
import { FormStage, pages } from './models';
import { AuthTypes, AuthActions } from '../auth/actions';
import { Visit, Signin, VisitInfo, MedicalHistory } from '@healthspaces/hsuite-data/models';
import { Patient } from '../models'
import { eventChannel, buffers, END } from 'redux-saga';
import { eventOptions } from 'is-support-passive-events'

export default [
  signatureListener,
  navigationSaga,
  formNavigationSaga,
  formsCompleteSaga,
  scrollPosition,
  uploadImageSaga,
]

const SCROLL_THRESHOLD = 0.985

function* navigationSaga() {
  while (true) {
    const { prev, next } = yield race({
      prev: take(FormTypes.FORM_PREV),
      next: take(FormTypes.FORM_NEXT),
    })

    const stage: FormStage = yield select(FormSelectors.stage)
    const step: number = yield select(FormSelectors.step)
    const signature_exists: boolean = yield select(FormSelectors.signature_exists)

    if (prev) {
      switch (stage) {
        case FormStage.Page:
          if (step > 0) {
            yield put(FormActions.position(FormStage.Page, step - 1))
          } else {
            yield put(FormActions.position(FormStage.Welcome, 0))
          }
          yield put(FormActions.valid(true))
          break
        case FormStage.Legal:
          yield put(FormActions.position(FormStage.Page, pages.length - 1))
          yield put(FormActions.valid(true))
          break
        case FormStage.Complete:
          yield put(FormActions.position(FormStage.Page, pages.length - 1))
          break
      }
    }

    if (next) {
      switch (stage) {
        case FormStage.Welcome:
          yield put(FormActions.position(FormStage.Page, 0))
          break
        case FormStage.Page:
          if (step < pages.length - 1) {
            yield put(FormActions.position(FormStage.Page, step + 1))
          } else if (!signature_exists) {
            yield put(FormActions.position(FormStage.Legal, 0))
          } else {
            yield put(FormActions.position(FormStage.Complete, 0))
          }
          break
        case FormStage.Legal:
          yield put(FormActions.position(FormStage.Complete, 0))
          break
      }
    }

    document.documentElement.scrollTo(0, 0)
  }
}

function* signatureListener() {
  yield takeLatest(FormTypes.FORM_SIGNATURE, signatureSaga)
}

function* signatureSaga(action: ReturnType<typeof FormActions.signature>) {
  const { image, empty } = action.payload

  yield put(FormActions.valid(!empty))
  if (empty) return

  const ctx: Context = yield select(AuthSelectors.context)
  const visit: Visit = yield select(FormSelectors.visit)
  const signin: Signin = yield select(FormSelectors.signin)

  try {
    if (visit) {
      yield call(saveVisitSignature, ctx, visit.appointment.patient.id, image)
    }

    if (signin) {
      yield call(saveSigninSignature, ctx, signin.id, image)
    }
    yield put(FormActions.signatureFetchSuccess(!empty))
  } catch (err) {
    console.error(err)
  }
}

async function saveVisitSignature(ctx: Context, patient: string, image: string) {
  const fs: firebase.firestore.Firestore = await firestore

  const path = `/practices/${ctx.practice}/signatures/${patient}`
  const ref = fs.doc(path)

  await ref.set({
    updated: window.firebase.firestore.FieldValue.serverTimestamp(),
    image,
  })
}

async function saveSigninSignature(ctx: Context, id: string, signature: string) {
  const fs: firebase.firestore.Firestore = await firestore

  const path = `/practices/${ctx.practice}/clinics/${ctx.clinic}/signins/${id}`
  const ref = fs.doc(path)

  await ref.update({
    updated: window.firebase.firestore.FieldValue.serverTimestamp(),
    signature,
  })
}

function* formNavigationSaga() {
  // avoid saving on every form nav, unless changes have been made:
  // let dirty = false
  // while (true) {
  //   const { prev, next, update } = race ...
  //   if (prev or next && dirty) {
  //      save data
  //      dirty = false
  //   }
  //   if (update) {
  //      dirty = true
  //   }
  // }

  yield takeLatest([
    FormTypes.FORM_PREV,
    FormTypes.FORM_NEXT,
  ], function* () {
    // if going from form page, save form
    const stage: FormStage = yield select(FormSelectors.stage)
    if (stage === FormStage.Page) {
      yield call(formsSaveSaga, false)
    }
  })
}

// save completed forms when unlocked
function* formsCompleteSaga() {
  yield takeLatest(AuthTypes.LOCKED, function* (action: ReturnType<typeof AuthActions.locked>) {
    const { locked } = action.payload
    if (!locked) {
      yield call(formsSaveSaga, true)
      yield put(FormActions.visitReset())
    }
  })
}

function* formsSaveSaga(complete: boolean) {
  const ctx: Context = yield select(AuthSelectors.context)
  const visit: Visit = yield select(FormSelectors.visit)
  const signin: Signin = yield select(FormSelectors.signin)
  const patient: Patient = yield select(FormSelectors.patient)
  const visit_data: VisitInfo = yield select(FormSelectors.visit_info)
  const medical_history_data: MedicalHistory = yield select(FormSelectors.medical_history)
  console.log(patient)

  try {
    if (visit) {
      yield call(saveVisitInfo, ctx, visit.id, patient, visit_data, medical_history_data, complete)
    }

    if (signin) {
      yield call(saveSigninInfo, ctx, signin.id, patient, visit_data, medical_history_data, complete)
    }
  } catch (err) {
    console.error(err)
  }
}

async function saveVisitInfo(ctx, id: string, patient: Patient, visit_info: VisitInfo, medical_history: MedicalHistory, complete: boolean) {
  const fs: firebase.firestore.Firestore = await firestore

  const batch = fs.batch()

  batch.set(fs.doc(`/practices/${ctx.practice}/clinics/${ctx.clinic}/visit_infos/${id}`), {
    ...visit_info,
    patient: patient.id,
    updated: window.firebase.firestore.FieldValue.serverTimestamp(),
    complete,
  })

  const { firstName, lastName, dob, info } = patient

  batch.update(fs.doc(`/practices/${ctx.practice}/patients/${patient.id}`), {
    firstName,
    lastName,
    dob,
    info,
  })

  batch.set(fs.doc(`/practices/${ctx.practice}/medical_history/${patient.id}`), {
    ...medical_history,
    updated: window.firebase.firestore.FieldValue.serverTimestamp(),
    complete,
  })

  await batch.commit()
}

async function saveSigninInfo(ctx, id: string, patient: Patient, visit_info: VisitInfo, medical_history: MedicalHistory, complete: boolean) {
  const fs: firebase.firestore.Firestore = await firestore

  const path = `/practices/${ctx.practice}/clinics/${ctx.clinic}/signins/${id}`
  const ref = fs.doc(path)

  await ref.update({
    patient,
    visit_info,
    medical_history,
    complete,
    updated: window.firebase.firestore.FieldValue.serverTimestamp(),
  })
}

function* scrollPosition() {
  const channel = scrollChannel()

  try {
    while (true) {
      const { scroll, at_end } = yield all({
        scroll: take(channel),
        at_end: select(FormSelectors.scrolled_to_end),
      })
      const bottom = (scroll >= SCROLL_THRESHOLD)
      if (bottom !== at_end) {
        yield put(FormActions.scrollPosition(bottom))
      }
    }
  } finally {
    if (yield cancelled()) {
      channel.close()
    }
  }
}

function scrollChannel() {
  return eventChannel(emit => {
    const handler = (e: Event) => {
      const top = document.scrollingElement.scrollTop
      const pos = document.scrollingElement.scrollHeight - window.innerHeight
      emit(top / pos) // scroll percentage
    }
    const options = eventOptions({ passive: true, capture: false })
    document.addEventListener('scroll', handler, options)

    return () => document.removeEventListener('scroll', handler, options)
  }, buffers.fixed(1))
}

function* uploadImageSaga() {
  yield takeLatest(FormTypes.FORM_PATIENT_UPLOAD_IMAGE, function* (action: ReturnType<typeof FormActions.uploadImage>) {
    const { type, blob } = action.payload
    const ctx: Context = yield select(AuthSelectors.context)
    const patient: Patient = yield select(FormSelectors.patient)
    const chan = yield call(uploadImage, ctx.practice, patient.id, type, blob)

    try {
      while (true) {
        const { progress, url, err } = yield take(chan)
        if (progress) {
          yield put(FormActions.uploadImageProgress(type, progress))
        }
        if (url) {
          const paths = type.split('.')
          const detail = {}
          let val = detail

          paths.forEach((path, i) => {
            if (i === paths.length - 1) {
              val[path] = url
            } else {
              val[path] = {}
              val = val[path]
            }
          })
          yield put(FormActions.patientInfoUpdate(detail))
          yield put(FormActions.uploadImageComplete(type))
        }
        if (err) {
          // TODO: handle
        }
      }
    } finally {}
  })
}

async function uploadImage(practice: string, patient: string, type: string, blob: Blob) {
  const st: firebase.storage.Storage = await storage
  const ref = st.ref(`practices/${practice}/patients/${patient}/${type}`)
  const task = ref.put(blob)

  return eventChannel(emit => {
    task.on(window.firebase.storage.TaskEvent.STATE_CHANGED, snap => {
      emit({ progress: snap.bytesTransferred / snap.totalBytes })
    }, err => {
      emit({ err })
      emit(END)
    }, async () => {
      const url = await ref.getDownloadURL()
      emit({ url })
      emit(END)
    })

    return () => {}
  })
}
