import { customElement, LitElement, html, property } from 'lit-element'
import { connect } from '@captaincodeman/redux-connect-element'
import { translate as t, translateUnsafeHTML as th } from 'lit-translate'
import './form-checkbox'

import { store, FormActions, RootState, FormSelectors } from '../../store'
import { VisitInfo } from '@healthspaces/hsuite-data/models';
import { baseStyle, fontStyle } from '../shared-styles';

declare global {
  interface HTMLElementTagNameMap {
    'visit-systems': VisitSytemsElement;
  }
}

@customElement('visit-systems')
export class VisitSytemsElement extends connect(store, LitElement) {
  @property({ type: Object }) info: VisitInfo

  mapState(state: RootState) {
    return {
      info: FormSelectors.visit_info(state),
    }
  }

  mapEvents() {
    return {
      'form-checkbox-changed': (e: CustomEvent) => FormActions.visitUpdate(e.detail)
    }
  }

  render() {
    return html`
    <h3>${th('visit-systems.fallen.title')}</h3>
      <section>
        <form-input .data=${this.info} key="systems.fallenTimes" placeholder="" title=${t('visit-systems.fallen.number')}></form-input>
      </section>

    <p>${th('visit-systems.experiencing')}</p>

    <h3>${th('visit-systems.general.title')}</h3>
    <section class="multi-column">
      <form-checkbox .data=${this.info} key="systems.general.fever" title=${t('visit-systems.general.fever')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.general.night_sweat" title=${t('visit-systems.general.night_sweat')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.general.night_pain" title=${t('visit-systems.general.night_pain')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.general.weight_loss" title=${t('visit-systems.general.weight_loss')}></form-checkbox>
    </section>

    <h3>${th('visit-systems.eyes.title')}</h3>
    <section class="multi-column">
      <form-checkbox .data=${this.info} key="systems.eyes.cataracts" title=${t('visit-systems.eyes.cataracts')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.eyes.blindness" title=${t('visit-systems.eyes.blindness')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.eyes.double_vision" title=${t('visit-systems.eyes.double_vision')}></form-checkbox>
    </section>

    <h3>${th('visit-systems.heent.title')}</h3>
    <section class="multi-column">
      <form-checkbox .data=${this.info} key="systems.heent.cough" title=${t('visit-systems.heent.cough')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.heent.sinus_problems" title=${t('visit-systems.heent.sinus_problems')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.heent.sore_throat" title=${t('visit-systems.heent.sore_throat')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.heent.hearing_loss" title=${t('visit-systems.heent.hearing_loss')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.heent.dentures" title=${t('visit-systems.heent.dentures')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.heent.loose_tooth" title=${t('visit-systems.heent.loose_tooth')}></form-checkbox>
    </section>

    <h3>${th('visit-systems.heart.title')}</h3>
    <section class="multi-column">
      <form-checkbox .data=${this.info} key="systems.heart.chest_pain" title=${t('visit-systems.heart.chest_pain')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.heart.irregular_beat" title=${t('visit-systems.heart.irregular_beat')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.heart.high_blood_pressure" title=${t('visit-systems.heart.high_blood_pressure')}></form-checkbox>
    </section>

    <h3>${th('visit-systems.lungs.title')}</h3>
    <section class="multi-column">
      <form-checkbox .data=${this.info} key="systems.lungs.wheezing" title=${t('visit-systems.lungs.wheezing')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.lungs.shortness_breath" title=${t('visit-systems.lungs.shortness_breath')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.lungs.pain_breathing" title=${t('visit-systems.lungs.pain_breathing')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.lungs.sputum_production" title=${t('visit-systems.lungs.sputum_production')}></form-checkbox>
    </section>

    <h3>${th('visit-systems.abdominal.title')}</h3>
    <section class="multi-column">
      <form-checkbox .data=${this.info} key="systems.abdominal.heartburn" title=${t('visit-systems.abdominal.heartburn')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.abdominal.difficult_swallowing" title=${t('visit-systems.abdominal.difficult_swallowing')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.abdominal.nausea_vomiting" title=${t('visit-systems.abdominal.nausea_vomiting')}></form-checkbox>
    </section>

    <h3>${th('visit-systems.urinary.title')}</h3>
    <section class="multi-column">
      <form-checkbox .data=${this.info} key="systems.urinary.incontinence" title=${t('visit-systems.urinary.incontinence')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.urinary.kidney_stones" title=${t('visit-systems.urinary.kidney_stones')}></form-checkbox>
    </section>

    <h3>${th('visit-systems.musculoskeletal.title')}</h3>
    <section class="multi-column">
      <form-checkbox .data=${this.info} key="systems.musculoskeletal.joint_swelling" title=${t('visit-systems.musculoskeletal.joint_swelling')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.musculoskeletal.muscle_cramps" title=${t('visit-systems.musculoskeletal.muscle_cramps')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.musculoskeletal.stiffness" title=${t('visit-systems.musculoskeletal.stiffness')}></form-checkbox>
    </section>

    <h3>${th('visit-systems.skin_breast.title')}</h3>
    <section class="multi-column">
      <form-checkbox .data=${this.info} key="systems.skin_breast.rash" title=${t('visit-systems.skin_breast.rash')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.skin_breast.moles" title=${t('visit-systems.skin_breast.moles')}></form-checkbox>
    </section>

    <h3>${th('visit-systems.neurologic.title')}</h3>
    <section class="multi-column">
      <form-checkbox .data=${this.info} key="systems.neurologic.seizures" title=${t('visit-systems.neurologic.seizures')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.neurologic.blackouts" title=${t('visit-systems.neurologic.blackouts')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.neurologic.balance_problems" title=${t('visit-systems.neurologic.balance_problems')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.neurologic.headaches" title=${t('visit-systems.neurologic.headaches')}></form-checkbox>
    </section>

    <h3>${th('visit-systems.psychiatric.title')}</h3>
    <section class="multi-column">
      <form-checkbox .data=${this.info} key="systems.psychiatric.depression" title=${t('visit-systems.psychiatric.depression')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.psychiatric.hyperactivity" title=${t('visit-systems.psychiatric.hyperactivity')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.psychiatric.insomnia" title=${t('visit-systems.psychiatric.insomnia')}></form-checkbox>
    </section>

    <h3>${th('visit-systems.metabolism.title')}</h3>
    <section class="multi-column">
      <form-checkbox .data=${this.info} key="systems.metabolism.weight_gain" title=${t('visit-systems.metabolism.weight_gain')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.metabolism.high_blood_sugar" title=${t('visit-systems.metabolism.high_blood_sugar')}></form-checkbox>
    </section>

    <h3>${th('visit-systems.blood.title')}</h3>
    <section class="multi-column">
      <form-checkbox .data=${this.info} key="systems.blood.anemia" title=${t('visit-systems.blood.anemia')}></form-checkbox>
      <form-checkbox .data=${this.info} key="systems.blood.prolonged_bleeding" title=${t('visit-systems.blood.prolonged_bleeding')}></form-checkbox>
    </section>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
    ]
  }
}
