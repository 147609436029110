import { customElement, LitElement, html } from 'lit-element'
import { translateUnsafeHTML as th } from 'lit-translate'

import { baseStyle, fontStyle } from '../shared-styles';

declare global {
  interface HTMLElementTagNameMap {
    'form-complete': FormCompleteElement;
  }
}

@customElement('form-complete')
export class FormCompleteElement extends LitElement {
  render() {
    return html`<p>${th('form-complete')}</p>`
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
    ]
  }
}
