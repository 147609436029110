import { customElement, LitElement, html, css, property, query } from 'lit-element'
import { connect } from '@captaincodeman/redux-connect-element'
import { User } from '@firebase/auth-types';
import { translate as t } from 'lit-translate'

import '@polymer/app-layout/app-header-layout/app-header-layout'
import '@polymer/app-layout/app-header/app-header'
import '@polymer/app-layout/app-toolbar/app-toolbar'
import '@polymer/paper-icon-button/paper-icon-button'
import '@polymer/iron-icons/iron-icons'
import '@healthspaces/hsuite-elements/auth-status';

import { store, RootState, AuthSelectors, RoutingActions, AuthActions, FormSelectors, FormStage } from '../store'

import './app-routes'
import './language-select'
import './unlock-dialog'
import { UnlockDialogElement } from './unlock-dialog';

declare global {
  interface HTMLElementTagNameMap {
    'app-shell': AppShellElement;
  }
}

@customElement('app-shell')
export class AppShellElement extends connect(store, LitElement) {
  constructor() {
    super()

    // NOTE: Although this might _appear_ to do nothing, it actually
    // causes a subtle change in behavior inside the Polymer gesture
    // library. It allows the blur events to fire for input elements
    // which triggers the onscreen keyboard to hide if the user taps
    // away from an input
    this.addEventListener('click', _ => {})
  }

  @property({ type: Boolean }) statusKnown: boolean
  @property({ type: Boolean }) authenticated: boolean
  @property({ type: Object }) user: User
  @property({ type: Boolean }) locked: boolean
  @property({ type: Number }) stage: FormStage
  @property({ type: String }) page: string

  @query('unlock-dialog')
  unlockDialog: UnlockDialogElement

  mapState(state: RootState) {
    return {
      statusKnown: AuthSelectors.statusKnown(state),
      authenticated: AuthSelectors.authenticated(state),
      user: AuthSelectors.user(state),
      locked: AuthSelectors.locked(state),
      stage: FormSelectors.stage(state),
      page: FormSelectors.page(state),
    }
  }

  mapEvents() {
    return {
      'auth-profile': (e: CustomEvent) => RoutingActions.push('/account'),
      'auth-signin': (e: CustomEvent) => RoutingActions.push('/signin'),
      'auth-signout': (e: CustomEvent) => AuthActions.signOut(),
      'code-input': (e: CustomEvent) => AuthActions.codeInput(e.detail),
    }
  }

  unlock(e: Event) {
    this.unlockDialog.open()
  }

  render() {
    return html`
      <unlock-dialog></unlock-dialog>
      <app-header-layout fullbleed>
        <app-header slot="header" fixed>
          <app-toolbar>
            <div main-title>${this.renderTitle()}</div>
            <language-select></language-select>
            <auth-status .user="${this.user}" ?status-known=${this.statusKnown} ?authenticated=${this.authenticated} ?hidden=${this.locked}></auth-status>
            <paper-icon-button icon="lock" ?hidden=${!this.locked} @click=${this.unlock} title="Unlock"></paper-icon-button>
          </app-toolbar>
        </app-header>
        <app-routes></app-routes>
      </app-header-layout>
    `
  }

  renderTitle() {
    if (!this.locked) return 'HSuite PHR'

    switch (this.stage) {
      case FormStage.Welcome:
        return t('app-shell.welcome')
      case FormStage.Page:
        return t('app-shell.page.' + this.page)
      case FormStage.Legal:
        return t('app-shell.legal')
      case FormStage.Complete:
        return t('app-shell.complete')
      default:
        return `unexpected stage ${this.stage}`
    }
  }

  static get styles() {
    return [
      css`
      :host {
        display: block;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
      }

      [hidden] {
        display: none !important;
      }

      app-header {
        background-color: #2ad3b3;
        color: #fff;
      }

      app-header paper-icon-button {
        --paper-icon-button-ink-color: white;
      }
      `
    ]
  }
}
