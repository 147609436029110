import { createSelector } from 'reselect';

import { RootState } from '../reducer';

const getState = (state: RootState) => state.visits;

export namespace VisitSelectors {
  export const visits = createSelector(
    [getState],
    (state) => state.keys
      .map((key) => state.entities[key])
      .sort((a, b) => a.appointment.datetime.getTime() - b.appointment.datetime.getTime())
  )

  export const loading = createSelector(
    [getState],
    (state) => state.fetching
  )
}
