import { ActionsUnion, createAction } from '../actions';
import { Visit, Signin } from '@healthspaces/hsuite-data/models'

export enum SigninTypes {
  SIGNIN_SELECT = 'SIGNIN_SELECT',
  SIGNINS_FETCH_REQUEST = 'SIGNINS_FETCH_REQUEST',
  SIGNINS_FETCH_SUCCESS = 'SIGNINS_FETCH_SUCCESS',
  SIGNINS_FETCH_FAILURE = 'SIGNINS_FETCH_FAILURE',
  SIGNIN_SWITCH = 'SIGNIN_SWITCH',
}

export const SigninActions = {
  selectSignin: (signin: Signin) => createAction(SigninTypes.SIGNIN_SELECT, { signin }),

  requestSignins: (practice: string, clinic: string) => createAction(SigninTypes.SIGNINS_FETCH_REQUEST, { practice, clinic }),
  receiveSignins: (signins: Signin[]) => createAction(SigninTypes.SIGNINS_FETCH_SUCCESS, { signins }),
  abortedSignins: (error: Error) => createAction(SigninTypes.SIGNINS_FETCH_FAILURE, { error }),

  switch: (signin: Signin, visit: Visit) => createAction(SigninTypes.SIGNIN_SWITCH, { signin, visit })
}

export type SigninActions = ActionsUnion<typeof SigninActions>
