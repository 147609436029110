import { customElement, LitElement, html, css, property, PropertyValues, query } from 'lit-element'
import { translate as t } from 'lit-translate'

import '@polymer/paper-input/paper-input'
import { PaperInputElement } from '@polymer/paper-input/paper-input';
import { Address, BlankAddress } from '@healthspaces/hsuite-data/models';

export interface AddressEvent {
  address: Address;
}

declare global {
  interface DocumentEventMap {
    'address-change': CustomEvent<AddressEvent>
  }

  interface HTMLElementTagNameMap {
    'address-input': AddressInputElement
  }

  interface Window {
    mapsLoaded: Function
  }
}

const callbacks: Function[] = []

function ensureMapsLoaded(callback: Function) {
  callbacks.push(callback)

  // maps already loaded
  if (window.google) {
    return callback()
  }

  // prevent re-loading / multiple load requests
  if (window.mapsLoaded) {
    return
  }

  window.mapsLoaded = () => callbacks.forEach(fn => fn())

  const s = document.createElement('script')
  s.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${window.HSuite.maps_api_key}&callback=mapsLoaded`

  document.head.appendChild(s)
}

@customElement('address-input')
export class AddressInputElement extends LitElement {
  @property({ type: String }) label: string = ''
  @property({ type: String }) value: string = ''
  @property({ type: Object }) address: Address

  @query('paper-input') searchElement: PaperInputElement

  firstUpdated(changedProperties: PropertyValues) {
    ensureMapsLoaded(() => this.mapsLoaded())
  }

  private autocomplete: google.maps.places.Autocomplete

  mapsLoaded() {
    const inputElement = <HTMLInputElement>this.searchElement.inputElement.querySelector('input')

    this.autocomplete = new google.maps.places.Autocomplete(inputElement, {
      componentRestrictions: { country: ['us', 'ca'] },
      fields: ['address_components'],
      types: ['address'],
    })
    this.autocomplete.set('place', null)
    this.autocomplete.addListener('place_changed', this.fillAddress.bind(this))

    this.geolocate()
  }

  geolocate() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        const circle = new google.maps.Circle({ center: geolocation, radius: position.coords.accuracy })

        this.autocomplete.setBounds(circle.getBounds());
      })
    }
  }

  fillAddress() {
    const place = this.autocomplete.getPlace()

    let street = ''
    let zip = ''

    this.address = {...BlankAddress }

    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
      const type = component.types[0]
      switch (type) {
        case 'street_number':
          street = component.long_name + ' ' + street
          break
        case 'route':
          street += component.long_name
          break
        case 'postal_code':
          zip = component.long_name + zip
          break
        case 'postal_code_suffix':
          zip = zip + '-' + component.long_name
          break
        case 'locality':
          this.address.city = component.long_name
          break
        case 'administrative_area_level_1':
          this.address.state = component.long_name
          break
        case 'country':
          this.address.country = component.long_name
          break
      }
    }

    this.address.street = street
    this.address.zip = zip

    this.onChange()
  }

  onChange() {
    const evt = new CustomEvent<AddressEvent>('address-change', {
      bubbles: true,
      composed: true,
      detail: {
        address: this.address,
      },
    })
    this.dispatchEvent(evt)
  }

  onPropertyChange(name: string) {
    return function(e: Event) {
      const el = <PaperInputElement>e.target
      this.address[name] = el.value
      this.onChange()
    }
  }

  render() {
    return html`
      <h4>${this.label}</h4>
      <paper-input id="search" always-float-label .label=${t('address.search')} value=${this.value}></paper-input>
      <paper-input always-float-label .label=${t('address.number')} value=${this.address.number} @change=${this.onPropertyChange('number')}></paper-input>
      <paper-input always-float-label .label=${t('address.street')} value=${this.address.street} @change=${this.onPropertyChange('street')}></paper-input>
      <paper-input always-float-label .label=${t('address.city')} value=${this.address.city} @change=${this.onPropertyChange('city')}></paper-input>
      <paper-input always-float-label .label=${t('address.state')} value=${this.address.state} @change=${this.onPropertyChange('state')}></paper-input>
      <paper-input always-float-label .label=${t('address.zip')} value=${this.address.zip} @change=${this.onPropertyChange('zip')}></paper-input>
      <paper-input always-float-label .label=${t('address.country')} value=${this.address.country} @change=${this.onPropertyChange('country')}></paper-input>
    `
  }

  static get styles() {
    return [
      css`
        :host {
          display: grid;
          grid-column-gap: 10px;
          grid-row-gap: 20px;
          grid-template-columns: 50% 50%;
          padding: 16px;
        }

        h4, #search {
          grid-column: 1 / 3;
        }
      `
    ]
  }
}
