import { ActionsUnion, createAction } from '../actions';
import { Practice } from '@healthspaces/hsuite-data/models/practice'

export enum PracticeTypes {
  FETCH_PRACTICE_REQUEST = 'FETCH_PRACTICE_REQUEST',
  FETCH_PRACTICE_SUCCESS = 'FETCH_PRACTICE_SUCCESS',
  FETCH_PRACTICE_FAILURE = 'FETCH_PRACTICE_FAILURE',
}

export const PracticeActions = {
  requestPractice: (id: string) => createAction(PracticeTypes.FETCH_PRACTICE_REQUEST, { id }),
  receivePractice: (id: string, practice: Practice) => createAction(PracticeTypes.FETCH_PRACTICE_SUCCESS, { id, practice }),
  abortedPractice: (error: Error) => createAction(PracticeTypes.FETCH_PRACTICE_FAILURE, { error }),
}

export type PracticeActions = ActionsUnion<typeof PracticeActions>
