import { customElement, LitElement, html, property, css, PropertyValues } from 'lit-element'
import { connect } from '@captaincodeman/redux-connect-element'
import { translate as t } from 'lit-translate'

import { dobFormatter } from '../../service/dobFormatter';
import { PatientInfo, Gender, Race, Ethnicity, MaritalStatus, StudentStatus, Parent, EmploymentStatus } from '@healthspaces/hsuite-data/models';
import '../address-input'
import '@polymer/paper-input/paper-input'
import { PaperInputElement } from '@polymer/paper-input/paper-input';
import { AddressEvent } from '../address-input';
import './form-checkbox'
import './form-input'
import './form-radio-group'
import '@polymer/paper-radio-button/paper-radio-button'
import { store, FormActions, RootState, FormSelectors } from '../../store'
import { baseStyle, fontStyle, alternatingSections } from '../shared-styles';

declare global {
  interface HTMLElementTagNameMap {
    'patient-demographics': PatientDemographicsElement;
  }
}

@customElement('patient-demographics')
export class PatientDemographicsElement extends connect(store, LitElement) {
  @property({ type: Object }) info: PatientInfo

  mapState(state: RootState) {
    return {
      info: FormSelectors.patient_info(state),
    }
  }

  mapEvents() {
    return {
      'form-checkbox-changed': (e: CustomEvent) => FormActions.patientInfoUpdate(e.detail),
      'form-input-changed': (e: CustomEvent) => FormActions.patientInfoUpdate(e.detail),
      'form-radio-group-changed': (e: CustomEvent) => FormActions.patientInfoUpdate(e.detail),
    }
  }

  onPatientAddressChange(e: CustomEvent<AddressEvent>) {
    const update = { demographics: { address: e.detail.address } }
    store.dispatch(FormActions.patientInfoUpdate(update))
  }

  onEmployerAddressChange(e: CustomEvent<AddressEvent>) {
    const update = { demographics: { employerAddress: e.detail.address } }
    store.dispatch(FormActions.patientInfoUpdate(update))
  }

  onSSNChange(e: Event){
    const el = <PaperInputElement>e.target
    this.info.ssn = el.value
  }

  convertDob(dob : any){
    if(dob != undefined) {
      if(dob.toString() == "0001-01-01T00:00:00Z" && typeof dob == 'string') {
        dob = null
      }
      else if(typeof dob == 'string'){
        dob = new Date(dob)
      }
      else if (dob.seconds != null){
        dob = new Date(dob.seconds * 1000)
        dob = dob && dobFormatter.format(dob)
      }
    }
    return dob
  }

  convertSsn(ssn: string){
    ssn = ssn.replace(/\d(?=\d{4})/g, "*")
    return ssn
  }

  render() {
    this.info.minor.parents.father.dob = this.convertDob(this.info.minor.parents.father.dob)
    this.info.minor.parents.mother.dob = this.convertDob(this.info.minor.parents.mother.dob)

    var newSSN = this.convertSsn(this.info.ssn)
    return html`
    <h2>${t('patient-demographics.title')}</h2>

    <section>
      <address-input label=${t('patient-demographics.address.label')} .address=${this.info.demographics.address} @address-change=${this.onPatientAddressChange}></address-input>
    </section>

    <section>
      <h3>${t('patient-demographics.employment.title')}</h3>
      <form-radio-group .data=${this.info} key="demographics.employment.status">
        <paper-radio-button name=${EmploymentStatus.Unemployed}>${t('patient-demographics.employment.status.' + EmploymentStatus.Unemployed)}</paper-radio-button>
        <paper-radio-button name=${EmploymentStatus.Employed}>${t('patient-demographics.employment.status.' + EmploymentStatus.Employed)}</paper-radio-button>
        <paper-radio-button name=${EmploymentStatus.Retired}>${t('patient-demographics.employment.status.' + EmploymentStatus.Retired)}</paper-radio-button>
        <paper-radio-button name=${EmploymentStatus.Disabled}>${t('patient-demographics.employment.status.' + EmploymentStatus.Disabled)}</paper-radio-button>
      </form-radio-group>
      <form-input .data=${this.info} key="demographics.occupation" title=${t('patient-demographics.employment.occupation')}></form-input>
      <form-input .data=${this.info} key="demographics.employer" title=${t('patient-demographics.employment.employer.name')}></form-input>
      <address-input label=${t('patient-demographics.employment.employer.address')} .address=${this.info.demographics.employerAddress} @address-change=${this.onEmployerAddressChange}></address-input>
    </section>

    <section>
      <h3>${t('patient-demographics.primaryCare.title')}</h3>
      <form-input .data=${this.info} key="physician" title=${t('patient-demographics.primaryCare.physician')}></form-input>
    </section>

    <section>
      <h3>${t('patient-demographics.minor.title')}</h3>
      <patient-minor .data=${this.info}></patient-minor>
    </section>

    <section>
      <h3>${t('patient-demographics.gender.title')}</h3>
      <form-radio-group .data=${this.info} key="demographics.gender">
        <paper-radio-button name=${Gender.Male}>${t('patient-demographics.gender.' + Gender.Male)}</paper-radio-button>
        <paper-radio-button name=${Gender.Female}>${t('patient-demographics.gender.' + Gender.Female)}</paper-radio-button>
      </form-radio-group>
    </section>

    <section>
      <h3>${t('patient-demographics.ssn.title')}</h3>
      <paper-input type="string" maxlength="9" value=${newSSN} title=${t('patient-demographics.ssn.label')} @change=${this.onSSNChange}></paper-input>
    </section>

    <section>
      <h3>${t('patient-demographics.race.title')}</h3>
      <form-radio-group .data=${this.info} key="demographics.race">
        <paper-radio-button name=${Race.Caucasian}>${t('patient-demographics.race.' + Race.Caucasian)}</paper-radio-button>
        <paper-radio-button name=${Race.Asian}>${t('patient-demographics.race.' + Race.Asian)}</paper-radio-button>
        <paper-radio-button name=${Race.Black}>${t('patient-demographics.race.' + Race.Black)}</paper-radio-button>
        <paper-radio-button name=${Race.Native}>${t('patient-demographics.race.' + Race.Native)}</paper-radio-button>
        <paper-radio-button name=${Race.Pacific}>${t('patient-demographics.race.' + Race.Pacific)}</paper-radio-button>
        <paper-radio-button name=${Race.Other}>${t('patient-demographics.race.' + Race.Other)}</paper-radio-button>
        <paper-radio-button name=${Race.Refuse}>${t('patient-demographics.race.' + Race.Refuse)}</paper-radio-button>
      </form-radio-group>
    </section>

    <section>
      <h3>${t('patient-demographics.ethnicity.title')}</h3>
      <form-radio-group .data=${this.info} key="demographics.ethnicity">
        <paper-radio-button name=${Ethnicity.Hispanic}>${t('patient-demographics.ethnicity.' + Ethnicity.Hispanic)}</paper-radio-button>
        <paper-radio-button name=${Ethnicity.NonHispanic}>${t('patient-demographics.ethnicity.' + Ethnicity.NonHispanic)}</paper-radio-button>
        <paper-radio-button name=${Ethnicity.Other}>${t('patient-demographics.ethnicity.' + Ethnicity.Other)}</paper-radio-button>
        <paper-radio-button name=${Ethnicity.Refuse}>${t('patient-demographics.ethnicity.' + Ethnicity.Refuse)}</paper-radio-button>
      </form-radio-group>
    </section>

    <section>
      <h3>${t('patient-demographics.marital.title')}</h3>
      <form-radio-group .data=${this.info} key="demographics.marital">
        <paper-radio-button name=${MaritalStatus.Single}>${t('patient-demographics.marital.' + MaritalStatus.Single)}</paper-radio-button>
        <paper-radio-button name=${MaritalStatus.Married}>${t('patient-demographics.marital.' + MaritalStatus.Married)}</paper-radio-button>
        <paper-radio-button name=${MaritalStatus.Widowed}>${t('patient-demographics.marital.' + MaritalStatus.Widowed)}</paper-radio-button>
        <paper-radio-button name=${MaritalStatus.Separated}>${t('patient-demographics.marital.' + MaritalStatus.Separated)}</paper-radio-button>
        <paper-radio-button name=${MaritalStatus.Divorced}>${t('patient-demographics.marital.' + MaritalStatus.Divorced)}</paper-radio-button>
      </form-radio-group>
    </section>

    <section>
      <h3>${t('patient-demographics.language.title')}</h3>
      <form-radio-group .data=${this.info} key="demographics.language">
        <paper-radio-button name="en">${t('patient-demographics.language.en')}</paper-radio-button>
        <paper-radio-button name="es">${t('patient-demographics.language.es')}</paper-radio-button>
        <paper-radio-button name="zh">${t('patient-demographics.language.zh')}</paper-radio-button>
        <paper-radio-button name="ja">${t('patient-demographics.language.ja')}</paper-radio-button>
      </form-radio-group>
      <!-- TODO: other language (why not just full list?) -->
    </section>

    <section>
      <h3>${t('patient-demographics.interpreter.title')}</h3>
      <form-checkbox .data=${this.info} key="demographics.interpreter" title=${t('patient-demographics.interpreter.label')}></form-checkbox>
    </section>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
      alternatingSections,
    ]
  }
}

@customElement('patient-minor')
export class PatientMinorElement extends LitElement {
  @property({ type: Object }) data: PatientInfo
  @property({ type: Object }) father: Parent
  @property({ type: Object }) mother: Parent

  shouldUpdate(changedProperties: PropertyValues) {
    if (changedProperties.has('data')) {
      this.father = this.data.minor.parents['father']
      this.mother = this.data.minor.parents['mother']
    }
    return true
  }

  onChangedFather(e: CustomEvent) {
    e.stopPropagation()
    const update = { minor: { parents: { father: e.detail } } }
    store.dispatch(FormActions.patientInfoUpdate(update))
  }

  onChangedMother(e: CustomEvent) {
    e.stopPropagation()
    const update = { minor: { parents: { mother: e.detail } } }
    store.dispatch(FormActions.patientInfoUpdate(update))
  }

  render() {
    return html`
    <form-radio-group .data=${this.data} key="minor.student">
      <paper-radio-button name=${StudentStatus.None}>${t('patient-minor.status.none')}</paper-radio-button>
      <paper-radio-button name=${StudentStatus.FullTime}>${t('patient-minor.status.full')}</paper-radio-button>
      <paper-radio-button name=${StudentStatus.PartTime}>${t('patient-minor.status.part')}</paper-radio-button>
    </form-radio-group>

    <div>
      <section @form-input-changed=${this.onChangedFather}>
        <form-input .data=${this.father} key="name" title=${t('patient-minor.parents.father')} type="string"></form-input>
        <form-input .data=${this.father} key="dob" title=${t('patient-minor.parents.dob')} type="date"></form-input>
        <form-input .data=${this.father} key="number" title=${t('patient-minor.parents.cell')} type="string"></form-input>
      </section>

      <section @form-input-changed=${this.onChangedMother}>
        <form-input .data=${this.mother} key="name" title=${t('patient-minor.parents.mother')} type="string"></form-input>
        <form-input .data=${this.mother} key="dob" title=${t('patient-minor.parents.dob')} type="date"></form-input>
        <form-input .data=${this.mother} key="number" title=${t('patient-minor.parents.cell')} type="string"></form-input>
      </section>
    </div>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
      css`
        div {
          display: grid;
          grid-column-gap: 8px;
          grid-row-gap: 12px;
          padding: 8px;
          grid-template-columns: 1fr 1fr;
        }
      `
    ]
  }
}
