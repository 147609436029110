import { customElement, LitElement, html, property } from 'lit-element'
import { connect } from '@captaincodeman/redux-connect-element'
import { translate as t } from 'lit-translate'

import '../address-input'
import { store, FormActions, RootState, FormSelectors } from '../../store'
import { baseStyle, fontStyle } from '../shared-styles';
import { PatientInfo } from '@healthspaces/hsuite-data'
import './form-image'

declare global {
  interface HTMLElementTagNameMap {
    'patient-driving-license': PatientDrivingLicense;
  }
}

@customElement('patient-driving-license')
export class PatientDrivingLicense extends connect(store, LitElement) {
  @property({ type: Object }) info: PatientInfo
  @property({ type: Number }) progress: number

  mapState(state: RootState) {
    return {
      info: FormSelectors.patient_info(state),
      progress: FormSelectors.upload_progress(state),
    }
  }

  mapEvents() {
    return {
      'form-image-changed': (e: CustomEvent) => FormActions.uploadImage(e.detail.type, e.detail.blob),
    }
  }

  render() {
    return html`
      <h2>${t('patient-driving-license.title')}</h2>

      <section>
        <form-image key="driving_license" .label=${t('patient-driving-license.title')} .value=${this.info.driving_license} .progress=${this.progress}></form-image>
      </section>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
    ]
  }
}
