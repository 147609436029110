import { customElement, LitElement, html, css, property } from 'lit-element'

import { Signin } from '@healthspaces/hsuite-elements/models';
import '@healthspaces/hsuite-elements/identicon-img';

import { cardStyle, baseStyle } from './shared-styles';
import { dobFormatter } from '../service/dobFormatter';

declare global {
  interface HTMLElementTagNameMap {
    'signin-card': SigninCardElement;
  }
}

@customElement('signin-card')
export class SigninCardElement extends LitElement {
  @property({ type: Object }) signin: Signin

  constructor() {
    super()
    this.addEventListener('click', this.onClick.bind(this))
  }

  onClick(e: Event) {
    const signin = this.signin
    const dob = new Date(Date.UTC(signin.year, signin.month, signin.day))
    const message = `Activate ${signin.firstName} ${signin.lastName} (${dobFormatter.format(dob)})`

    const select = window.confirm(message)
    if (select) {
      this.dispatchEvent(new CustomEvent('signin-select', {
        bubbles: true,
        composed: true,
        detail: this.signin,
      }))
    }
  }

  render() {
    const signin = this.signin
    const key = `${signin.id} ${signin.firstName} ${signin.lastName}`

    return html`
    <identicon-img .key=${key}></identicon-img>
    <h2>${signin.firstName} ${signin.lastName}</h2>
    `
  }

  static get styles() {
    return [
      baseStyle,
      cardStyle,
      css`
    :host {
      margin: 16px 0;
      padding: 16px;
      cursor: pointer;
    }

    :host([selected]) {
      background-color: #fefeee;
    }

    h2 {
      font-weight: 100;
      margin: 0;
    }

    identicon-img {
      width: 36px;
      height: 36px;
      margin-right: 8px;
      float: left;
    }
    `
    ]
  }
}
