import { customElement, LitElement, html, property, PropertyValues } from 'lit-element'
import { connect } from '@captaincodeman/redux-connect-element'
import { get, translate as t, translateUnsafeHTML as th } from 'lit-translate'
import '@healthspaces/hsuite-elements/pain-diagram'
import '@healthspaces/hsuite-elements/pain-options'

import { store, FormActions, RootState, FormSelectors } from '../../store'
import { VisitInfo } from '@healthspaces/hsuite-data/models';
import { baseStyle, fontStyle } from '../shared-styles';
import { defaultPainTypes, PainTypes } from '@healthspaces/hsuite-elements/pain-diagram'

declare global {
  interface HTMLElementTagNameMap {
    'visit-pain': VisitPainElement;
  }
}

@customElement('visit-pain')
export class VisitPainElement extends connect(store, LitElement) {
  @property({ type: String }) type: string = 'ache'
  @property({ type: Object }) info: VisitInfo
  @property({ type: Object }) painTypes: PainTypes = defaultPainTypes

  constructor() {
    super()
    this.langChanged = this.langChanged.bind(this)
  }

  connectedCallback() {
    super.connectedCallback()
    window.addEventListener('langChanged', this.langChanged)
    this.langChanged()
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    window.removeEventListener('langChanged', this.langChanged)
  }

  langChanged() {
    this.painTypes.ache.name = get('visit-pain.ache')
    this.painTypes.numb.name = get('visit-pain.numb')
    this.painTypes.pins.name = get('visit-pain.pins')
    this.painTypes.burn.name = get('visit-pain.burn')
    this.painTypes.stab.name = get('visit-pain.stab')

    this.painTypes = {...this.painTypes}
  }

  mapState(state: RootState) {
    return {
      info: FormSelectors.visit_info(state),
    }
  }

  mapEvents() {
    return {
      'pain-diagram-add': (e: CustomEvent) => FormActions.addPain(e.detail),
      'pain-diagram-remove': (e: CustomEvent) => FormActions.removePain(e.detail),
    }
  }

  painTypeSelected(e: CustomEvent) {
    this.type = e.detail
  }

  render() {
    return html`
    <p>${th('visit-pain.instruction')}</p>
    <pain-diagram
      .type=${this.type}
      .items=${this.info.pain}
      .left=${t('visit-pain.left')}
      .right=${t('visit-pain.right')}
      .front=${t('visit-pain.front')}
      .back=${t('visit-pain.back')}
      .painTypes=${this.painTypes}
    ></pain-diagram>
    <pain-options @pain-type-selected=${this.painTypeSelected} .painTypes=${this.painTypes}></pain-options>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
    ]
  }
}
