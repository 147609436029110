import { customElement, LitElement, html, css, property } from 'lit-element'
import { unsafeHTML } from 'lit-html/directives/unsafe-html'
import { connect } from '@captaincodeman/redux-connect-element'
import { translate as t } from 'lit-translate'

import { store, FormStage, FormSelectors, FormActions } from '../store'
import { RootState } from '../store/reducer'
import './forms'
import { chevron_down } from './icons'

declare global {
  interface HTMLElementTagNameMap {
    'view-forms': ViewFormsElement;
  }
}

@customElement('view-forms')
export class ViewFormsElement extends connect(store, LitElement) {
  @property({ type: Number }) stage: FormStage
  @property({ type: Number }) step: number
  @property({ type: String }) page: string
  @property({ type: Boolean }) prevEnabled = true
  @property({ type: Boolean }) nextEnabled = true
  @property({ type: Boolean }) scrollIndicator = false

  mapState(state: RootState) {
    return {
      stage: FormSelectors.stage(state),
      step: FormSelectors.step(state),
      page: FormSelectors.page(state),
      nextEnabled: FormSelectors.valid(state),
      scrollIndicator: FormSelectors.scroll_indicator(state),
    }
  }

  prev(e: Event) {
    store.dispatch(FormActions.prev())
  }

  next(e: Event) {
    store.dispatch(FormActions.next())
  }

  async updated() {
    await this.updateComplete
    requestAnimationFrame(() => {
      const scrollRequired = document.scrollingElement.scrollHeight > window.innerHeight
      store.dispatch(FormActions.scrollRequired(scrollRequired))
    })
  }

  renderForm() {
    switch (this.stage) {
      case FormStage.Welcome:
        this.setAttribute('stage', 'welcome')
        return html`<form-welcome></form-welcome>`
      case FormStage.Page:
        this.setAttribute('stage', 'page')
        return html`${unsafeHTML(`<${this.page}></${this.page}>`)}`
      case FormStage.Legal:
        this.setAttribute('stage', 'legal')
        return html`<form-legal></form-legal>`
      case FormStage.Complete:
        this.setAttribute('stage', 'complete')
        return html`<form-complete></form-complete>`
    }
  }

  render() {
    return html`
    ${this.renderForm()}
    <div id="scroll" ?hidden=${!this.scrollIndicator}>${chevron_down} ${t('view-form.scroll')} ${chevron_down}</div>
    <footer>
      <button id="prev" ?disabled=${!this.prevEnabled} @click=${this.prev}>${t('view-form.prev')}</button>
      <button id="next" ?disabled=${!this.nextEnabled} @click=${this.next}>${t('view-form.next')}</button>
    </footer>
    `
  }

  static get styles() {
    return [
      css`
      :host {
        display: block;
        box-sizing: border-box;
        padding-bottom: 80px;
      }

      #scroll {
        pointer-events: none;
        box-sizing: border-box;
        position: fixed;
        right: 0;
        bottom: 70px;
        left: 0;
        height: 70px;
        padding-top: 40px;
        background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1) 70%);
        text-align: center;
        transition: opacity 250ms ease-in-out;
      }

      #scroll[hidden] {
        display: block !important;
        opacity: 0;
      }

      svg {
        width: 1em;
        height: 1em;
        color: #666;
      }

      footer {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: space-around;
        align-items: stretch;
        background-color: #fff;
        border-top: 1px solid #ccc;
      }

      button {
        flex: auto;
        font-size: 18px;
        margin: 8px;
        padding: 16px;
        border: none;
        border-radius: 6px;
        outline: none;
        color: var(--button-color);
        background-color: var(--button-background-color);
      }

      #prev {
        margin-right: 4px;
      }

      #next {
        margin-left: 4px;
        transition: background-color 250ms ease-in-out;
      }

      button[disabled] {
        background-color: #999;
      }

      :host([stage="welcome"]) #prev,
      :host([stage="complete"]) #next {
        visibility: hidden;
      }
      `
    ]
  }
}
