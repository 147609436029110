import { customElement, LitElement, html, property, css } from 'lit-element'
import { translate as t, translateUnsafeHTML as th } from 'lit-translate'
import './form-radio-group'

import { FamilyHistory } from '@healthspaces/hsuite-data/models'
import { fontStyle } from '../shared-styles';

declare global {
  interface HTMLElementTagNameMap {
    'medical-family-conditions': MedicalFamilyConditionsElement;
  }
}

@customElement('medical-family-conditions')
export class MedicalFamilyConditionsElement extends LitElement {
  @property({ type: Object }) data: FamilyHistory

  constructor() {
    super()
    this.addEventListener('form-checkbox-changed', this.onChanged)
    this.addEventListener('form-input-changed', this.onChanged)
  }

  onChanged(e: CustomEvent) {
    Object.assign(this.data, e.detail)
    this.notify(e)
  }

  notify(e: CustomEvent) {
    e.stopPropagation()
    const evt = new CustomEvent('medical-family-history-updated', {
      bubbles: true,
      composed: true,
      detail: this.data,
    })
    this.dispatchEvent(evt)
  }

  render() {
    return html`
    <form-radio-group .data=${this.data} key="status" @form-radio-group-changed=${this.onChanged}>
      <paper-radio-button name="alive">${t('medical-family-conditions.status.alive')}</paper-radio-button>
      <paper-radio-button name="deceased">${t('medical-family-conditions.status.deceased')}</paper-radio-button>
      <paper-radio-button name="unknown">${t('medical-family-conditions.status.unknown')}</paper-radio-button>
    </form-radio-group>

    <form-input .data=${this.data} key="age" title=${t('medical-family-conditions.age')}></form-input>

    <section class="multi-column">
      <form-checkbox .data=${this.data} key="anesthesia" title=${t('medical-family-conditions.anesthesia')}></form-checkbox>
      <form-checkbox .data=${this.data} key="rheumatoid" title=${t('medical-family-conditions.rheumatoid')}></form-checkbox>
      <form-checkbox .data=${this.data} key="osteoarthritis" title=${t('medical-family-conditions.osteoarthritis')}></form-checkbox>
      <form-checkbox .data=${this.data} key="osteoperosis" title=${t('medical-family-conditions.osteoperosis')}></form-checkbox>
      <form-checkbox .data=${this.data} key="asthma" title=${t('medical-family-conditions.asthma')}></form-checkbox>
      <form-checkbox .data=${this.data} key="bleeding" title=${t('medical-family-conditions.bleeding')}></form-checkbox>
      <form-checkbox .data=${this.data} key="cancer" title=${t('medical-family-conditions.cancer')}></form-checkbox>
      <form-checkbox .data=${this.data} key="coronary" title=${t('medical-family-conditions.coronary')}></form-checkbox>
      <form-checkbox .data=${this.data} key="dvt" title=${t('medical-family-conditions.dvt')}></form-checkbox>
      <form-checkbox .data=${this.data} key="diabetes" title=${t('medical-family-conditions.diabetes')}></form-checkbox>
      <form-checkbox .data=${this.data} key="hemophilia" title=${t('medical-family-conditions.hemophilia')}></form-checkbox>
      <form-checkbox .data=${this.data} key="hypertension" title=${t('medical-family-conditions.hypertension')}></form-checkbox>
      <form-checkbox .data=${this.data} key="kidney" title=${t('medical-family-conditions.kidney')}></form-checkbox>
      <form-checkbox .data=${this.data} key="pulmonary" title=${t('medical-family-conditions.pulmonary')}></form-checkbox>
      <form-checkbox .data=${this.data} key="seizures" title=${t('medical-family-conditions.seizures')}></form-checkbox>
      <form-checkbox .data=${this.data} key="stroke" title=${t('medical-family-conditions.stroke')}></form-checkbox>
    </section>

    <section>
      <p>${th('medical-family-conditions.other.title')}:</p>
      <form-input .data=${this.data} key="other" title=${t('medical-family-conditions.other.label')}></form-input>
    </section>
    `
  }

  static get styles() {
    return [
      fontStyle,
      css`
        .multi-column {
          display: grid;
          grid-column-gap: 10px;
          grid-row-gap: 20px;
          padding: 16px;
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        }
      `
    ]
  }
}
