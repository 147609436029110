import { Reducer } from 'redux';

import { VisitActions, VisitTypes } from './actions';
import { Visit } from '@healthspaces/hsuite-data/models/visit'
import { AuthActions, AuthTypes } from '../auth';

export interface VisitState {
  fetching: boolean
  entities: { [key: string]: Visit }
  keys: string[]
}

const initialState: VisitState = {
  fetching: false,
  entities: {},
  keys: [],
};

export default <Reducer>(state: VisitState = initialState, action: VisitActions | AuthActions) => {
  switch (action.type) {
    case AuthTypes.SIGNED_OUT:
      return initialState

    case VisitTypes.VISITS_FETCH_REQUEST:
      return {
        ...state,
        fetching: true,
      }

    case VisitTypes.VISITS_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        keys: action.payload.visits.map(x => x.id),
        entities: action.payload.visits.reduce((entities, visit) => {
          entities[visit.id] = visit
          return entities
        }, {}),
      }

    default:
      return state
  }
}
