import { call, takeEvery, take, cancelled, put } from 'redux-saga/effects';
import UniversalRouter from 'universal-router';
import { AnyAction } from 'redux';

import { RoutingActions } from './actions';
import { eventChannel, buffers } from 'redux-saga';

export default [
  routeSaga,
  clickListener,
]

const ROUTE_SAGAS = [
]

const sagaRouter = new UniversalRouter(ROUTE_SAGAS, {
  resolveRoute(context, params) {
    return context.route.action
      ? [context.route.action, params, context.queries]
      : undefined
  },
});

function* routeSaga() {
  yield takeEvery('ROUTER/LOCATION_CHANGE', navigationSaga);
}

let pathnamePrev = ''
function* navigationSaga(action: AnyAction) {
  const { pathname, search, queries, hash } = action.payload;

  if (pathname === pathnamePrev) return

  try {
    const route = yield sagaRouter.resolve({
      pathname,
      queries,
    })
    if (route) {
      yield call(route[0], route[1], route[2])
    }
  } catch (err) {
    // no route, ignore
  }
}

function clickChannel() {
  return eventChannel(emit => {
    const handler = (e: MouseEvent) => {
      if ((e.button && e.button !== 0)
        || e.metaKey
        || e.altKey
        || e.ctrlKey
        || e.shiftKey
        || e.defaultPrevented) {
        return
      }

      let anchor = <HTMLAnchorElement>e.composedPath().filter(n => (n as HTMLElement).tagName === 'A')[0];
      if (!anchor || anchor.target || anchor.hasAttribute('download') || anchor.getAttribute('rel') === 'external') {
        return
      }

      const href = anchor.href
      if (!href || href.indexOf('mailto:') !== -1) {
        return
      }

      const location = window.location;
      const origin = location.origin || location.protocol + '//' + location.host;
      if (href.indexOf(origin) !== 0) return;

      e.preventDefault();
      if (href !== location.href) {
        emit(RoutingActions.push(anchor.href.substr(origin.length)))
      }
    }
    window.document.body.addEventListener('click', handler)
    return () => document.body.removeEventListener('click', handler)
  },
    buffers.fixed(1)
  )
}

function* clickListener() {
  const channel = yield call(clickChannel);

  try {
    while (true) {
      const action = yield take(channel);
      yield put(action)
    }
  } finally {
    if (yield cancelled()) {
      channel.close()
    }
  }
}
