declare global {
  interface Window {
    firebase: typeof import('firebase')
  }
}

const loadScript = (url) => new Promise((resolve, reject) => {
  const script = document.createElement('script')
  script.src = url
  script.async = true
  script.onload = resolve
  script.onerror = reject
  document.head.appendChild(script)
})

const loadModule = (module) => loadScript(`https://www.gstatic.com/firebasejs/FIREBASE_SDK_VERSION/firebase-${module}.js`)

const loadConfig = () => window.firebase.initializeApp(window.HSuite.firebase_config)

const loadAppConfig = loadModule('app').then(loadConfig)

export const firebaseApp = loadAppConfig
  .then(() => Promise.all([
    loadModule('auth'),
    loadModule('firestore.memory'),
    loadModule('storage'),
  ]))
  .then(() => window.firebase.app)

