import { Signin, FormsStatus, Patient, BlankPatientInfo } from '@healthspaces/hsuite-data/models';
import { IStore } from '@healthspaces/hsuite-data/store';
import { buffers, eventChannel } from 'redux-saga';
import { cancelled, put, take, takeLatest, select, call, all, race } from 'redux-saga/effects';
import { takeAuth } from '../saga-helpers';
import { SigninActions, SigninTypes } from './actions';
import { datastore } from '../firestore';
import { Context } from '@healthspaces/hsuite-data/repositories/repository';
import { AuthActions, AuthSelectors, AuthTypes } from '../auth';
import { FormActions, FormStage, FormSelectors } from '../forms'

export default [
  fetchSigninsListener,
  signinSelectedListener,
  watchSigninListener,
  switchSigninListener,
  lockListener,
]

function* fetchSigninsListener() {
  yield takeAuth(fetchSigninsSaga)
}

function* fetchSigninsSaga(ctx: Context) {
  const db: IStore = yield datastore

  const listener = eventChannel(emit => db.signins.subscribe(ctx,
    (data, error) => emit({ data, error }), db.signins.withForms(FormsStatus.Requested)
  ), buffers.expanding(1))

  yield put(SigninActions.requestSignins(ctx.practice, ctx.clinic))

  try {
    while (true) {
      const { data, error }: { data: Signin[], error: Error } = yield take(listener)
      if (data) {
        yield put(SigninActions.receiveSignins(data))
      } else {
        yield put(SigninActions.abortedSignins(error))
      }
    }
  } finally {
    if (yield cancelled()) {
      listener.close()
    }
  }
}

function* signinSelectedListener() {
  yield takeLatest(SigninTypes.SIGNIN_SELECT, signinSelectedSaga)
}

function* signinSelectedSaga(action: ReturnType<typeof SigninActions.selectSignin>) {
  const { signin } = action.payload

  const patient: Patient = {
    id: '',
    firstName: signin.firstName,
    lastName: signin.lastName,
    dob: new Date(Date.UTC(signin.year, signin.month, signin.day)),
    payerType: '',
    balance: { amount: 0, currency: 'USD' },
    info: BlankPatientInfo,
  }

  yield all([
    put(AuthActions.locked(true)),
    put(FormActions.position(FormStage.Welcome, 0)),
    put(FormActions.patientFetchSuccess(patient))
  ])

  yield call(watchSigninSaga)
}

function* watchSigninListener() {
  yield takeAuth(watchSigninSaga)
}

function* watchSigninSaga() {
  const ctx: Context = yield select(AuthSelectors.context)
  const db: IStore = yield datastore

  const selected = yield select(FormSelectors.signin)
  if (!selected) return

  type SigninResult = { signin: Signin, error: Error }
  const listener = eventChannel<SigninResult>(emit => db.signins.watch(ctx, selected.id,
    (signin, error) => emit({ signin, error })
  ), buffers.expanding(1))

  while (true) {
    const { result, complete } = yield race({
      result: take(listener),
      complete: take(AuthTypes.LOCKED),
    })
    if (result) {
      const { signin, error }: SigninResult = result
      if (signin) {
        if (signin.visit) {
          // signin matched to visit (appointment)
          // fetch and switch ...
          const visit = yield call([db.visits, db.visits.get], ctx, signin.visit!)
          yield put(SigninActions.switch(selected, visit))
          break
        }
      } else {
        console.error(error)
      }
    }
    if (complete) {
      // forms marked as complete, stop listening
      break
    }
  }

  listener.close()
}

function* switchSigninListener() {
  yield takeLatest(SigninTypes.SIGNIN_SWITCH, switchSigninSaga)
}

function* switchSigninSaga(action: ReturnType<typeof SigninActions.switch>) {
  const { signin } = action.payload
  const ctx: Context = yield select(AuthSelectors.context)
  const db: IStore = yield datastore

  // TODO: we now have 3 sources of patient name:
  //       - appointment (from EMR)
  //       - manual signin card (from PF or kiosk)
  //       - PHR forms entered by patient
  // So the Q is, which one should go where if they are different?

  yield call([db, db.switchSigninToVisit], ctx, signin.id)
}

function* lockListener() {
  yield takeLatest(AuthTypes.LOCKED, lockSaga)
}

function* lockSaga(action: ReturnType<typeof AuthActions.locked>) {
  const { locked } = action.payload

  const db: IStore = yield datastore
  const ctx = yield select(AuthSelectors.context)
  const signin: Signin = yield select(FormSelectors.signin)
  if (!signin) return

  yield call([db, db.updateSigninForms], ctx, signin.id, locked
    ? FormsStatus.Activated
    : FormsStatus.Completed
  )
}
