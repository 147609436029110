import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import { middleware } from './middleware'
import { rootReducer } from './reducer'
import { startRouting } from './routing/middleware'
import { startReselect } from './reselect'
import { startSagas } from './sagas'
import { loadState } from './persistence';

const composeEnhancers = composeWithDevTools({ })
const enhancer = composeEnhancers(applyMiddleware(...middleware))
const state = loadState()

export const store = createStore(rootReducer, state, enhancer)

async function start() {
  await startSagas()
  startReselect(store)
  startRouting(store)
}

start()
