import { customElement, LitElement, html, css, property, PropertyValues, query } from 'lit-element'
import { connect } from '@captaincodeman/redux-connect-element'
import { translate as t, translateUnsafeHTML as th } from 'lit-translate'
import './form-input'

import '@polymer/paper-fab';
import '@polymer/paper-dialog';
import '@polymer/paper-dialog-scrollable';
import { PaperDialogElement } from '@polymer/paper-dialog';


import { store, FormActions, RootState, FormSelectors } from '../../store'
import { MedicalHistory, Hospitalization } from '@healthspaces/hsuite-data/models'
import { baseStyle, cardStyle, fontStyle } from '../shared-styles';
import { delete_icon } from '../icons';

declare global {
  interface HTMLElementTagNameMap {
    'medical-hospitalizations': MedicalHospitalizationsElement;
    'medical-hospitalization-list': MedicalHospitalizationListElement;
    'medical-hospitalization-card': MedicalHospitalizationCardElement;
    'medical-hospitalization-edit': MedicalHospitalizationEditElement;
  }
}

const emptyHospitalization: Hospitalization = { reason: '', year: '' }

@customElement('medical-hospitalizations')
export class MedicalHospitalizationsElement extends connect(store, LitElement) {
  @property({ type: Object }) history: MedicalHistory

  mapState(state: RootState) {
    return {
      history: FormSelectors.medical_history(state),
    }
  }

  mapEvents() {
    return {
      'hospitalization-add': (e: CustomEvent<Hospitalization>) => FormActions.medicalHistoryArrayAdd('hospitalizations', e.detail),
      'hospitalization-update': (e: CustomEvent<{ old: Hospitalization, new: Hospitalization }>) => FormActions.medicalHistoryArrayUpdate('hospitalizations', e.detail.old, e.detail.new),
      'hospitalization-delete': (e: CustomEvent<Hospitalization>) => FormActions.medicalHistoryArrayDelete('hospitalizations', e.detail),
    }
  }

  render() {
    return html`
      ${ this.history.hospitalizations.length
        ? t('medical-hospitalizations.instructions.filled')
        : th('medical-hospitalizations.instructions.empty', { icon: '<iron-icon icon="add"></iron-icon>' })
      }
      <medical-hospitalization-list .hospitalizations=${this.history.hospitalizations}></medical-hospitalization-list>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
      css`
        svg {
          width: 1em;
          height: 1em;
        }
      `
    ]
  }
}

@customElement('medical-hospitalization-list')
export class MedicalHospitalizationListElement extends LitElement {
  @property({ type: Array }) hospitalizations: Hospitalization[]

  @query('medical-hospitalization-edit')
  editElement: MedicalHospitalizationEditElement

  async add(e: Event) {
    try {
      const hospitalization = await this.editElement.open(emptyHospitalization)
      const evt = new CustomEvent('hospitalization-add', {
        bubbles: true,
        composed: true,
        detail: hospitalization,
      })
      this.dispatchEvent(evt)
    } catch (err) {
      // cancelled
    }
  }

  async edit(e: Event) {
    const el = <MedicalHospitalizationCardElement>e.target
    const hospitalization = el.hospitalization
    try {
      const updated = await this.editElement.open(hospitalization)
      const evt = new CustomEvent('hospitalization-update', {
        bubbles: true,
        composed: true,
        detail: {
          old: hospitalization,
          new: updated,
        },
      })
      this.dispatchEvent(evt)
    } catch (err) {
      // cancelled
    }
  }

  delete(e: Event) {
    e.stopPropagation()
    const el = <HTMLButtonElement>e.target
    const hospitalization = el.closest('medical-hospitalization-card').hospitalization
    const evt = new CustomEvent('hospitalization-delete', {
      bubbles: true,
      composed: true,
      detail: hospitalization,
    })
    this.dispatchEvent(evt)
  }

  render() {
    const hospitalizations = this.hospitalizations.sort((a, b) => b.year.localeCompare(a.year, 'en'))

    return html`${hospitalizations.map(hospitalization => html`
      <medical-hospitalization-card .hospitalization=${hospitalization} @click=${this.edit} @delete=${this.delete}></medical-hospitalization-card>`)}
      <paper-fab icon="add" @click=${this.add}></paper-fab>
      <medical-hospitalization-edit></medical-hospitalization-edit>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
    ]
  }
}

@customElement('medical-hospitalization-card')
export class MedicalHospitalizationCardElement extends LitElement {
  @property({ type: Object }) hospitalization: Hospitalization

  delete(e: Event) {
    e.stopPropagation()
    const evt = new CustomEvent('delete', {
      bubbles: true,
      composed: true,
    })
    this.dispatchEvent(evt)
  }

  render() {
    return html`
      <button @click=${this.delete}>${delete_icon}</button>
      <h2>${this.hospitalization.reason}</h2>
      <p>${this.hospitalization.year}</p>
    `
  }

  static get styles() {
    return [
      baseStyle,
      fontStyle,
      cardStyle,
      css`
        :host {
          padding: 8px;
          cursor: pointer;
          display: flex;
          align-items: center;
        }

        h2, p {
          margin: 8px 0;
        }
        h2 { width: 70%; }
        p { width: 20% }

        button {
          color: #999;
          background-color: transparent;
          border: none;
          outline: none;
          margin-right: 8px;
        }

        button svg {
          width: 2em;
          height: 2em;
        }
      `
    ]
  }
}

@customElement('medical-hospitalization-edit')
export class MedicalHospitalizationEditElement extends LitElement {
  @property({ type: Object }) hospitalization: Hospitalization = emptyHospitalization

  private dialog: PaperDialogElement
  private resolve: (Hospitalization) => void
  private reject: () => void

  firstUpdated(changedProperties: PropertyValues) {
    // re-parent dialog to fix stacking
    this.dialog = this.shadowRoot.querySelector('paper-dialog')
    this.dialog = document.body.appendChild(this.dialog);

    this.dialog.addEventListener('form-input-changed', (e: CustomEvent) => {
      this.hospitalization = { ...this.hospitalization, ...e.detail }
    })
  }

  open(hospitalization: Hospitalization): Promise<Hospitalization> {
    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
      this.hospitalization = hospitalization
      this.dialog.open()
    })
  }

  save() {
    this.resolve(this.hospitalization)
  }

  hide() {
    this.reject()
  }

  render() {
    return html`
      <paper-dialog style="width: 80vw" with-backdrop no-cancel-on-outside-click @iron-overlay-closed=${this.hide}>
        <h2 style="color: #666">${t('medical-hospitalization-edit.title')}</h2>
        <paper-dialog-scrollable>
          <form-input .data=${this.hospitalization} key="reason" title=${t('medical-hospitalization-edit.reason')}></form-input>
          <form-input .data=${this.hospitalization} key="year" title=${t('medical-hospitalization-edit.year')} type="text" inputmode="numeric"></form-input>
        </paper-dialog-scrollable>
        <div class="buttons" style="display:flex; align-items: center; align-content: flex-end;">
          <paper-button dialog-dismiss>${t('dialog.cancel')}</paper-button>
          <paper-button dialog-confirm autofocus @click=${this.save}>${t('dialog.save')}</paper-button>
        </div>
      </paper-dialog>
    `
  }
}
